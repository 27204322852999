const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const APP_ENV: string | undefined = process.env.REACT_APP_ENV;
const GQL_URL = process.env.REACT_APP_GQL_URL;
const GOOGLE_ID: string | undefined = process.env.REACT_APP_GOOGLE_ID;
const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const APP_USER_AGENT = 'MerchantOnboarding';
const APP_SMARTY_KEY = process.env.REACT_APP_APP_SMARTY_CLIENT_KEY;
const CLOUDBEES_APIKEY = process.env.REACT_APP_CLOUDBEES_APIKEY;

export { SENTRY_DSN, APP_USER_AGENT, APP_ENV, GOOGLE_ID, API_URL, GQL_URL, APP_SMARTY_KEY, CLOUDBEES_APIKEY };
