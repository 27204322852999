import { useMutation } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ApplicationLeadReasonPrompt, Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { numericPattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';

import client from 'lib/graphql/client';
import { UPDATE_BANKING_INFO } from 'lib/graphql/mutations';
import { GET_ROUTING_NUMBER_DETAIL } from 'lib/graphql/queries/RoutingNumberDetail';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { AccountInformationModal } from './AccountInformationModal';
import { AccountNameInformationModal } from './AccountNameInformationModal';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';
import { ApplicationLeadPromptReasons, ApplicationLeadPromptStatus } from 'lib/constants';

interface State {
  accountName: string;
  accountNumber: string;
  routingNumber: string;
  mappedAccountType: string;
}

enum ACCOUNT_TYPE {
  BUSINESS_CHECKING = 'Business Checking',
  BUSINESS_SAVINGS = 'Business Savings',
  PERSONAL_CHECKING = 'Personal Checking',
  PERSONAL_SAVINGS = 'Personal Savings',
}

enum ACCOUNT {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

enum ACCOUNT_CLASSIFICATION {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

const ACCOUNT_TYPE_OPTIONS = [
  ACCOUNT_TYPE.BUSINESS_CHECKING,
  ACCOUNT_TYPE.BUSINESS_SAVINGS,
  ACCOUNT_TYPE.PERSONAL_CHECKING,
  ACCOUNT_TYPE.PERSONAL_SAVINGS,
];

const ACCOUNT_TYPE_MAPPING = {
  [ACCOUNT_TYPE.BUSINESS_CHECKING]: {
    accountType: ACCOUNT.CHECKING,
    accountClassification: ACCOUNT_CLASSIFICATION.BUSINESS,
  },
  [ACCOUNT_TYPE.BUSINESS_SAVINGS]: {
    accountType: ACCOUNT.SAVINGS,
    accountClassification: ACCOUNT_CLASSIFICATION.BUSINESS,
  },
  [ACCOUNT_TYPE.PERSONAL_CHECKING]: {
    accountType: ACCOUNT.CHECKING,
    accountClassification: ACCOUNT_CLASSIFICATION.PERSONAL,
  },
  [ACCOUNT_TYPE.PERSONAL_SAVINGS]: {
    accountType: ACCOUNT.SAVINGS,
    accountClassification: ACCOUNT_CLASSIFICATION.PERSONAL,
  },
};

export const getAccountTypeInitialValue = (accountType, accountClassification) => {
  let defaultValue = '';

  if (accountType === ACCOUNT.SAVINGS) {
    defaultValue = ACCOUNT_TYPE.PERSONAL_SAVINGS;
    if (accountClassification === ACCOUNT_CLASSIFICATION.BUSINESS) {
      defaultValue = ACCOUNT_TYPE.BUSINESS_SAVINGS;
    }
  } else {
    defaultValue = ACCOUNT_TYPE.PERSONAL_CHECKING;
    if (accountClassification === ACCOUNT_CLASSIFICATION.BUSINESS) {
      defaultValue = ACCOUNT_TYPE.BUSINESS_CHECKING;
    }
  }

  return defaultValue;
};

const BankingInfo = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const [routingNumberBankName, setRoutingNumberBankName] = useState(null);
  const {
    checkUpdatePromptReasonStatusNeeded,
    createUpdatePromptReasonHandler,
    holdInformationRequestedActive,
    getBankingRelatedReasons,
    checkPromptReasonListHasReason,
    handleNavigateForward,
    loading: updatePromptReasonLoading,
  } = useApplicationLeadPromptReason();

  const [bankingInfo, { loading }] = useMutation(UPDATE_BANKING_INFO);

  const [displayAccountNameInfoModal, setDisplayAccountNameInfoModal] = useState(false);
  const [displayAccountInfoModal, setDisplayAccountInfoModal] = useState(false);
  const [reasonList, setReasonList] = useState<ApplicationLeadPromptReasons[]>([]);
  const [isBankingInfoReasonPromptVisible, setIsBankingInfoReasonPromptVisible] = useState(false);

  const { handleSubmit, control, formState, getValues, trigger, setValue } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;

  useEffect(() => {
    if (holdInformationRequestedActive) {
      const reasons = getBankingRelatedReasons();
      const isBankingReasonInPromptList = reasons.some((reason) => checkPromptReasonListHasReason(reason));

      if (isBankingReasonInPromptList) {
        setReasonList(reasons);
        setIsBankingInfoReasonPromptVisible(true);
      }
    }
  }, [holdInformationRequestedActive]);

  useEffect(() => {
    trackPage('bankingInfo');
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_PAGE_LOAD);
    const { accountType, accountClassification, routingNumber } = sessionData;
    if (accountType && accountClassification) {
      const mappedAccountValue = getAccountTypeInitialValue(accountType, accountClassification);
      setValue('mappedAccountType', mappedAccountValue);

      if (sessionData?.accountNumber) {
        setValue('confirmedAccountNumber', sessionData?.accountNumber);
      }
    }

    if (routingNumber) {
      getRoutingNumberBankName(routingNumber);
    }
  }, []);

  useEffect(() => {
    setPageIndex(9);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Banking Info');
  }, [setPageTitle]);

  const openAccountNameInfoModal = () => {
    setDisplayAccountNameInfoModal(true);
  };
  const closeAccountNameInfoModal = () => {
    setDisplayAccountNameInfoModal(false);
  };

  const openAccountInfoModal = () => {
    setDisplayAccountInfoModal(true);
  };
  const closeAccountInfoModal = () => {
    setDisplayAccountInfoModal(false);
  };

  const getRoutingNumberBankName = async (routingNumber: string) => {
    if (routingNumber?.length > 8) {
      const {
        data: { getRoutingNumberDetail },
      } = await client.query({
        query: GET_ROUTING_NUMBER_DETAIL,
        variables: {
          input: {
            routingNumber,
          },
        },
      });

      if (getRoutingNumberDetail?.bankName) {
        setRoutingNumberBankName(getRoutingNumberDetail.bankName);
      }
    } else {
      if (routingNumberBankName) {
        setRoutingNumberBankName(null);
      }
    }
  };

  const isBankAccountTypeInfoUpdated = (data) => {
    return sessionData?.accountType !== ACCOUNT_TYPE_MAPPING[data?.mappedAccountType]?.accountType;
  };

  const isBankAccountDetailsInfoUpdated = (data) => {
    const { accountName, accountNumber, routingNumber } = data;

    return (
      sessionData?.accountName !== accountName ||
      sessionData?.accountNumber !== accountNumber ||
      sessionData?.routingNumber !== routingNumber
    );
  };

  const onSubmit = async (data: State) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_CONTINUE_CLICKED);
    try {
      if (checkUpdatePromptReasonStatusNeeded()) {
        await Promise.all(
          reasonList.map((reason) =>
            createUpdatePromptReasonHandler(
              reason === ApplicationLeadPromptReasons.BankAccountDetails
                ? isBankAccountDetailsInfoUpdated
                : isBankAccountTypeInfoUpdated,
              reason,
            )(data),
          ),
        );
      }

      const { id } = sessionData;
      const { accountName, accountNumber, routingNumber, mappedAccountType } = data;
      const accountInfo = ACCOUNT_TYPE_MAPPING[mappedAccountType];
      const params = {
        accountName,
        accountNumber,
        routingNumber,
        accountType: accountInfo.accountType,
        applicationLeadId: id,
        accountClassification: accountInfo.accountClassification,
      };

      const {
        data: { updateBankInfo },
      } = await bankingInfo({ variables: { input: params } });
      if (updateBankInfo?.id) {
        setSessionData({ ...params, mappedAccountType });

        if (!holdInformationRequestedActive) {
          navigate('/review');
        } else {
          await handleNavigateForward(reasonList?.[0]);
        }
      } else {
        alert.info('There was an error communicating with your bank. Double-check info and try again.');
      }
    } catch (err) {
      alert.info('There was an error communicating with your bank. Double-check info and try again.');
    }
  };

  const validateConfirmedAccountNumber = (value) => getValues('accountNumber') === value;

  const shouldDisplayForm = () => !!getValues('mappedAccountType');

  const onEditInfo = () => {
    setIsBankingInfoReasonPromptVisible(false);
  };

  const onSkipReasonPrompt = async () => {
    await Promise.all(
      reasonList?.map((reason) =>
        createUpdatePromptReasonHandler(
          reason === ApplicationLeadPromptReasons.BankAccountDetails
            ? isBankAccountDetailsInfoUpdated
            : isBankAccountTypeInfoUpdated,
          reason,
        )({}, ApplicationLeadPromptStatus.Skipped),
      ),
    );

    await handleNavigateForward(reasonList?.[0]);
  };

  return (
    <Container backUrl="/financial-services">
      <ContentContainer>
        <IconContainer>
          <Icon src={'cherry-logo'} width={24} height={24} />
          <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
          <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
          <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
          <Icon src={'bank'} />
        </IconContainer>
        <Title m={'10px 0px 24px 0px'}>Where should we deposit your funds?</Title>
        <Subtitle m={'0px 0px 24px'}>Please add your bank information so we can transfer your funds.</Subtitle>
        <Box>
          <Subtitle>What type of bank account are you using?</Subtitle>
          <Gap size={'16px'} />
          <Controller
            name="mappedAccountType"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                style={{ textAlign: 'left' }}
                label="Account Type"
                placeholder="Account Type"
                id="filled-basic"
                variant="filled"
                data-testid="mappedAccountType"
                select={true}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_TYPE_SELECTED, {
                    value,
                  });
                }}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              >
                {ACCOUNT_TYPE_OPTIONS.map((option: string, index: number) => (
                  <MenuItem data-testid="accountTypeOption" key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Gap size={'16px'} />
          {shouldDisplayForm() ? (
            <>
              <Subtitle>Enter your account information.</Subtitle>
              <Gap size={'10px'} />
              <Row>
                <Icon src={'info_circle_green'} width={24} height={24} />
                <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openAccountInfoModal}>
                  Where can I find this?
                </UnderlinedSubtitle>
              </Row>
              <Gap size={'18px'} />
              <Controller
                name="routingNumber"
                control={control}
                rules={{ required: true, minLength: 9, maxLength: 9 }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    inputProps={{ maxLength: 9 }}
                    id="filled-basic"
                    variant="filled"
                    data-testid="routingNumber"
                    type="tel"
                    placeholder="e.g. 123456789"
                    label="Routing Number"
                    autoComplete="off"
                    value={value}
                    onKeyPress={(event: KeyboardEvent) => {
                      if (!numericPattern.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      getRoutingNumberBankName(event.target.value);
                      onChange(event);
                    }}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_ROUTING_NUMBER_ENTERED, {
                        value,
                      });
                      onBlur();
                    }}
                    error={!!error}
                    helperText={error ? 'Invalid Routing Number' : routingNumberBankName}
                  />
                )}
              />
              <Gap size={'8px'} />
              <Controller
                name="accountNumber"
                control={control}
                rules={{ required: true, minLength: 4, maxLength: 17 }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    inputProps={{ maxLength: 17 }}
                    id="filled-basic"
                    variant="filled"
                    data-testid="accountNumber"
                    placeholder="e.g. 12345678901"
                    type="tel"
                    label="Account Number"
                    autoComplete="off"
                    value={value}
                    onKeyPress={(event) => {
                      if (!numericPattern.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NUMBER_ENTERED, {
                        value,
                      });
                      onBlur();
                    }}
                    onChange={(e) => {
                      onChange(e);
                      if (!!getValues('confirmedAccountNumber')) {
                        trigger('confirmedAccountNumber');
                      }
                    }}
                    error={!!error}
                    helperText={error ? 'Invalid Account Number' : null}
                  />
                )}
              />
              <Gap size={'8px'} />
              <Controller
                name="confirmedAccountNumber"
                control={control}
                rules={{ required: true, minLength: 4, maxLength: 17, validate: validateConfirmedAccountNumber }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    inputProps={{ maxLength: 17 }}
                    id="filled-basic"
                    variant="filled"
                    data-testid="confirmedAccountNumber"
                    type="tel"
                    placeholder="e.g. 12345678901"
                    label="Confirm Account Number"
                    autoComplete="off"
                    value={value}
                    onKeyPress={(event) => {
                      if (!numericPattern.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NUMBER_CONFIRMED, {
                        value,
                      });
                      onBlur();
                    }}
                    onChange={(e) => {
                      onChange(e);
                      trigger('accountName');
                    }}
                    error={!!error}
                    helperText={error ? 'Account Numbers do not match' : null}
                  />
                )}
              />
              <Gap size={'8px'} />
              <Controller
                name="accountName"
                control={control}
                defaultValue=""
                rules={{ required: true, validate: trapSpacesForRequiredFields }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    data-testid="name"
                    label="Account Name"
                    placeholder={'e.g. Smile Today Inc.'}
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NAME_ENTERED, {
                        value,
                      });
                      onBlur();
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Gap size={'10px'} />
              <Row>
                <Icon src={'info_circle_green'} width={24} height={24} />
                <UnderlinedSubtitle onClick={openAccountNameInfoModal} m={'0px 0px 0px 8px'}>
                  What’s my account name?
                </UnderlinedSubtitle>
              </Row>
            </>
          ) : null}
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading || !shouldDisplayForm() || updatePromptReasonLoading}
          loading={loading || updatePromptReasonLoading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </ButtonContainer>
      <AccountInformationModal show={displayAccountInfoModal} hideModal={closeAccountInfoModal} />
      <AccountNameInformationModal show={displayAccountNameInfoModal} hideModal={closeAccountNameInfoModal} />
      {reasonList?.length > 0 && (
        <ApplicationLeadReasonPrompt
          type={reasonList}
          show={!!isBankingInfoReasonPromptVisible}
          onConfirmAndContinue={onSkipReasonPrompt}
          onEditInfo={onEditInfo}
          loading={updatePromptReasonLoading}
        />
      )}
    </Container>
  );
};

export default BankingInfo;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
`;
