import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components';

const PLANS = [3, 6, 12, 18, 24];
const INCLUDED_PLANS = {
  3: [3],
  6: [3],
  12: [3, 6],
  18: [3, 6, 12],
  24: [3, 6, 12, 18],
};

const getBackgroundColor = (selected, included) => {
  let bgColor = '#f2f4f5';

  if (selected) {
    bgColor = '#00C37D';
  } else if (included) {
    bgColor = '#F2FFFB';
  }

  return bgColor;
};

const getTextColor = (selected, included) => {
  let textColor = '#56636d';

  if (selected) {
    textColor = '#FFF';
  } else if (included) {
    textColor = '#00C37D';
  }

  return textColor;
};

const PlanOption = ({ onClick, month, selected, included }) => {
  return (
    <PlanOptionContainer selected={selected} included={included} onClick={() => onClick(month)}>
      <PlanMonthText selected={selected} included={included}>
        {month}
      </PlanMonthText>
      <PlanText selected={selected} included={included}>
        Months
      </PlanText>
      {selected && <CheckIcon width={20} height={20} src={'check_circle'} />}
    </PlanOptionContainer>
  );
};

export const PlanSelector = ({ onSelect, initialValue }) => {
  const [selectedPlan, setSelectedPlan] = useState(initialValue);

  useEffect(() => {
    onSelect(initialValue);
  }, []);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    onSelect(plan);
  };

  return (
    <PlanSelectorContainer>
      {PLANS.map((plan, index) => (
        <PlanOption
          key={index}
          onClick={handlePlanSelect}
          month={plan}
          selected={selectedPlan === plan}
          included={INCLUDED_PLANS[selectedPlan].includes(plan)}
        />
      ))}
    </PlanSelectorContainer>
  );
};

const PlanSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

const PlanOptionContainer = styled.div<{ selected: boolean; included: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 13%;
  height: ${(props) => (props.selected ? '75px' : '49px')};

  border-radius: 8px;
  padding: 24px 8px;
  border: ${(props) => (props.included ? '2px solid #00C37D' : 'none')};

  background-color: ${(props) => getBackgroundColor(props.selected, props.included)};
  cursor: pointer;
`;

const PlanText = styled.div<{ selected: boolean; included: boolean }>`
  color: ${(props) => getTextColor(props.selected, props.included)};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PlanMonthText = styled.div<{ selected: boolean; included: boolean }>`
  color: ${(props) => getTextColor(props.selected, props.included)};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CheckIcon = styled(Icon)`
  margin-top: 16px;
`;
