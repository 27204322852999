import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

import { Icon } from 'lib/components';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const RenderSliderTitle = ({ month }: any) => {
  return (
    <SliderTitleContainer>
      <SliderTitle>{month}</SliderTitle>
      <SliderDesc>Month</SliderDesc>
    </SliderTitleContainer>
  );
};

export const CherrySlider = ({ onChange }) => {
  const marks = [
    {
      value: 1,
      label: <RenderSliderTitle month={3} />,
    },
    {
      value: 2,
      label: <RenderSliderTitle month={6} />,
    },
    {
      value: 3,
      label: <RenderSliderTitle month={12} />,
    },
    {
      value: 4,
      label: <RenderSliderTitle month={18} />,
    },
    {
      value: 5,
      label: <RenderSliderTitle month={24} />,
    },
  ];

  const formatMarkValue = (key) => {
    const markValueObject = {
      1: 3,
      2: 6,
      3: 12,
      4: 18,
      5: 24,
    };
    return markValueObject[key];
  };

  const ThumbComponent = (props: any) => {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <ThumbContainer>
          <Icon src={'unfold_more'} hover={true} />
        </ThumbContainer>
      </SliderThumb>
    );
  };

  const onSliderChange = (value) => {
    onChange(formatMarkValue(value));
  };

  return (
    <SliderContainer>
      <StyledSlider
        aria-label="ios slider"
        components={{ Thumb: ThumbComponent }}
        // @ts-ignore
        getAriaValueText={onSliderChange}
        data-testid="control-percentage"
        defaultValue={1}
        marks={marks}
        min={0}
        max={6}
        step={null}
      />
    </SliderContainer>
  );
};

const StyledSlider = muiStyled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#00C37C' : '#00C37C',
  height: 4,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 40,
    width: 40,
    pointerEvents: 'none',
    backgroundColor: '#00C37C',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: '#56636D',
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'white',
    height: 8,
    width: 8,
    borderRadius: '50%',
    border: '2px solid #56636D',

    '&.MuiSlider-markActive': {
      backgroundColor: 'white',
      height: 8,
      width: 8,
      borderRadius: '50%',
      border: '2px solid #00C37C',
      opacity: 1,
    },
  },
  '& .MuiSlider-markLabel': {
    color: '#56636D',

    '&.MuiSlider-markLabelActive': {
      color: '#00C37C',
    },
  },
}));

const SliderContainer = styled.div`
  margin-top: 65px;
`;

const SliderTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
`;
const SliderTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
`;
const SliderDesc = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const ThumbContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding; 10;
  border-radius: 50%;
`;
