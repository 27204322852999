import { gql } from '@apollo/client/core';

export const GET_APPLICATION_LEADS = gql`
  query getApplicationLeads($input: ApplicationLeadListingRequest) {
    getApplicationLeads(input: $input) {
      total
      contents {
        userId
        name
        dba
        industry
        phone
        website
        address {
          street
          unit
          city
          state
          zip
        }
        role
        ownerUserId
        establishedAt
        id
        status
        createdAt
        updatedAt
        financingOptions
        hasNoRefundPolicy
        isFirstLook
        firstLookDeclineExplanation
        bankInfo {
          accountName
          accountNumber
          accountType
          routingNumber
          accountClassification
        }
        requirements {
          reason
          description
          instructions
          isFileUpload
          id
          status
          createdAt
          updatedAt
        }
        reference {
          ref
          source
        }
      }
    }
  }
`;

export const GET_APPLICATION_LEAD_DETAIL = gql`
  query getApplicationLeadDetail($input: ApplicationLeadDetailRequest) {
    getApplicationLeadDetail(input: $input) {
      userId
      id
      status
      createdAt
    }
  }
`;

export const GET_APPLICATION_TRAINING_LINK = gql`
  query fetchTrainingLink($input: FetchTrainingLinkRequest) {
    fetchTrainingLink(input: $input) {
      onboardingRegistrationLink
      status
      error
    }
  }
`;
