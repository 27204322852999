import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore, { Requirement } from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';

export interface ClickLinkFormProps {
  buttonText?: string;
  buttonUrl: string;
  children?: ReactNode;
  icon?: string;
  reason: string;
  subtitle?: string;
  title: string;
}

export const ClickLinkForm = ({
  buttonText = 'link',
  buttonUrl,
  children,
  icon = 'zoom',
  reason,
  subtitle,
  title,
}: ClickLinkFormProps) => {
  const navigate = useNavigate();
  const { sessionData } = useStore();
  const { submitApplicationLeadRequirement } = useApplicationLeadRequirementSubmit();
  const [clicked, setClicked] = useState(false);

  const { requirements = [] }: { requirements: Requirement[] } = sessionData;

  const openLink = async () => {
    setClicked(true);
    window.open(buttonUrl, '_blank');

    return;
  };

  const done = async () => {
    const requirement = requirements.find((req: Requirement) => req.reason === reason);
    const res = await submitApplicationLeadRequirement({
      applicationLeadId: sessionData?.id,
      requirementId: requirement?.id,
    });

    if (res) {
      navigate('/provide-more-info');
    }
  };

  return (
    <Container backUrl={'/provide-more-info'}>
      <ContentContainer>
        <Icon src={icon} />
        <Title m={'12px 0 8px 0'}>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children && <Subtitle>{children}</Subtitle>}
      </ContentContainer>
      <StyledButtonContainer>
        <Button disabled={clicked} onClick={openLink}>
          {buttonText}
        </Button>
        {clicked && <Button onClick={done}>Mark as Scheduled</Button>}
      </StyledButtonContainer>
    </Container>
  );
};

const StyledButtonContainer = styled(ButtonContainer)`
  > button {
    margin-bottom: 8px;
  }
`;
