export const theme = {
  main: {
    darkBlue: '#0e1f2e',
    midnightBlue: '#0E202F',
    midnightBlue50: '#879097',
    white: '#ffffff',
    black: '#000000',
    green: '#00c37d',
    green5: '#f2fffb',
    green10: '#e6f9f2',
    disabledBtn: '#777',
    textColor: '#0E202F',
    borderColor: '#979797',
    grayColor: '#868F97',
    gray5: '#F2F4F5',
  },
  constants: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
  },
  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '430px',
    mobileXl: '680px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};
