import { ApolloProvider } from '@apollo/client';
import { Suspense } from 'react';
import { positions, Provider as AlertProvider } from 'react-alert';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AlertTemplate, Loading } from 'lib/components';
import ApolloClient from 'lib/graphql/client';
import Router from 'Route';

const alertOptions = {
  timeout: 4000,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: 100,
    marginBottom: '50px',
  },
};

function App() {
  return (
    <ApolloProvider client={ApolloClient}>
      <Suspense
        fallback={
          // @ts-ignore
          <CenterLoading>
            {/*// @ts-ignore*/}
            <Loading size={40} />
          </CenterLoading>
        }
      >
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AlertProvider>
      </Suspense>
    </ApolloProvider>
  );
}

export default App;

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
