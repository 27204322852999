import { useMutation } from '@apollo/client';
import { UPLOAD_APPLICATION_LEAD_REQUIREMENT_FILE } from 'lib/graphql/mutations';

export const useUploadApplicationLeadRequirementFile = () => {
  const [uploadApplicationLeadRequirementFile, { loading }] = useMutation(UPLOAD_APPLICATION_LEAD_REQUIREMENT_FILE);

  const uploadApplicationLeadRequirement = async (data) => {
    const response = await uploadApplicationLeadRequirementFile({ variables: { input: data } });

    return !!response?.data?.uploadApplicationLeadRequirementFile?.success;
  };

  return { uploadApplicationLeadRequirement, loading };
};
