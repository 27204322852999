import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ApplicationLeadReasonPrompt,
  Box,
  Button,
  Container,
  Icon,
  OwnerDobInput,
  PhoneInput,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

import { CREATE_USER } from 'lib/graphql/mutations';
import StorageService from 'lib/services/Storage';
import { emailPattern, phonePattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';

import { DuplicatePersonalInfoModal } from 'pages/OwnerInfo/DuplicatePersonalInfoModal';
import { OwnerNameInfoModal } from 'pages/PersonalInfo/OwnerNameInfoModal';
import { USER_ROLE, USER_ROLE_TITLES } from 'pages/UserRole/UserRole';
import { OwnerInfoReasonPrompt } from 'pages/PersonalInfo/OwnerInfoReasonPrompt';
import { ApplicationLeadPromptReasons } from 'lib/constants';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';

interface FormData {
  ownerFirstname: string;
  ownerLastname: string;
  ownerEmail: string;
  ownerPhone: string;
}
const OwnerInfo = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setPageTitle, setSessionData, sessionData, userData } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const [createOwnerUser, { loading }] = useMutation(CREATE_USER);
  const {
    holdInformationRequestedActive,
    checkUpdatePromptReasonStatusNeeded,
    createUpdatePromptReasonHandler,
    checkPromptReasonListHasReason,
    updateApplicationLeadInfo,
    handleNavigateForward,
    loading: updatePromptReasonLoading,
  } = useApplicationLeadPromptReason();

  const pocUserDetail = StorageService.getUserData();
  const [displayOwnerInfoModal, setDisplayOwnerInfoModal] = useState(false);

  const [displayDuplicateModal, setDisplayDuplicateModal] = useState(false);
  const [isDuplicateErrorOccured, setIsDuplicateErrorOccured] = useState(false);
  const [isReasonPromptVisible, setIsReasonPromptVisible] = useState(false);
  const [isOwnerInfoReasonPromptVisible, setIsOwnerInfoReasonPromptVisible] = useState(false);
  const [isOwnerDobInputVisible, setIsOwnerDobInputVisible] = useState(false);
  const [updateOwnerDobLoading, setUpdateOwnerDobLoading] = useState(false);

  const { handleSubmit, control, formState, setValue, reset, setError } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;

  const closeDuplicateModal = () => {
    setDisplayDuplicateModal(false);
  };

  useEffect(() => {
    const isOwnerReasonInPromptList =
      holdInformationRequestedActive && checkPromptReasonListHasReason(ApplicationLeadPromptReasons.Owner);

    if (isOwnerReasonInPromptList) {
      setIsOwnerInfoReasonPromptVisible(true);
    }
  }, [holdInformationRequestedActive]);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (sessionData.id || sessionData.firstName) {
      const { ownerFirstname, ownerLastname, ownerEmail, ownerPhone } = sessionData;
      setValue('ownerFirstname', ownerFirstname);
      setValue('ownerLastname', ownerLastname);
      setValue('ownerEmail', ownerEmail);
      setValue('ownerPhone', ownerPhone);
    }
  }, [sessionData]);

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle("Owner's Info");
    trackPage('ownerInfo');
  }, [setPageTitle]);

  const validateBeforeSubmit = (data: FormData) => {
    if (pocUserDetail?.user?.email === data.ownerEmail || userData?.phone === data.ownerPhone) {
      setDisplayDuplicateModal(true);
    } else {
      return onSubmit(data);
    }
  };

  const handleContinueAsOwner = () => {
    reset({
      ownerFirstname: '',
      ownerLastname: '',
      ownerEmail: '',
      ownerPhone: '',
    });
    setSessionData({ role: USER_ROLE.OWNER });
    closeDuplicateModal();
    navigate('/business-info');
  };

  const handleFormClear = () => {
    reset({
      ownerFirstname: '',
      ownerLastname: '',
      ownerEmail: '',
      ownerPhone: '',
    });
    setError('ownerFirstname', { type: 'required' });
    setError('ownerLastname', { type: 'required' });
    setError('ownerEmail', { type: 'required' });
    setIsDuplicateErrorOccured(true);
    closeDuplicateModal();
  };

  const openOwnerInfoModal = () => {
    setDisplayOwnerInfoModal(true);
  };
  const closeOwnerInfoModal = () => {
    setDisplayOwnerInfoModal(false);
  };

  const isInformationUpdated = (data) => {
    const { firstName, lastName, phone, ownerDob } = data;
    const { pocFirstName, pocLastName, pocPhone, ownerDob: pocOwnerDob } = sessionData;

    return pocFirstName !== firstName || pocLastName !== lastName || pocPhone !== phone || ownerDob !== pocOwnerDob;
  };

  const onSubmit = async (data: any) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_CONTINUE_CLICKED);

    if (checkUpdatePromptReasonStatusNeeded()) {
      await createUpdatePromptReasonHandler(isInformationUpdated, ApplicationLeadPromptReasons.Owner)(data);
    }

    const { ownerPhone, ownerEmail, ownerFirstname, ownerLastname } = data;

    if (data?.ownerDob) {
      setUpdateOwnerDobLoading(true);
      await updateApplicationLeadInfo({ ownerDob: data.ownerDob });
      setUpdateOwnerDobLoading(false);
    }

    const {
      data: { createOwnerUserRequest },
    } = await createOwnerUser({
      variables: {
        input: { phone: ownerPhone, email: ownerEmail, firstName: ownerFirstname, lastName: ownerLastname },
      },
    });

    if (createOwnerUserRequest?.user?.id) {
      setSessionData({
        ownerFirstname: createOwnerUserRequest.user.firstName,
        ownerLastname: createOwnerUserRequest.user.lastName,
        ownerEmail: createOwnerUserRequest.user.email,
        ownerPhone: createOwnerUserRequest.user.phone,
        ownerUserId: createOwnerUserRequest.user.id,
      });

      handleNavigateForward(ApplicationLeadPromptReasons.Owner);

      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_CONTINUE_CLICKED);
    } else {
      alert.info(createOwnerUserRequest?.message);
    }
  };

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event?.target || {};

    if (name === 'firstName') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_CLICKED);
    } else if (name === 'lastName') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_LAST_NAME_CLICKED);
    }
  };

  const openReasonPrompt = () => {
    setIsReasonPromptVisible(true);
  };

  const closeReasonPrompt = () => {
    setIsReasonPromptVisible(false);
  };

  const onEditInfo = () => {
    setIsOwnerDobInputVisible(true);
    setIsOwnerInfoReasonPromptVisible(false);
  };

  return (
    <Container backUrl="/personal-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'user_circle'} />
          <Title m={'10px 0px 24px 0px'}>Tell us about the business owner</Title>
          <Subtitle m={'0 0 24px 0'}>
            For account security and fraud prevention, please list the owner’s information as follows.
          </Subtitle>
          <Box>
            <Controller
              name="ownerFirstname"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="firstName"
                  data-testid="firstName"
                  label="Owner's First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_FILLED, {
                      value,
                    });
                  }}
                />
              )}
            />
            <Controller
              name="ownerLastname"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="lastName"
                  data-testid="lastName"
                  label="Owner's Last Name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Row>
              <Icon src={'info_circle_green'} width={24} height={24} />
              <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openOwnerInfoModal}>
                What name should I use?
              </UnderlinedSubtitle>
            </Row>
            <Controller
              name="ownerEmail"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: emailPattern, minLength: 2, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="email"
                  inputProps={{ maxLength: 255 }}
                  variant="filled"
                  data-testid="email"
                  label="Owner's Email"
                  value={value}
                  onChange={(event) => {
                    onChange(event?.target?.value?.toLowerCase());
                  }}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_EMAIL_FILLED, {
                      value,
                    });
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error?.type === 'maxLength' ? 'Max length exceed' : null}
                  onFocus={onInputFocus}
                />
              )}
            />
            <Controller
              name="ownerPhone"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: phonePattern }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="phone"
                  data-testid="phone"
                  type="tel"
                  label="Owner's Personal Cell  Number"
                  InputProps={{
                    inputComponent: PhoneInput as any,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_PHONE_FILLED, {
                      value,
                    });
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Row>
              <Icon src={'info_circle_green'} width={24} height={24} />
              <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openReasonPrompt}>
                What phone number should I use?
              </UnderlinedSubtitle>
            </Row>
            {isOwnerDobInputVisible && <OwnerDobInput control={control} />}
          </Box>
        </InnerContainer>
        {isDuplicateErrorOccured && (
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              Please enter the <b>business owner</b>'s information. Since you are not the business owner, this
              information should not match your personal information.
            </InfoText>
          </InfoContainer>
        )}
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            <>
              You must provide the <b>owner’s personal cell phone number, not an office line.</b> This is required to
              approve your practice. This number will never be shared with patients.
            </>
          </InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading || updateOwnerDobLoading}
          loading={loading || updateOwnerDobLoading}
          onClick={handleSubmit(validateBeforeSubmit)}
        >
          Continue
        </Button>
      </ButtonContainer>
      <DuplicatePersonalInfoModal
        show={displayDuplicateModal}
        hideModal={closeDuplicateModal}
        handleContinue={handleContinueAsOwner}
        handleInputClear={handleFormClear}
        selectedRole={USER_ROLE_TITLES[sessionData?.role]}
      />
      <OwnerNameInfoModal hideModal={closeOwnerInfoModal} show={displayOwnerInfoModal} />
      <OwnerInfoReasonPrompt show={isReasonPromptVisible} hideModal={closeReasonPrompt} />
      <ApplicationLeadReasonPrompt
        type={ApplicationLeadPromptReasons.Owner}
        show={!!isOwnerInfoReasonPromptVisible}
        onEditInfo={onEditInfo}
        loading={updatePromptReasonLoading}
      />
    </Container>
  );
};

export default OwnerInfo;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
`;
