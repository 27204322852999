import { ClickLinkForm } from 'lib/components';

const AddApplicationReview = () => {
  return (
    <ClickLinkForm
      reason="R_ApplicationReview"
      title="Application Review"
      subtitle="We need to verify some details on your registration. We ask that you block off a 30 minute window to review your registration and verify your location via Zoom. Most reviews are completed within 15 minutes. You must be at your place of business to complete the review. Please schedule a time with one of our underwriters using the button below:"
      buttonText="Schedule Application Review"
      buttonUrl="https://withcherry.chilipiper.com/book/underwriting-application-review"
      icon="application-review"
    />
  );
};

export default AddApplicationReview;
