import styled from 'styled-components';

export const ContentContainer = styled.div<{ justify?: string; noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  padding: 0px 24px 20px 24px;
  margin-top: ${(props) => (props.noMargin ? '0px' : '30px')};
  background-color: white;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    // overflow-x: scroll;
  }
`;

export const ButtonContainer = styled.div`
  background-color: white;
  padding: 24px 24px 14px 24px;
  box-shadow: inset 0px 1px 0px #dadada;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
