import { useRef } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, DateInput, Icon, PhoneInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore, { Requirement } from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';

interface State {
  firstName: string;
  lastName: string;
  dba: string;
  phone: string;
}

const AddBusinessOwnerInfo = () => {
  const navigate = useNavigate();
  const dbaRef = useRef({});
  const { sessionData, setPageTitle } = useStore();
  const { handleSubmit, control, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();

  const { isValid } = formState;
  dbaRef.current = watch('dba', '');
  const { requirements = [] }: { requirements: Requirement[] } = sessionData;

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const onSubmit = async (data: State) => {
    const requirement = requirements.find((req: Requirement) => req.reason === 'R_OwnerInformation');
    const concatSubmittedInformation = `Name: ${data.firstName}, Lastname: ${data?.lastName}, Phone: ${data?.phone}, Date of Birth: ${data?.dba}`;
    const res = await submitApplicationLeadRequirement({
      submittedInformation: concatSubmittedInformation,
      applicationLeadId: sessionData?.id,
      requirementId: requirement?.id,
    });

    if (res) {
      navigate('/provide-more-info');
    }
  };

  const buttonDisabled = !isValid;

  return (
    <Container backUrl="/provide-more-info">
      <ContentContainer>
        <Icon src={'user_circle'} />
        <Title m={'10px 0px 24px 0px'}>Owner’s information</Title>
        <Subtitle m={'10px 0px 24px 0px'}>
          Please provide the following information for the business <b>owner</b>.
        </Subtitle>
        <Box>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="firstName"
                variant="filled"
                data-testid="firstName"
                label="Owner's First Name"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="lastName"
                variant="filled"
                data-testid="lastName"
                label="Owner's Last Name"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="phone"
                variant="filled"
                data-testid="phone"
                label="Owner's Phone Number"
                InputProps={{
                  inputComponent: PhoneInput as any,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="dba"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="dob"
                variant="filled"
                data-testid="dob"
                type="tel"
                label="Owner's Date of Birth (mm/dd/yyyy)"
                InputProps={{
                  inputComponent: DateInput as any,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={buttonDisabled || loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddBusinessOwnerInfo;
