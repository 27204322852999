import { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { confettiColors } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { ExternalRedirect } from 'lib/utils/ExternalRedirect';
import { theme } from '../../config/theme';

const Result = () => {
  const { setPageIndex, setPageTitle } = useStore();
  const [confettiActive, setConfettiActive] = useState(false);
  const { trackPage } = useSegment();

  const containerRef: any = useRef(null);

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 5000);
  }, []);

  useEffect(() => {
    trackPage('submitted');
    setPageIndex(12);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Submitted');
  }, [setPageTitle]);

  const unfreezeHandler = () => {
    ExternalRedirect('https://www.creditkarma.com/id-theft/i/lift-credit-freeze', '_blank');
  };

  return (
    <Container>
      <ContentContainer ref={containerRef} noMargin={true} justify="space-between">
        <InnerContainer>
          <Icon src="party" />
          <Title m={'10px 0px'}>Your application is in review.</Title>
          <Subtitle m={'0px 0px 20px'}>
            Thank you for submitting your application. Your application will be reviewed and processed within{' '}
            <b>1 to 3 business days.</b>
          </Subtitle>
          <Subtitle m={'0px 0px 50px'}>
            You can return to check the status at any time. We’ll notify you when your status changes, or if we need any
            additional information.
          </Subtitle>
          <InnerTitle>While you’re waiting...</InnerTitle>
          <Subtitle m={'0px 0px 50px'}>
            if you’ve frozen your credit, you may want to temporarily un-freeze it. This will expedite your approval.{' '}
            <InfoTextUnderline color={theme.main.green} onClick={unfreezeHandler}>
              Show me how
            </InfoTextUnderline>
          </Subtitle>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            While waiting, if you’ve frozen your credit, you may want to temporarily un-freeze it. This will expedite
            your approval.<InfoTextUnderline onClick={unfreezeHandler}>Learn More</InfoTextUnderline>
          </InfoText>
        </InfoContainer>
        {confettiActive && (
          <ConfettiContainer>
            <Confetti
              width={containerRef.current?.clientWidth}
              height={containerRef.current?.clientHeight}
              numberOfPieces={300}
              colors={confettiColors}
              recycle={false}
            />
          </ConfettiContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default Result;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const InnerTitle = styled(Title)`
  //styleName: Heading/h5;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #56636d;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.span<{ color?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props?.color || '#0e202f'};
`;

const InfoTextUnderline = styled(InfoText)<{ color?: string }>`
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;
