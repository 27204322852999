import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import client from 'lib/graphql/client';
import { GET_USER_DETAIL } from 'lib/graphql/queries/User';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore, { ApplicationLead, initialSessionData } from 'lib/hooks/useStore';
import { FormatDate } from 'lib/utils';
import { USER_ROLE } from 'pages/UserRole/UserRole';
import { useNavigate } from 'react-router-dom';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';
import { useGetApplicationLeadPromptReasons } from 'lib/hooks/useGetApplicationLeadPromptReasons';

const MultipleApplicationSelection = () => {
  const {
    setPageTitle,
    sessionData,
    setSessionData,
    userData,
    setSelectedApplicationLeadId,
    setSelectedApplicationLead,
  } = useStore();
  const navigate = useNavigate();
  const { trackSegmentEvent, trackPage } = useSegment();
  const { getRouteNameFromPromptReasonList, setPromptReasonListInSessionData } = useApplicationLeadPromptReason();
  const { getApplicationLeadPromptReasons } = useGetApplicationLeadPromptReasons(sessionData?.id);

  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const { applicationLeads = [] }: { applicationLeads: ApplicationLead[] | undefined } = sessionData || {};
  const { isValid } = formState;

  useEffect(() => {
    setPageTitle('Select Organization');
    trackPage('selectOrganization');
  }, [setPageTitle]);

  useEffect(() => {
    setSessionData({ ...initialSessionData, applicationLeads });
  }, []);

  const getActiveApplication = (data) => {
    return applicationLeads?.find((application: ApplicationLead) => application.id === data?.applicationLeadId);
  };

  const handleOwnerUserDetail = async ({ ownerUserId, role }) => {
    let ownerUserDetail;

    if (ownerUserId && role !== USER_ROLE.OWNER) {
      const {
        data: { getUserDetail },
      } = await client.query({
        query: GET_USER_DETAIL,
        variables: {
          input: {
            userId: ownerUserId,
          },
        },
      });

      if (!getUserDetail.error) {
        ownerUserDetail = getUserDetail.user;
      }
    }

    return ownerUserDetail;
  };

  const handleActiveApplicationData = async (activeApplication) => {
    if (activeApplication) {
      const {
        id,
        name,
        dba,
        industry,
        phone,
        website,
        financingOptions,
        address = {},
        establishedAt,
        hasNoRefundPolicy,
        ownerUserId,
        role,
        bankInfo = {},
        promoPeriod,
        requirements,
      } = activeApplication;

      const ownerUserDetail = await handleOwnerUserDetail({ ownerUserId, role });

      const params = {
        id,
        name,
        dba,
        industry,
        phone,
        website,
        financingOptions,
        role,
        establishedAt: FormatDate(establishedAt),
        hasNoRefundPolicy,
        ownerUserId,
        ownerFirstname: ownerUserDetail?.firstName,
        ownerLastname: ownerUserDetail?.lastName,
        ownerEmail: ownerUserDetail?.email,
        ownerPhone: ownerUserDetail?.phone,
        promoPeriod,
        requirements,
      };
      setSessionData({ ...params, ...address, ...bankInfo });
    }
  };

  const handleNavigateInitializedOrDraftedApplication = (activeApplication) => {
    if (!activeApplication?.role) {
      navigate('/user-role');
    } else if (activeApplication?.id && !activeApplication?.bankInfo) {
      navigate('/banking-info');
    } else if (activeApplication?.id && !activeApplication?.promoPeriod) {
      navigate('/pricing-review');
    } else if (activeApplication?.id && activeApplication?.isFirstLook === null) {
      navigate('/first-look');
    } else {
      navigate('/preparation-list');
    }
  };

  const handleNavigateHoldInformationRequestedApplication = async (activeApplication) => {
    const res = await getApplicationLeadPromptReasons({
      variables: {
        input: {
          applicationLeadId: activeApplication?.id,
        },
      },
    });

    const reasonList = res?.data?.getApplicationLeadPromptReasons?.applicationLeadPromptReasons;
    setPromptReasonListInSessionData(reasonList);
    const routeName = getRouteNameFromPromptReasonList(reasonList);
    const { role } = activeApplication || {};
    navigate(routeName === '/personal-info' && role !== USER_ROLE.OWNER ? '/owner-info' : routeName);
  };

  const handleNavigateBasedOnRequirements = (activeApplication) => {
    const { requirements = [] } = activeApplication;
    if (requirements.length > 0) {
      navigate('/provide-more-info');
    } else {
      navigate('/application-status');
    }
  };

  const handleRedirectUserBasedOnStatus = async (activeApplication) => {
    if (['ADDITIONAL_MATERIALS_REQUESTED', 'RISK_REVIEW', 'IN_REVIEW'].includes(activeApplication?.status)) {
      handleNavigateBasedOnRequirements(activeApplication);
    } else if (activeApplication?.status === 'HOLD_INFORMATION_REQUESTED') {
      await handleNavigateHoldInformationRequestedApplication(activeApplication);
    } else if (
      [
        'SUBMITTED',
        'ARCHIVED',
        'HOLD_SERVICES',
        'HOLD_RESTRICTED_STATE',
        'HOLD_LOW_PRIORITY',
        'HOLD_FIRST_LOOK',
      ].includes(activeApplication?.status)
    ) {
      navigate('/application-status');
    } else if (activeApplication?.status === 'INITIALIZED' || activeApplication?.status === 'DRAFTED') {
      handleNavigateInitializedOrDraftedApplication(activeApplication);
    } else if (activeApplication?.status === 'APPROVED') {
      navigate('/application-approved');
    } else if (activeApplication?.status === 'DENIED') {
      navigate('/application-declined');
    } else {
      navigate('/submitted');
    }
  };

  const onSubmit = async (data) => {
    trackSegmentEvent(SegmentEventNames.SELECT_ORGANIZATION_CONTINUE);
    setSelectedApplicationLeadId(data.applicationLeadId);

    const activeApplication = getActiveApplication(data);
    setSelectedApplicationLead(activeApplication);
    await handleActiveApplicationData(activeApplication);

    if (activeApplication?.status) {
      await handleRedirectUserBasedOnStatus(activeApplication);
    } else {
      navigate('/preparation-list');
    }
  };

  const registerNewBusiness = () => {
    trackSegmentEvent(SegmentEventNames.REGISTER_NEW_BUSINESS);
    navigate('/user-role');
  };

  const handleOnApplicationLeadChange = (event, onChange) => {
    trackSegmentEvent(SegmentEventNames.SELECT_ORGANIZATION_ONCHANGE);
    onChange(event);
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'user-group'} />
        <Title m={'10px 0px 8px 0px'}>Welcome back {userData?.firstName}!</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          Your account is associated one or more organizations. Please select the one you would like to access in this
          session.
        </Subtitle>
        <Box>
          <Controller
            name="applicationLeadId"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="email-input"
                variant="filled"
                data-testid="applicationLeadSelection"
                label="Select Organization"
                type="email"
                select={true}
                value={value}
                onChange={(e) => handleOnApplicationLeadChange(e, onChange)}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              >
                {applicationLeads?.map((applicationLead) => (
                  <MenuItem data-testid="industryItem" key={0} value={applicationLead?.id}>
                    {applicationLead?.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          Continue
        </Button>
        <Button secondary={true} onClick={registerNewBusiness}>
          Register a New Business
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default MultipleApplicationSelection;
