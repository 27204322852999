import { gql } from '@apollo/client/core';

export const GET_USER_DETAIL = gql`
  query getUserDetail($input: UserDetailInput) {
    getUserDetail(input: $input) {
      status
      message
      error
      user {
        id
        email
        firstName
        lastName
        phone
      }
    }
  }
`;
