import React, { useEffect } from 'react';
import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';

import { Icon, Subtitle, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { ApplicationLeadPromptReasons } from 'lib/constants';
import { REASON_PROMPT_CONTENT } from './ReasonPromptContent';
import { useSegment } from 'lib/hooks/useSegment';

type ApplicationLeadReasonPromptProps = {
  show: boolean;
  type: ApplicationLeadPromptReasons | ApplicationLeadPromptReasons[];
  onConfirmAndContinue?: () => void;
  onEditInfo: () => void;
  loading?: boolean;
};

const ApplicationLeadPromptSegmentEvents = {
  [ApplicationLeadPromptReasons.BankAccountType]: 'PROMPT_BANK_ACCOUNT_TYPE',
  [ApplicationLeadPromptReasons.BankAccountDetails]: 'PROMPT_BANK_ACCOUNT_DETAILS',
  [ApplicationLeadPromptReasons.Owner]: 'PROMPT_OWNER_INFO',
  [ApplicationLeadPromptReasons.Npi]: 'PROMPT_NPI_INFO',
  [ApplicationLeadPromptReasons.Address]: 'PROMPT_ADDRESS_INFO',
};

export const ApplicationLeadReasonPrompt = ({
  show,
  type,
  onConfirmAndContinue,
  onEditInfo,
  loading = false,
}: ApplicationLeadReasonPromptProps) => {
  const { title, subtitle, infoText, confirmButtonText, editButtonText } =
    REASON_PROMPT_CONTENT[Array.isArray(type) ? type[0] : type];
  const {
    sessionData: { city, unit, street, zip, state, accountName, accountNumber, routingNumber, accountType },
  } = useStore();
  const { trackSegmentEvent } = useSegment();

  const segmentEventNamePrefix = `PRACTICE_REGISTRATION_${
    ApplicationLeadPromptSegmentEvents[Array.isArray(type) ? type[0] : type]
  }`;

  useEffect(() => {
    if (show) {
      trackSegmentEvent(`${segmentEventNamePrefix}_MODAL_LOAD`);
    }
  }, [show]);

  const handleClose = () => {
    if (onConfirmAndContinue) {
      onConfirmAndContinue();
    } else {
      onEditInfo();
    }
  };

  const handleConfirmAndContinue = () => {
    if (type === ApplicationLeadPromptReasons.BankAccountType) {
      trackSegmentEvent(`${segmentEventNamePrefix}_CONFIRM_CLICKED`);
    } else {
      trackSegmentEvent(`${segmentEventNamePrefix}_SKIP_CLICKED`);
    }
    onConfirmAndContinue?.();
  };

  const handleEditAddressInfo = () => {
    if (type === ApplicationLeadPromptReasons.Owner) {
      trackSegmentEvent(`${segmentEventNamePrefix}_ADD_DOB_CLICKED`);
    } else {
      trackSegmentEvent(`${segmentEventNamePrefix}_EDIT_CLICKED`);
    }
    onEditInfo();
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContent>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{title}</Title>
            <Icon src={'close'} onClick={handleClose} />
          </ModalHeader>
          <Subtitle>{subtitle}</Subtitle>

          {type === ApplicationLeadPromptReasons.Address && (
            <DetailedInfoWrapper>
              <DetailedInfoWrapperTitle>
                Business Address <Icon src={'warning_outline'} width={20} height={20} />
              </DetailedInfoWrapperTitle>
              <AddressLabel>Address: {street}</AddressLabel>
              <AddressLabel>Apt / Unit: {unit}</AddressLabel>
              <AddressLabel>City: {city}</AddressLabel>
              <AddressLabel>State: {state}</AddressLabel>
              <AddressLabel>ZIP Code: {zip}</AddressLabel>
            </DetailedInfoWrapper>
          )}

          {((Array.isArray(type) && type.includes(ApplicationLeadPromptReasons.BankAccountDetails)) ||
            (Array.isArray(type) && type.includes(ApplicationLeadPromptReasons.BankAccountType))) && (
            <DetailedInfoWrapper>
              <DetailedInfoWrapperTitle>
                Banking Information
                {Array.isArray(type) && type.includes(ApplicationLeadPromptReasons.BankAccountDetails) && (
                  <Icon src={'warning_outline'} width={20} height={20} />
                )}
              </DetailedInfoWrapperTitle>
              <AddressLabel>Account Name: {accountName}</AddressLabel>
              <AddressLabel>Account Number: {accountNumber}</AddressLabel>
              <AddressLabel>Routing Number: {routingNumber}</AddressLabel>
              <AddressLabel>
                Account Type: {accountType}
                {Array.isArray(type) && type.includes(ApplicationLeadPromptReasons.BankAccountType) && (
                  <Icon src={'warning_outline'} width={20} height={20} />
                )}
              </AddressLabel>
            </DetailedInfoWrapper>
          )}

          {!!infoText && (
            <InfoWrapper>
              <Icon src={'info_circle_outline'} width={28} height={28} />
              <Subtitle>{infoText}</Subtitle>
            </InfoWrapper>
          )}

          <Gap size={'24px'} />
          <Button onClick={handleEditAddressInfo} loading={loading}>
            {editButtonText}
          </Button>
          {onConfirmAndContinue && (
            <Button onClick={handleConfirmAndContinue} loading={loading}>
              {confirmButtonText}
            </Button>
          )}
        </ModalContent>
      </Slide>
    </Backdrop>
  );
};

const ModalContent = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 40px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div<{ loading?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.midnightBlue};
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.main.midnightBlue};
  cursor: pointer;

  ${(props) => {
    if (props.loading) {
      return `
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.4
      `;
    }
  }}
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const DetailedInfoWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--blue-light-5, #f2f4f5);
  margin: 24px 0;
`;

const DetailedInfoWrapperTitle = styled(Subtitle)`
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddressLabel = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
