import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const DateInput = React.forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const onAcceptHandler = (value: string) => {
    if (value.length === 10 && validateDateValue(value)) {
      onChange({ target: { name: props.name, value } });
    } else if (value.length !== 10) {
      onChange({ target: { name: props.name, value } });
    }
  };

  const validateDateValue = (enteredDate) => {
    const splittedDate = enteredDate.split('/');
    const userDate = new Date(splittedDate[2], splittedDate[0] - 1, splittedDate[1]);
    const today = new Date();

    return today > userDate;
  };

  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Date}
      max={new Date()}
      maxLength={10}
      pattern={'m/`d/`YYYY'}
      inputRef={ref}
      autofix={true}
      onAccept={onAcceptHandler}
      overwrite={true}
    />
  );
});
