import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ApplicationLeadReasonPrompt,
  Box,
  Button,
  Container,
  Icon,
  OwnerDobInput,
  PhoneInput,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { PATCH_APPLICATION_LEAD_USER } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { trapSpacesForRequiredFields } from 'lib/utils/Validators';
import { phonePattern } from 'lib/utils/Validators';

import StorageService from 'lib/services/Storage';
import { OwnerNameInfoModal } from 'pages/PersonalInfo/OwnerNameInfoModal';
import { USER_ROLE } from 'pages/UserRole/UserRole';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';
import { ApplicationLeadPromptReasons } from 'lib/constants';
import { OwnerInfoReasonPrompt } from './OwnerInfoReasonPrompt';

const PersonalInfo = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setPageTitle, setSessionData, sessionData, setUserData, userData } = useStore();
  const { trackPage, trackSegmentEvent, trackIdentify } = useSegment();
  const [patchUser, { loading }] = useMutation(PATCH_APPLICATION_LEAD_USER);

  const {
    holdInformationRequestedActive,
    checkUpdatePromptReasonStatusNeeded,
    createUpdatePromptReasonHandler,
    checkPromptReasonListHasReason,
    updateApplicationLeadInfo,
    handleNavigateForward,
    loading: updatePromptReasonLoading,
  } = useApplicationLeadPromptReason();

  const [displayOwnerInfoModal, setDisplayOwnerInfoModal] = useState(false);
  const [userRole, setUserRole] = useState(sessionData?.role);
  const [isReasonPromptVisible, setIsReasonPromptVisible] = useState(false);
  const [isOwnerInfoReasonPromptVisible, setIsOwnerInfoReasonPromptVisible] = useState(false);
  const [isOwnerDobInputVisible, setIsOwnerDobInputVisible] = useState(false);
  const [updateOwnerDobLoading, setUpdateOwnerDobLoading] = useState(false);

  const userDetail = StorageService.getUserData();

  useEffect(() => {
    const isOwnerReasonInPromptList =
      holdInformationRequestedActive && checkPromptReasonListHasReason(ApplicationLeadPromptReasons.Owner);

    if (isOwnerReasonInPromptList) {
      setIsOwnerInfoReasonPromptVisible(true);
    }
  }, [holdInformationRequestedActive]);

  useEffect(() => {
    if (sessionData?.role) {
      setUserRole(sessionData?.role);
    }

    if (sessionData?.role === USER_ROLE.OWNER) {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_PAGE_LOAD);
    } else {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_PAGE_LOAD);
    }
  }, []);

  const openOwnerInfoModal = () => {
    setDisplayOwnerInfoModal(true);
  };
  const closeOwnerInfoModal = () => {
    setDisplayOwnerInfoModal(false);
  };

  const { handleSubmit, control, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: userData || {},
  });
  const { isValid } = formState;

  const isOwner = userRole === USER_ROLE.OWNER;

  useEffect(() => {
    if (sessionData.id || sessionData.firstName) {
      const { firstName, lastName } = userData;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
    }
  }, [sessionData]);

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle(isOwner ? 'Owner Info' : 'Your Info');
    trackPage('pocInfo');
  }, [setPageTitle]);

  const handleNextStep = () => {
    if (isOwner) {
      navigate('/business-info');
    } else {
      navigate('/owner-info');
    }
  };

  const isInformationUpdated = (data) => {
    const { firstName, lastName, phone, ownerDob } = data;
    const { pocFirstName, pocLastName, pocPhone, ownerDob: pocOwnerDob } = sessionData;

    return pocFirstName !== firstName || pocLastName !== lastName || pocPhone !== phone || ownerDob !== pocOwnerDob;
  };

  const onSubmit = async (data: any) => {
    const { token } = StorageService.getAuthData();

    if (!token) {
      alert.info('Please log in to continue.');
      StorageService.clearUserData();
      navigate('/login');
      return;
    }

    trackSegmentEvent(
      isOwner
        ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_CONTINUE_CLICKED
        : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_CONTINUE_CLICKED,
    );

    // Step 1: Check if the prompt reason status needs to be updated and update reason as UPDATED or SKIPPED
    if (checkUpdatePromptReasonStatusNeeded()) {
      await createUpdatePromptReasonHandler(isInformationUpdated, ApplicationLeadPromptReasons.Owner)(data);
    }

    const { user, ...rest } = data;
    const patchUserParams = { ...rest, tempPhone: data.phone };
    // Step 2: Delete ownerDob from patchUserParams because patchUser mutation does not accept ownerDob
    delete patchUserParams.ownerDob;

    // Step 3: Update ownerDob in application lead if it is present in the form data
    if (data?.ownerDob) {
      setUpdateOwnerDobLoading(true);
      await updateApplicationLeadInfo({ ownerDob: data.ownerDob });
      setSessionData({ ownerDob: data.ownerDob });
      setUpdateOwnerDobLoading(false);
    }

    // Step 4: Update user
    const {
      data: { patchApplicationLeadUser },
    } = await patchUser({ variables: { input: patchUserParams } });
    if (patchApplicationLeadUser.id) {
      setUserData({
        ...data,
        user: patchApplicationLeadUser,
      });
      setSessionData({
        pocFirstName: data.firstName,
        pocLastName: data.lastName,
        firstName: data.firstName,
        lastName: data.lastName,
        pocPhone: data.phone,
      });

      if (patchApplicationLeadUser?.user?.phone === data.phone) {
        handleNextStep();
        trackIdentify({
          id: userDetail?.user?.id || '',
          firstName: data.firstName,
          lastName: data.lastName,
          email: userDetail?.user?.email || '',
          phone: data.phone,
        });
      } else {
        await handleNavigateForward(ApplicationLeadPromptReasons.Owner);
      }

      trackSegmentEvent(SegmentEventNames.BUSINESS_OWNER_PHONE_CONTINUE_CLICKED);
    } else {
      if (patchApplicationLeadUser.code === 'phone.number.voip') {
        alert.info('Cherry does not accept VOIP numbers, please try entering a different number');
      } else {
        alert.info(patchApplicationLeadUser.message);
      }
    }
  };

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event?.target || {};

    if (name === 'firstName') {
      trackSegmentEvent(
        isOwner
          ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_CLICKED
          : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_FIRST_NAME_CLICKED,
      );
    } else if (name === 'lastName') {
      trackSegmentEvent(
        isOwner
          ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_LAST_NAME_CLICKED
          : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_LAST_NAME_CLICKED,
      );
    } else if (name === 'phone') {
      trackSegmentEvent(
        isOwner
          ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_PHONE_CLICKED
          : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_PHONE_CLICKED,
      );
    }
  };

  const openReasonPrompt = () => {
    setIsReasonPromptVisible(true);
  };

  const closeReasonPrompt = () => {
    setIsReasonPromptVisible(false);
  };

  const onEditInfo = () => {
    setIsOwnerDobInputVisible(true);
    setIsOwnerInfoReasonPromptVisible(false);
  };

  return (
    <Container backUrl="/user-role">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'user_circle'} />
          {holdInformationRequestedActive && isOwner ? (
            <>
              <Title m={'10px 0px 24px 0px'}>Confirm Owner’s Information</Title>
              <Subtitle m={'0 0 24px 0'}>
                Please confirm the following information for the <b>business owner</b>.
              </Subtitle>
            </>
          ) : (
            <>
              <Title m={'10px 0px 24px 0px'}>
                {isOwner ? 'Tell us about the business owner' : 'Tell us about you'}
              </Title>
              {isOwner && (
                <Subtitle m={'0 0 24px 0'}>
                  For account security and fraud prevention, please list the owner’s information as follows.
                </Subtitle>
              )}
            </>
          )}

          <Box>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="firstName"
                  data-testid="firstName"
                  label={`${isOwner ? "Owner's" : 'Your'} First Name`}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                  onBlur={() => {
                    trackSegmentEvent(
                      isOwner
                        ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_FILLED
                        : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_FIRST_NAME_FILLED,
                      {
                        value,
                      },
                    );
                  }}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="lastName"
                  data-testid="lastName"
                  label={`${isOwner ? "Owner's" : 'Your'} Last Name`}
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(
                      isOwner
                        ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_LAST_NAME_FILLED
                        : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_LAST_NAME_FILLED,
                      {
                        value,
                      },
                    );
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                />
              )}
            />
            {isOwner && (
              <Row>
                <Icon src={'info_circle_green'} width={24} height={24} />
                <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openOwnerInfoModal}>
                  What name should I use?
                </UnderlinedSubtitle>
              </Row>
            )}
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: phonePattern }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="phone"
                  data-testid="phone"
                  type="tel"
                  label={`${isOwner ? "Owner's" : 'Your'} Cell Phone Number`}
                  InputProps={{
                    inputComponent: PhoneInput as any,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(
                      isOwner
                        ? SegmentEventNames.PRACTICE_REGISTRATION_OWNER_INFO_PHONE_FILLED
                        : SegmentEventNames.PRACTICE_REGISTRATION_PERSONAL_INFO_PHONE_FILLED,
                      {
                        value,
                      },
                    );
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                />
              )}
            />
            <Row>
              <Icon src={'info_circle_green'} width={24} height={24} />
              <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openReasonPrompt}>
                What phone number should I use?
              </UnderlinedSubtitle>
            </Row>
            {isOwnerDobInputVisible && <OwnerDobInput control={control} />}
          </Box>
        </InnerContainer>
        <Gap size="15px" />
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            {isOwner ? (
              <>
                You must provide the <b>owner’s personal cell phone number, not an office line.</b> This is required to
                approve your practice. This number will never be shared with patients.
              </>
            ) : (
              <>
                This should be <b>your personal cell phone number, not an office line.</b> It will be used to verify
                your identity and to contact you about your Cherry account. This number will <b>not</b> be shown to
                patients.{' '}
              </>
            )}
          </InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading || updateOwnerDobLoading}
          loading={loading || updateOwnerDobLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </ButtonContainer>
      <OwnerNameInfoModal hideModal={closeOwnerInfoModal} show={displayOwnerInfoModal} />
      <OwnerInfoReasonPrompt show={isReasonPromptVisible} hideModal={closeReasonPrompt} />
      <ApplicationLeadReasonPrompt
        type={ApplicationLeadPromptReasons.Owner}
        show={!!isOwnerInfoReasonPromptVisible}
        onEditInfo={onEditInfo}
        loading={updatePromptReasonLoading}
      />
    </Container>
  );
};

export default PersonalInfo;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
