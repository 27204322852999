import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { emailPattern } from 'lib/utils/Validators';

import { REGISTER } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';

interface State {
  email: string;
  password: string;
}

const Register = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const { setPageTitle } = useStore();
  const { trackPage, trackSegmentEvent, trackIdentify } = useSegment();

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const passwordControl = useRef({});

  const [register, { loading }] = useMutation(REGISTER);

  const { handleSubmit, control, watch, formState } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;
  passwordControl.current = watch('password', '');

  useEffect(() => {
    setPageTitle('Create New Account');
    trackPage('register');
    trackIdentify();
  }, [setPageTitle]);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const showRePasswordHandler = () => {
    setShowRePassword(!showRePassword);
  };

  const emailInUseText = `
    An application has already been started or submitted using this email. To continue your application, please log-in. You can request a password reset
    <a style="color: black;" href=/forgot-password>
      here
    </a>
  `;

  const onSubmit = async (data: State) => {
    trackSegmentEvent(SegmentEventNames.REGISTER_CREATE_AND_APPLY_CLICKED);
    try {
      const { email, password } = data;
      const res = await register({ variables: { input: { email, password, scope: 'ONBOARDING' } } });
      if (res.data?.createApplicationLeadUser?.success) {
        const userData = res.data.createApplicationLeadUser;
        const { user } = userData;

        trackIdentify({ id: user?.id, firstName: user?.firstName, lastName: user?.lastName, email: user?.email });
        StorageService.setUserData(userData);
        StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
        navigate('/preparation-list');
      } else if (res.data?.createApplicationLeadUser?.code === 'email.in.use') {
        alert.info(emailInUseText);
      } else {
        alert.info(res.data.createApplicationLeadUser?.message);
      }
    } catch (err) {
      alert.info('An error occurred please try again');
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Icon width={35} height={35} src={'user_plus'} />
        <Title m={'13px 0px 24px 0px'}>Create an account and start a new application</Title>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: emailPattern }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                label="Email"
                type="email"
                data-testid="register-email"
                value={value}
                onChange={(event) => {
                  onChange(event?.target?.value?.toLowerCase());
                }}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },

              validate: (value) =>
                [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) => pattern.test(value)) ||
                'Password does not meet minimum requirements',
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                label="Password"
                autoComplete="new-password"
                data-testid="password"
                value={value}
                type={showPassword ? 'email' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={showPasswordHandler}>
                      {showPassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
          <Controller
            name="repeatPassword"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => value === passwordControl.current || 'The passwords do not match',
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                autoComplete="off"
                label="Re-enter Password"
                value={value}
                type={showRePassword ? 'email' : 'password'}
                data-testid="rePassword"
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={showRePasswordHandler}>
                      {showRePassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Box>
        <ValidateContainer>
          <ValidateTitle>Passwords must contain:</ValidateTitle>
          <ValidateTextContainer>
            <ValidateText>8 characters minimum</ValidateText>
            <ValidateText>at least 1 number</ValidateText>
            <ValidateText>at least 1 uppercase and 1 lowercase letter</ValidateText>
          </ValidateTextContainer>
        </ValidateContainer>
      </ContentContainer>
      <ButtonContainer>
        {/*// @ts-ignore*/}
        <Button disabled={!isValid || loading} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Create Account and Apply
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Register;

const ValidateContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const ValidateTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
`;

const ValidateTextContainer = styled.ul`
  margin: 0px;
  padding: 0px 30px;
`;

const ValidateText = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
`;
