import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Button, Icon, Subtitle, Title } from 'lib/components';

export const BusinessPhotoInfoModal = ({ show, hideModal, handleZoomWalkthrough }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContent>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>Visualogyx Help</Title>
            <Icon src={'close'} onClick={hideModal} />
          </ModalHeader>
          <Subtitle>
            If you’re having trouble with Visualogyx, you can schedule a 15 minute zoom walkthrough with one of our
            underwriters instead.
          </Subtitle>

          <br />
          <Gap size={'24px'} />
          <Button onClick={handleZoomWalkthrough}>Schedule Zoom Walkthrough</Button>
          <BackButton onClick={hideModal}>Back</BackButton>
        </ModalContent>
      </Slide>
    </Backdrop>
  );
};

const ModalContent = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 30px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 280px;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.main.white};
  border: 1px solid #56636d;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;
