export const US_STATES = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Delaware', value: 'DE' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];

export const INDUSTRIES = [
  'Audiology',
  'Chiropractic',
  'Cosmetic Surgery',
  'Cryotherapy',
  'Day Spa',
  'Dermatology',
  'General Dentistry',
  'Hair Restoration',
  'Holistic/Regenerative Medicine',
  'Medical Weight Loss',
  'Medspa',
  'Optometry',
  'Orthodontics',
  'Oral Surgery',
  'Periodontics',
  'Permanent Makeup',
  'Plastic Surgery',
  'Primary Care',
  'Salon',
  'Scalp Micropigmentation',
  'Sexual Health',
  'Tattoo Removal',
  'Vein Specialist',
  'Veterinary',
];

export const MONTH_60_TERM_INDUSTRIES = [
  'Cosmetic Surgery',
  'General Dentistry',
  'Oral Surgery',
  'Orthodontics',
  'Periodontics',
  'Plastic Surgery',
];

export const DENTAL_INDUSTRIES = [
  'General Dentistry',
  'Dental',
  'Dental & Orthodontics',
  'Oral Surgery',
  'Orthodontics',
  'Periodontics',
];

export const INSTALLMENT_PERIOD = [
  {
    installmentDuration: 3,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: false, fee: '4.5' },
      { duration: 12, included: false, fee: '4.5' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
    ],
  },
  {
    installmentDuration: 6,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: false, fee: '4.5' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
    ],
  },
  {
    installmentDuration: 12,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
    ],
  },
  {
    installmentDuration: 18,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: false, fee: '4.5' },
    ],
  },
  {
    installmentDuration: 24,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: true, fee: '13.9' },
    ],
  },
];

export const DENTAL_INSTALLMENT_PERIOD = [
  {
    installmentDuration: 3,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: false, fee: '4.5' },
      { duration: 12, included: false, fee: '4.5' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 6,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: false, fee: '4.5' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 12,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: false, fee: '4.5' },
      { duration: 24, included: false, fee: '4.5' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 18,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: false, fee: '4.5' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 24,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: true, fee: '13.9' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 36,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: true, fee: '13.9' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 48,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: true, fee: '13.9' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
  {
    installmentDuration: 60,
    rates: [
      { duration: 3, included: true, fee: '3.5' },
      { duration: 6, included: true, fee: '4.9' },
      { duration: 12, included: true, fee: '8.9' },
      { duration: 18, included: true, fee: '12.5' },
      { duration: 24, included: true, fee: '13.9' },
      { duration: 36, included: false, fee: '4.5' },
      { duration: 48, included: false, fee: '4.9' },
      { duration: 60, included: false, fee: '4.9' },
    ],
  },
];

export const PAGE_COUNT = 14;

export const MARKETING_CHANNELS = [
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Google', value: 'Google' },
  { label: 'Email Marketing', value: 'Email Marketing' },
  { label: 'Yelp', value: 'Yelp' },
  { label: 'Physical Mail', value: 'Physical Mail' },
  { label: 'Referrals', value: 'Referrals' },
  { label: 'Other', value: 'Other' },
];

export const RETAIL_STATES = ['AK', 'AZ', 'CA', 'ID', 'KY', 'MT', 'NH', 'NM', 'NV', 'UT', 'WA', 'WI'];

export const PRACTICE_ONBOARDING = 'practiceOnboarding';

export const confettiColors: string[] = ['#00bb71', '#1D291D', '#C5F1E1', '#B1BC74', '#00bb71'];

export enum ApplicationLeadPromptReasons {
  BankAccountType = 'BANK_ACCOUNT_TYPE',
  BankAccountDetails = 'BANK_ACCOUNT_DETAILS',
  Owner = 'OWNER_INFO',
  Npi = 'NPI_INFO',
  Address = 'ADDRESS_INFO',
}

export enum ApplicationLeadPromptStatus {
  Prompted = 'PROMPTED',
  Updated = 'UPDATED',
  Skipped = 'SKIPPED',
}

export const ApplicationLeadPromptReasonsNavigationMap = {
  [ApplicationLeadPromptReasons.BankAccountType]: {
    routeName: '/banking-info',
    nextRouteName: '/review',
    routeOrder: 4,
  },
  [ApplicationLeadPromptReasons.BankAccountDetails]: {
    routeName: '/banking-info',
    nextRouteName: '/review',
    routeOrder: 4,
  },
  [ApplicationLeadPromptReasons.Owner]: {
    routeName: '/personal-info',
    nextRouteName: '/otp',
    routeOrder: 1,
  },
  [ApplicationLeadPromptReasons.Npi]: {
    routeName: '/financial-services',
    nextRouteName: '/banking-info',
    routeOrder: 3,
  },
  [ApplicationLeadPromptReasons.Address]: {
    routeName: '/business-address',
    nextRouteName: '/financial-services',
    routeOrder: 2,
  },
};

export type ApplicationLeadPromptReason = {
  id: number;
  applicationLeadId: number;
  reason: ApplicationLeadPromptReasons;
  status: ApplicationLeadPromptStatus;
};

export type InitiateApplicationLeadResponse = {
  applicationLeadPromptReasons: Array<ApplicationLeadPromptReason>;
};

export type GetApplicationLeadPromptReasonsResponse = {
  applicationLeadPromptReasons: Array<ApplicationLeadPromptReason>;
};
