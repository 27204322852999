import { useApolloClient } from '@apollo/client';
import { GET_APPLICATION_LEAD_DETAIL, GET_APPLICATION_TRAINING_LINK } from 'lib/graphql/queries';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useApplicationLeadPolling = () => {
  const client = useApolloClient();
  const countRef = useRef(10);
  const navigate = useNavigate();
  const { setTrainingLink } = useStore();
  const [pollingLoading, setPollingLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const pollApplicationLeadStatus = async (applicationLeadId: string | undefined) => {
    if (timeoutRef.current === null) {
      timeoutRef.current = setTimeout(() => {
        countRef.current = 0;
      }, 20000);
    }

    if (countRef.current > 0) {
      countRef.current = countRef.current - 1;

      try {
        if (!applicationLeadId) {
          throw new Error('Application Lead Id Not Found');
        }

        const {
          data: { getApplicationLeadDetail },
        } = await client.query({
          query: GET_APPLICATION_LEAD_DETAIL,
          variables: {
            input: {
              applicationLeadId,
            },
          },
        });

        if (getApplicationLeadDetail.status === 'DENIED') {
          navigate('/application-declined');
          clearTimeout(timeoutRef.current!);
        } else if (getApplicationLeadDetail.status === 'APPROVED') {
          const { salesforceAccountId } = StorageService.getSfInfo();

          if (salesforceAccountId) {
            const {
              data: { fetchTrainingLink },
            } = await client.query({
              query: GET_APPLICATION_TRAINING_LINK,
              variables: {
                input: {
                  salesforceId: salesforceAccountId,
                },
              },
            });

            if (!fetchTrainingLink?.error && fetchTrainingLink?.onboardingRegistrationLink) {
              setTrainingLink(fetchTrainingLink.onboardingRegistrationLink);
              clearTimeout(timeoutRef.current!);
            }
          }

          navigate('/application-approved');
          clearTimeout(timeoutRef.current!);
        } else {
          setTimeout(() => pollApplicationLeadStatus(applicationLeadId), 3000);
        }
      } catch (e) {
        navigate('/result');
        clearTimeout(timeoutRef.current!);
      }
    } else {
      navigate('/result');
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { pollApplicationLeadStatus, pollingLoading, setPollingLoading };
};
