import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, PhoneInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { INDUSTRIES } from 'lib/constants';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { phonePattern, websitePattern } from 'lib/utils/Validators';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  website: string;
}

const BusinessAdditioanlInfo = () => {
  const navigate = useNavigate();
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_PAGE_LOAD);
  }, []);

  useEffect(() => {
    setPageIndex(5);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Business Info');
    trackPage('businessAdditionalInfo');
  }, [setPageTitle]);

  const onSubmit = (data: State) => {
    setSessionData(data);
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_CONTINUE_CLICKED);
    navigate('/business-address');
  };

  const onIndustryFieldChanged = (e, onChange) => {
    onChange(e);
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_INDUSTRY_SELECTED, {
      value: e?.target?.value,
    });
  };

  const onIndustrySelectionDropdownClick = () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_INDUSTRY_CLICKED);
  };

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event?.target || {};

    if (name === 'phone') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_PHONE_CLICKED);
    } else if (name === 'website') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_WEBSITE_CLICKED);
    }
  };

  return (
    <Container backUrl="/business-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'business-store'} />
          <Title m={'10px 0px 8px 0px'}>{`Tell us more about ${sessionData.name}`}</Title>
          <Subtitle m={'10px 0px 24px 0px'}>
            A website is required. Social media business pages are acceptable.
          </Subtitle>
          <Box>
            <Controller
              name="industry"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  style={{ textAlign: 'left' }}
                  label="Industry"
                  id="filled-basic"
                  variant="filled"
                  data-testid="industry"
                  select={true}
                  value={value}
                  onChange={(e) => onIndustryFieldChanged(e, onChange)}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                  SelectProps={{
                    onOpen: onIndustrySelectionDropdownClick,
                  }}
                >
                  {INDUSTRIES.map((option: string, index: number) => (
                    <MenuItem data-testid="industryItem" key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: phonePattern }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="phone"
                  data-testid="business-phone"
                  type="tel"
                  label="Business Phone Number"
                  InputProps={{
                    inputComponent: PhoneInput as any,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_PHONE_ENTERED, {
                      value,
                    });
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                />
              )}
            />
            <Controller
              name="website"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: websitePattern, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="website"
                  variant="filled"
                  name="website"
                  data-testid="website"
                  label="Business Website"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(
                      SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_WEBSITE_ENTERED,
                      {
                        value,
                      },
                    );
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error?.type === 'maxLength' ? 'Max length exceed' : null}
                  onFocus={onInputFocus}
                />
              )}
            />
          </Box>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            The business phone number should be your <b>main business line</b>. Patients use this number for
            appointments and general questions.
          </InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default BusinessAdditioanlInfo;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
