import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const Footer = () => {
  return (
    <Container>
      <Typography variant="subtitle2">© {new Date().getFullYear()} Cherry LLC.</Typography>
    </Container>
  );
};

const Container = styled.div`
  flex-grow: 0;
  border-top: solid 1px lightgray;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
`;
