import { PhotoUploadForm } from 'lib/components';

const AddMedicalLicense = () => {
  return (
    <PhotoUploadForm
      title="Medical License"
      subtitle="Due to the nature of services your business provides, Cherry requires there to be a fully licensed doctor on staff or acting as the Medical Director. Please upload the doctor’s medical license. Note: While we understand some states do not require a medical license to practice, Cherry has chosen to set this requirement as part of internal protections."
      reason="R_MedicalLicense"
      exampleImgSrc="examples/medical-license.png"
      icon="medical-license"
    />
  );
};

export default AddMedicalLicense;
