import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Button, Icon, Title } from 'lib/components';

interface RemoveProps {
  show: boolean;
  hideModal: () => void;
  removeImage: () => void;
}

export const RemoveModal = ({ show, hideModal, removeImage }: RemoveProps) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>Remove File?</Title>
            <Icon src={'close'} onClick={hideModal} />
          </ModalHeader>
          <RemoveButton data-testid="removeButton" onClick={removeImage}>
            <Icon src={'trash_full'} m={'0px 10px'} />
            Yes, Remove
          </RemoveButton>
          <Button secondary={true} onClick={hideModal}>
            Cancel
          </Button>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export const ModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: #ffffff;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 48px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 200px;
    border-radius: 6px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RemoveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ec3360;
  box-sizing: border-box;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  color: #ec3360;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
`;

export const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;
