import { DENTAL_INSTALLMENT_PERIOD, INSTALLMENT_PERIOD, MONTH_60_TERM_INDUSTRIES } from 'lib/constants';
import { Rates } from 'lib/types';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { Icon } from 'lib/components';
import useStore from 'lib/hooks/useStore';

interface Props {
  selectedInstallment: number;
  showExtraInformation: boolean;
}

export const PricingInformationTable = ({ selectedInstallment, showExtraInformation = false }: Props) => {
  const { sessionData } = useStore();

  const isDental = MONTH_60_TERM_INDUSTRIES.includes(sessionData.industry);

  const findChosenInfo = () => {
    const period = isDental ? DENTAL_INSTALLMENT_PERIOD : INSTALLMENT_PERIOD;
    if (selectedInstallment > 0) {
      return period.find((ip) => ip.installmentDuration === selectedInstallment);
    }

    return { installmentDuration: 0, rates: [] };
  };

  return (
    <Container>
      <HeadlineContainer>Prime Credit Patients</HeadlineContainer>

      <SelectedContainer>
        <Header />

        <div className="body">
          {findChosenInfo()?.rates.map((node: Rates, index: number) => (
            <div key={index} className="rates">
              <span className="month">
                <span className="duration">{node.duration}</span>
              </span>

              {node.included ? <Promo /> : <NonPromo />}
              <span className="ratio"> {node.fee}% </span>
            </div>
          ))}
        </div>
      </SelectedContainer>

      {showExtraInformation && (
        <HeadlineContainer>
          <span>Subprime Credit Patients</span>
        </HeadlineContainer>
      )}

      {showExtraInformation && (
        <SelectedContainer>
          <Header />
          <div className="body">
            <div className="rates">
              <span className="month"> All</span>

              <NonPromo />
              <span className="ratio col-4"> 9.9% </span>
            </div>
          </div>
        </SelectedContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border: 1px #56636d solid;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const HeadlineContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-self: stretch;
  background: #0e202f;
  color: ${theme.main.white};
  padding: 3px 0px;
  border-radius: 8px 8px 0px 0px;
`;

const RatesContainer = styled.div`
  display: flex;
  padding: 4px 6px 5px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: ${theme.main.green10};
`;

const RatesText = styled.span`
  color: ${theme.main.green};
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.02px;
  text-transform: uppercase;
`;

const SelectedContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: ${theme.main.gray5};
    color: ${theme.main.midnightBlue};
    > span {
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      padding: 6px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .body {
    .rates {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      line-height: 1.5;
      > span {
        flex: 1;
        padding: 8px;
      }
    }
  }
`;

const NonPromoText = styled.div`
  color: ${theme.main.midnightBlue50};
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.02px;
  text-transform: uppercase;
`;

const NonPromo = () => <NonPromoText>NON-PROMO</NonPromoText>;

const Promo = () => (
  <RatesContainer>
    <Icon width={20} height={20} src="circle_check" />
    <RatesText>PROMO</RatesText>
  </RatesContainer>
);

const Header = () => (
  <div className="header">
    <span>Months</span>
    <span>Patient APR</span>
    <span>Merchant Fee</span>
  </div>
);
