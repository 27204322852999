import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { COMPLETE_APPLICATION_LEAD } from 'lib/graphql/mutations';
import { useState } from 'react';

type CompleteApplicationLeadResponse = {
  success: boolean;
};

export const useCompleteApplicationLead = (applicationLeadId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CompleteApplicationLeadResponse | null>(null);

  const [completeApplicationLead] = useMutation(COMPLETE_APPLICATION_LEAD, {
    variables: {
      input: {
        applicationLeadId,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data?.completeApplicationLead);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException('useCompleteApplicationLead', {
        level: 'error',
        extra: {
          errorMessage: 'COMPLETE_APPLICATION_LEAD ERROR',
          page: 'useCompleteApplicationLead',
          error,
        },
      });
    },
  });

  return { completeApplicationLead, data, loading, setLoading };
};
