import { ApplicationLeadPromptReasons } from 'lib/constants';

type ReasonPromptContent = {
  title: string;
  subtitle: string;
  infoText?: string;
  confirmButtonText?: string;
  editButtonText: string;
};

export const REASON_PROMPT_CONTENT = {
  [ApplicationLeadPromptReasons.Owner]: {
    title: 'Provide Date of Birth',
    subtitle:
      'Please provide the owner’s date of birth. It will be used to verify their identity and help us process the application faster.',
    editButtonText: 'Add Owner’s Date of Birth',
  },
  [ApplicationLeadPromptReasons.Address]: {
    title: 'Are you sure?',
    subtitle: 'There was an issue verifying your address, please confirm the address below:',
    infoText: 'This address should be a physical location where your patients receive services.',
    confirmButtonText: 'Skip, this is the correct address',
    editButtonText: 'Edit Address Info',
  },
  [ApplicationLeadPromptReasons.BankAccountDetails]: {
    title: 'Are you sure?',
    subtitle: 'There was an issue verifying your banking information. Please confirm the details below are accurate:',
    confirmButtonText: 'Skip, this is the correct banking info',
    editButtonText: 'Edit Banking Info',
  },
  [ApplicationLeadPromptReasons.BankAccountType]: {
    title: 'Are you sure?',
    subtitle: 'You’ve connected a Savings Account. Are you sure you want to connect this account?',
    confirmButtonText: 'Skip, this is the correct banking info',
    editButtonText: 'Edit Banking Info',
  },
  [ApplicationLeadPromptReasons.Npi]: {
    title: 'NPI not provided',
    subtitle:
      'Please provide your National Provider Identifier (NPI). This will help us to process your application faster.',
    confirmButtonText: 'Skip, I don’t know my NPI',
    editButtonText: 'Add NPI',
  },
} as Record<ApplicationLeadPromptReasons, ReasonPromptContent>;
