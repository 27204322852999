export const FormatPhoneNumber = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const FormatDate = (str) => {
  if (str) {
    const splittedDate = str.split('-');
    return `${splittedDate[1]}/${splittedDate[2]}/${splittedDate[0]}`;
  }
  return str;
};
