import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import styled from 'styled-components';

import { APP_ENV } from 'config';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';

import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { UserRoleConfirmationModal } from 'pages/UserRole/UserRoleConfirmationModal';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';

export enum USER_ROLE {
  OWNER = 'OWNER',
  LOCATION_MANAGER = 'LOCATION_MANAGER',
  OTHER_AUTHORIZED_REPRESENTATIVE = 'OTHER_AUTHORIZED_REPRESENTATIVE',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export enum USER_ROLE_TITLES {
  OWNER = 'Owner',
  LOCATION_MANAGER = 'Location Manager',
  OTHER_AUTHORIZED_REPRESENTATIVE = 'Other Authorized Representative',
  STAFF_MEMBER = 'Staff Member',
}

const USER_ROLE_OPTIONS = [
  { key: USER_ROLE.OWNER, value: USER_ROLE_TITLES.OWNER },
  { key: USER_ROLE.LOCATION_MANAGER, value: USER_ROLE_TITLES.LOCATION_MANAGER },
  { key: USER_ROLE.OTHER_AUTHORIZED_REPRESENTATIVE, value: USER_ROLE_TITLES.OTHER_AUTHORIZED_REPRESENTATIVE },
  { key: USER_ROLE.STAFF_MEMBER, value: USER_ROLE_TITLES.STAFF_MEMBER },
];

const UserRole = () => {
  const navigate = useNavigate();
  const { setPageIndex, setPageTitle, setSessionData, sessionData } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { flags } = useFlags();

  const [selectedRole, setSelectedRole] = useState(sessionData?.role);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const shareUrl = APP_ENV === 'master' ? 'register.withcherry.com' : `${APP_ENV}-register.withcherry.com`;

  const { handleSubmit, control, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: sessionData || {},
  });
  const { isValid } = formState;

  useEffect(() => {
    console.log(`FEATURES.migrationTestFlag`, flags.FEATURES.migrationTestFlag.isEnabled());
    console.log(`FEATURES.testFlag`, flags.FEATURES.testFlag.isEnabled());
    console.log(`notNestedTestFlag`, flags.notNestedTestFlag.isEnabled());
  }, [flags]);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_YOUR_ROLE_PAGE_LOAD);
  }, []);

  useEffect(() => {
    if (sessionData.id) {
      const { role } = sessionData;
      setValue('role', role);
    }
  }, [sessionData]);

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Your Info');
    trackPage('userRole');
  }, [setPageTitle]);

  const onSubmit = async (data: any) => {
    const { role } = data;
    setSessionData({ role });
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_YOUR_ROLE_CONTINUE_CLICKED);
    if (role === USER_ROLE.OWNER) {
      setSessionData({ ownerUserId: '' });

      navigate('/personal-info');
    } else if (role === USER_ROLE.OTHER_AUTHORIZED_REPRESENTATIVE || role === USER_ROLE.LOCATION_MANAGER) {
      setDisplayConfirmationModal(true);
    }
  };

  const handleConfirmationModalSubmit = () => {
    setDisplayConfirmationModal(false);

    navigate('/personal-info');
  };

  const onShareClicked = () => {
    console.log('shared successfully!');
  };

  const getInfoTextForRepresentatives = () => {
    let infoText = <></>;

    if (selectedRole && selectedRole !== USER_ROLE.OWNER) {
      infoText = (
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>
            Only select this role if you are <b>not</b> also a business owner.
          </InfoText>
        </InfoContainer>
      );
    }

    return infoText;
  };

  const onRoleSelectionDropdownClick = () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_YOUR_ROLE_ROLE_SELECTOR_CLICKED);
  };

  const isStaffMember = selectedRole === USER_ROLE.STAFF_MEMBER;

  return (
    <Container>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'user_circle'} />
          <Title m={'10px 0px 24px 0px'}>Tell us about your role</Title>
          <Box>
            <Subtitle>Who is completing this application?</Subtitle>
            <Gap size={'16px'} />
            <Controller
              name="role"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  style={{ textAlign: 'left' }}
                  label="Your Role"
                  placeholder="Account Type"
                  id="filled-basic"
                  variant="filled"
                  data-testid="role"
                  select={true}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setSelectedRole(e.target.value);
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_YOUR_ROLE_ROLE_SELECTED, {
                      value: e.target.value,
                    });
                  }}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                  SelectProps={{
                    onOpen: onRoleSelectionDropdownClick,
                  }}
                >
                  {USER_ROLE_OPTIONS.map((option: { key: string; value: string }) => (
                    <MenuItem data-testid="accountTypeOption" key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Box>
        </InnerContainer>
        {isStaffMember ? (
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              This application must be completed by a business owner, manager, or other authorized representative of the
              business. <br />
              Please use the button below to share this application with an authorized business representative.
            </InfoText>
          </InfoContainer>
        ) : (
          getInfoTextForRepresentatives()
        )}
      </ContentContainer>
      <ButtonContainer>
        {isStaffMember ? (
          <RWebShare
            data={{
              text: 'Cherry Merchant Register',
              url: shareUrl,
              title: 'Cherry - Merchant Register',
            }}
            onClick={onShareClicked}
          >
            <Button>Share This</Button>
          </RWebShare>
        ) : (
          <Button disabled={!isValid} onClick={handleSubmit(onSubmit)} icon={isStaffMember ? 'share' : ''}>
            Continue
          </Button>
        )}
      </ButtonContainer>
      <UserRoleConfirmationModal
        show={displayConfirmationModal}
        hideModal={() => setDisplayConfirmationModal(false)}
        handleContinue={handleConfirmationModalSubmit}
      />
    </Container>
  );
};

export default UserRole;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 88px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;
