import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

interface Props {
  label: string | React.ReactElement;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const CheckboxItem = ({ onChange, checked, label }: Props) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <FormControlLabel
      disableTypography={true}
      label={<StyledLabel>{label}</StyledLabel>}
      sx={{ mb: 1 }}
      control={
        <Checkbox
          data-testid="checkbox"
          onChange={onChangeHandler}
          checked={checked}
          sx={{
            '&.Mui-checked': {
              color: '#1ec37c',
            },
          }}
        />
      }
    />
  );
};

export default CheckboxItem;

const StyledLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e202f;
`;
