import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Icon, Subtitle, Title } from 'lib/components';

export const PricingTiers = ({ show, hideModal }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <TiersContainer>
          <TiersHeader>
            <IconGap />
            <Title m={'24px 0px'}>Pricing Tiers</Title>
            <Icon src={'close'} onClick={hideModal} />
          </TiersHeader>
          <Subtitle>
            New Cherry practices start at our <b>Bronze Tier</b>, with standard practice fees.
          </Subtitle>
          <Gap size={'8px'} />
          <PricingTierContainer>
            <BadgeTier>BRONZE TIER</BadgeTier>
            <PricingTierTextContainer>
              <BadgeTextAmount>$0 - $15k</BadgeTextAmount>
              <BadgeTextInfo>Cherry sales per month</BadgeTextInfo>
              <BadgeBorder />
              <BadgeTextFee>standard fees</BadgeTextFee>
            </PricingTierTextContainer>
            <Icon width={30} height={30} src={'circle_check'} />
          </PricingTierContainer>
          <Gap size={'24px'} />
          <Subtitle>
            Unlock <b>Silver Tier</b> or <b>Gold Tier</b> pricing discounts to lower your practice fees by meeting
            monthly transaction volume goals.
          </Subtitle>
          <Gap size={'8px'} />
          <PricingTierContainer locked={true}>
            <BadgeTierSilver>SILVER TIER</BadgeTierSilver>
            <PricingTierTextContainer>
              <BadgeTextAmount>$15K - $30k </BadgeTextAmount>
              <BadgeTextInfo>Cherry sales per month</BadgeTextInfo>
              <BadgeBorder />
              <BadgeTextFee>1.17% Discount</BadgeTextFee>
            </PricingTierTextContainer>
            <Icon width={30} height={30} src={'lock-secure'} />
          </PricingTierContainer>
          <Gap size={'8px'} />
          <PricingTierContainer locked={true}>
            <BadgeTierGold>GOLD TIER</BadgeTierGold>
            <PricingTierTextContainer>
              <BadgeTextAmount>over $30k</BadgeTextAmount>
              <BadgeTextInfo>Cherry sales per month</BadgeTextInfo>
              <BadgeBorder />
              <BadgeTextFee>1.86% Discount</BadgeTextFee>
            </PricingTierTextContainer>
            <Icon width={30} height={30} src={'lock-secure'} />
          </PricingTierContainer>
          <Gap size={'24px'} />
          <BackButton onClick={hideModal}>Back</BackButton>
        </TiersContainer>
      </Slide>
    </Backdrop>
  );
};

const TiersContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 30px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 559px;
    border-radius: 6px;
  }
`;

const TiersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.main.white};
  border: 1px solid #56636d;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

export const PricingTierContainer = styled.div<{ locked?: boolean }>`
  display: flex;
  padding: 8px;
  align-items: center;
  align-self: stretch;
  justify-content: space-evenly;

  border-radius: 8px;
  border: ${(props) => (props.locked ? '1px solid #CFD2D5' : `1px solid ${props.theme.main.green}`)};

  background: ${(props) => (props.locked ? props.theme.main.gray5 : props.theme.main.green5)};
`;

export const BadgeTier = styled.div`
  width: 115.578px;
  height: 45.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: conic-gradient(
    from 84.98deg at 50% 102.6%,
    #a37c6c -32.03deg,
    #ac836e 219.29deg,
    #f1ccb4 261.72deg,
    #f7d9c6 272.2deg,
    #a37c6c 327.97deg,
    #ac836e 579.29deg
  );
  box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: ${(props) => props.theme.main.textColor};
`;

const BadgeTierGold = styled(BadgeTier)`
  background: conic-gradient(
    from 84.98deg at 50% 102.6%,
    #cd8b00 -32.03deg,
    #c48500 0.37deg,
    #eab829 222.2deg,
    #fce469 262.5deg,
    #cd8b00 327.97deg,
    #c48500 360.37deg
  );
  box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
`;

export const PricingTierTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;

  cursor: pointer;
`;

export const BadgeTextAmount = styled.span`
  color: ${(props) => props.theme.main.textColor};
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;
export const BadgeTextInfo = styled.span`
  color: ${(props) => props.theme.main.textColor};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.52px;
  text-transform: uppercase;
`;
export const BadgeBorder = styled.span`
  margin: 4px 0;
  border-bottom: 1px solid ${(props) => props.theme.main.textColor};
`;
export const BadgeTextFee = styled.span`
  color: ${(props) => props.theme.main.textColor};

  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.02px;
  text-transform: uppercase;
`;

const BadgeTierSilver = styled(BadgeTier)`
  background: conic-gradient(
    from 84.98deg at 50% 102.6%,
    #b1c2d0 -32.03deg,
    #c8d5de 222.37deg,
    #fafafd 261.13deg,
    #f0f3f6 277.96deg,
    #b1c2d0 327.97deg,
    #c8d5de 582.37deg
  );
  box-shadow: 0px 36px 67px rgba(0, 0, 0, 0.07), 0px 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0px 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;
