import Rox from 'rox-browser';

export const Flags = {
  FEATURES: {
    migrationTestFlag: new Rox.Flag(false),
    testFlag: new Rox.Flag(false),
  },
  notNestedTestFlag: new Rox.Flag(false),
};

// Register the feature flags
(Object.keys(Flags) as (keyof typeof Flags)[])?.forEach((namespace) => {
  if (namespace === 'FEATURES') {
    Rox.register(namespace, Flags[namespace]);
  } else {
    Rox.register('', { [namespace]: Flags[namespace] });
  }
});
