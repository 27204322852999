import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore, { Requirement } from 'lib/hooks/useStore';

const ProvideMoreInfoItems = [
  {
    key: 'R_LocationPictures',
    icon: 'photo',
    activeIcon: 'photo-active',
    label: 'Location Photo(s)',
    routingUrl: '/add-business-photo',
  },
  {
    key: 'R_VoidedCheck',
    icon: 'remote-check',
    activeIcon: 'remote-check-active',
    label: 'Voided Check',
    routingUrl: '/add-void-check',
  },
  {
    key: 'R_ZoomWalkThrough',
    icon: 'zoom',
    activeIcon: 'zoom-active',
    label: 'Zoom Walkthrough',
    routingUrl: '/zoom-walkthrough',
  },
  {
    key: 'R_AddressVerification',
    icon: 'location-pin',
    activeIcon: 'location-pin-active',
    label: 'Address Verification',
    routingUrl: '/add-address-verification',
  },
  {
    key: 'R_UpdatedWebsite',
    icon: 'webpage',
    activeIcon: 'webpage-active',
    label: 'Updated Website',
    routingUrl: '/add-updated-website',
  },
  {
    key: 'R_OwnerInformation',
    icon: 'user_circle',
    activeIcon: 'user_circle-active',
    label: 'Owner Information',
    routingUrl: '/add-business-owner-info',
  },
  {
    key: 'R_BankStatements',
    icon: 'bank',
    activeIcon: 'bank-active',
    label: 'Bank Statements',
    routingUrl: '/add-bank-statements',
  },
  {
    key: 'R_BusinessRegistration',
    icon: 'business-store',
    activeIcon: 'business-store-active',
    label: 'Business Registration',
    routingUrl: '/add-business-registration',
  },
  {
    key: 'R_CreditUnlock',
    icon: 'key',
    activeIcon: 'key-active',
    label: 'Credit Unlock',
    routingUrl: '/add-credit-unlock',
  },
  {
    key: 'R_MedicalLicense',
    icon: 'medical-license',
    activeIcon: 'medical-license-active',
    label: 'Medical License',
    routingUrl: '/add-medical-license',
  },
  {
    key: 'R_DentalLicense',
    icon: 'medical-license',
    activeIcon: 'medical-license-active',
    label: 'Dental License',
    routingUrl: '/add-dental-license',
  },
  {
    key: 'R_OwnerSSN',
    icon: 'SSN',
    activeIcon: 'SSN-active',
    label: 'Owner`s Social Security Number',
    routingUrl: '/add-owner-ssn',
  },
  {
    key: 'R_OwnerEmail',
    icon: 'email',
    activeIcon: 'email-active',
    label: 'Owner`s Email',
    routingUrl: '/add-owner-email',
  },
  {
    key: 'R_ListOfServices',
    icon: 'service-list',
    activeIcon: 'service-list-active',
    label: 'List Of Services',
    routingUrl: '/add-list-of-services',
  },
  {
    key: 'R_PriceList',
    icon: 'price-list',
    activeIcon: 'price-list-active',
    label: 'Price List',
    routingUrl: '/add-price-list',
  },
  {
    key: 'R_ApplicationReview',
    icon: 'application-review',
    activeIcon: 'application-review-active',
    label: 'Application Review',
    routingUrl: '/add-application-review',
  },
  {
    key: 'R_Other',
    icon: 'more',
    activeIcon: 'more-active',
    label: 'Other',
    routingUrl: '/add-other-info',
  },
];

const ProvideMoreInfo = () => {
  const navigate = useNavigate();
  const { setPageTitle, sessionData } = useStore();

  const { requirements = [] } = sessionData;

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const notDeletedRequirements = requirements.filter((req: Requirement) => req.status !== 'DELETED');

  const buttonDisabled = notDeletedRequirements.find((req: Requirement) =>
    ['REQUESTED', 'RE_REQUESTED', 'MANUALLY_SUBMITTED'].includes(req.status),
  );

  const navigateToSubmitted = () => {
    navigate('/submitted');
  };

  const renderMoreInfoItem = (requirement) => {
    const isItemExist: Requirement | undefined = requirements.find((req: any) => req.reason === requirement.key);

    if (Boolean(isItemExist)) {
      const isItemCompleted = !['REQUESTED', 'RE_REQUESTED'].includes(isItemExist!.status);

      const navigateItemDetail = () => navigate(requirement?.routingUrl);

      return (
        <InfoContainer isCompleted={isItemCompleted}>
          <LabelContainer>
            <Icon src={!isItemCompleted ? requirement.icon : requirement.activeIcon} />
            <Label>{requirement.label}</Label>
          </LabelContainer>
          <CompleteContainer>
            {isItemCompleted ? (
              <Icon src={'check_big'} />
            ) : (
              <Button data-testid="button-add-business-photo" onClick={navigateItemDetail}>
                Complete
              </Button>
            )}
          </CompleteContainer>
        </InfoContainer>
      );
    }

    return null;
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'file_find'} />
        <Title m={'10px 0 8px 0'}>We need more information</Title>
        <Subtitle m={'0px 0px 24px 0px'}>We need to collect a bit more information before we can approve you.</Subtitle>
        <Subtitle>Please complete the following:</Subtitle>
        {ProvideMoreInfoItems?.map((req) => renderMoreInfoItem(req))}
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={buttonDisabled} type="submit" onClick={navigateToSubmitted}>
          Finish & Submit
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ProvideMoreInfo;

const InfoContainer = styled.div<{ isCompleted: any }>`
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  color: #56636d;

  ${(props) => props.isCompleted && `color: #00C37C !important;`}
`;

const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin-left: 19px;
  font: 400 16px 'Open Sans', sans-serif;
`;

const CompleteContainer = styled.div`
  display: flex;
  max-width: 88px;

  button {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 8px 16px;
  }
`;
