import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import {
  ApplicationLeadPromptReason,
  ApplicationLeadPromptReasons,
  ApplicationLeadPromptReasonsNavigationMap,
  ApplicationLeadPromptStatus,
} from 'lib/constants';
import { useUpdateApplicationLeadPromptReasonStatusCall } from './useUpdateApplicationLeadPromptReasonStatus';
import { useMutation } from '@apollo/client';
import { UPDATE_APPLICATION_LEADS } from 'lib/graphql/mutations';
import { USER_ROLE } from 'pages/UserRole/UserRole';
import { useNavigate } from 'react-router-dom';
import { useGetApplicationLeadPromptReasons } from './useGetApplicationLeadPromptReasons';
import { useState } from 'react';

const useApplicationLeadPromptReason = () => {
  const navigate = useNavigate();
  const [updateApplicationLead] = useMutation(UPDATE_APPLICATION_LEADS);
  const [updateApplicationLeadPromptReasonStatus] = useUpdateApplicationLeadPromptReasonStatusCall();
  const { sessionData, setSessionData, holdInformationRequestedActive, setHoldInformationRequestedActive } = useStore();
  const { getApplicationLeadPromptReasons } = useGetApplicationLeadPromptReasons(sessionData?.id);

  const [loading, setLoading] = useState(false);

  const { applicationLeadPromptReasons, id: applicationLeadId } = sessionData;
  const isOwner = sessionData?.role === USER_ROLE.OWNER;

  const applicationLeadPromptReasonRoutes = Object.values(ApplicationLeadPromptReasonsNavigationMap).map(
    ({ routeName }) => routeName,
  );

  const reasonList: ApplicationLeadPromptReasons[] = applicationLeadPromptReasons?.map(({ reason }) => reason);

  const setPromptReasonListInSessionData = (reasonList: ApplicationLeadPromptReason[] | undefined) => {
    setSessionData({ applicationLeadPromptReasons: reasonList || [] });
    setHoldInformationRequestedActive(true);
  };

  const clearPromptReasonListInSessionData = () => {
    setSessionData({ applicationLeadPromptReasons: [] });
    setHoldInformationRequestedActive(false);
  };

  const checkUpdatePromptReasonStatusNeeded = () => {
    const routeName = window.location.pathname;
    const reason: ApplicationLeadPromptReasons | ApplicationLeadPromptReasons[] | undefined = getReasonByRouteName(
      routeName === '/owner-info' ? '/personal-info' : routeName,
    );

    if (!holdInformationRequestedActive) return false;
    if (routeName !== '/owner-info' && !applicationLeadPromptReasonRoutes.includes(routeName)) return false;
    if (
      !reason ||
      (reason && !Array.isArray(reason) && !reasonList?.includes(reason)) ||
      (reason && Array.isArray(reason) && !reason.some((item) => reasonList.includes(item)))
    ) {
      return false;
    }

    return true;
  };

  const getBankingRelatedReasons = () => {
    return [ApplicationLeadPromptReasons.BankAccountDetails, ApplicationLeadPromptReasons.BankAccountType].filter(
      (reason) => reasonList?.includes(reason),
    );
  };

  const updatePromptReasonStatus = async (id: number, status: ApplicationLeadPromptStatus) => {
    setLoading(true);
    const response = await updateApplicationLeadPromptReasonStatus({
      variables: { input: { applicationLeadId, promptReasonId: id, status } },
    });
    setLoading(false);

    return response?.data?.updateApplicationLeadPromptReasonStatus?.success;
  };

  const getRouteNameFromPromptReasonList = (reasonList: ApplicationLeadPromptReason[]) => {
    const possibleRoutes = eliminateUpdatedOrSkippedReasons(reasonList)?.map(
      ({ reason }) => ApplicationLeadPromptReasonsNavigationMap[reason],
    );

    if (possibleRoutes?.length === 0) {
      return '/agreement-review';
    }

    const lowestOrderRoute = possibleRoutes?.reduce((lowest, current) => {
      return current.routeOrder < lowest.routeOrder ? current : lowest;
    });

    if (lowestOrderRoute.routeName === '/personal-info' && !isOwner) {
      return '/owner-info';
    }

    return lowestOrderRoute.routeName;
  };

  const getReasonByRouteName = (
    routeName: string,
  ): ApplicationLeadPromptReasons | ApplicationLeadPromptReasons[] | undefined => {
    for (const key in ApplicationLeadPromptReasonsNavigationMap) {
      if (routeName === '/banking-info') {
        return [ApplicationLeadPromptReasons.BankAccountDetails, ApplicationLeadPromptReasons.BankAccountType];
      }

      if (ApplicationLeadPromptReasonsNavigationMap[key].routeName === routeName) {
        return key as ApplicationLeadPromptReasons;
      }
    }
    return undefined;
  };

  const createUpdatePromptReasonHandler =
    (isInformationUpdatedFunction: (data) => boolean, applicationLeadPromptReason: ApplicationLeadPromptReasons) =>
    async (data, selectedStatus?: ApplicationLeadPromptStatus) => {
      const reasonToBeUpdated = (applicationLeadPromptReasons as ApplicationLeadPromptReason[]).find(
        ({ reason }) => reason === applicationLeadPromptReason,
      );

      if (reasonToBeUpdated?.id && reasonToBeUpdated.status === ApplicationLeadPromptStatus.Prompted) {
        let status: ApplicationLeadPromptStatus;
        if (selectedStatus) {
          status = selectedStatus;
        } else {
          status = isInformationUpdatedFunction(data)
            ? ApplicationLeadPromptStatus.Updated
            : ApplicationLeadPromptStatus.Skipped;
        }

        setLoading(true);
        await updatePromptReasonStatus(reasonToBeUpdated.id, status);
        setLoading(false);
      }
    };

  const checkPromptReasonListHasReason = (reason: ApplicationLeadPromptReasons) => {
    return reasonList?.some((currentReason) => currentReason === reason);
  };

  const updateApplicationLeadInfo = async (updatedFields) => {
    const { salesforceAccountId, salesforceOwnerId } = StorageService.getSfInfo();
    const {
      name,
      dba,
      industry,
      phone,
      website,
      street,
      unit,
      city,
      state,
      zip,
      role,
      ownerUserId,
      financingOptions,
      establishedAt,
      hasNoRefundPolicy,
      einNumber,
      npi,
      ownerDob,
    } = sessionData;

    const params = {
      name,
      dba,
      industry,
      phone,
      website,
      financingOptions,
      address: {
        street,
        unit,
        city,
        state,
        zip,
      },
      establishedAt,
      role,
      ownerUserId,
      marketingChannels: ['Other'],
      salesforceAccountId,
      salesforceOwnerId,
      hasNoRefundPolicy,
      ein: einNumber,
      npi,
      ownerDob,
    };

    setLoading(true);
    await updateApplicationLead({
      variables: { input: { ...params, id: sessionData?.id, ...updatedFields } },
    });
    setLoading(false);
  };

  const eliminateUpdatedOrSkippedReasons = (reasons: ApplicationLeadPromptReason[]) => {
    const reasonList = reasons?.filter(({ status }) => status === ApplicationLeadPromptStatus.Prompted);
    return reasonList;
  };

  const handleNavigateForwardWithPromptReason = async () => {
    const reasonList = await handleGetApplicationLeadPromptReasons();
    const onlyPromptedReasons = eliminateUpdatedOrSkippedReasons(reasonList);
    const isBanking = window.location.pathname === '/banking-info';

    if (onlyPromptedReasons?.length === 0 || isBanking) {
      navigate('/agreement-review');
    } else {
      const nextRouteName = getRouteNameFromPromptReasonList(onlyPromptedReasons);
      navigate(nextRouteName);
    }
  };

  const handleNavigateForward = async (reason: ApplicationLeadPromptReasons) => {
    if (holdInformationRequestedActive) {
      setLoading(true);
      await handleNavigateForwardWithPromptReason();
      setLoading(false);
    } else if (reason === ApplicationLeadPromptReasons.Owner && window.location.pathname === '/owner-info') {
      navigate('/business-info');
    } else {
      navigate(ApplicationLeadPromptReasonsNavigationMap[reason].nextRouteName);
    }
  };

  const handleGetApplicationLeadPromptReasons = async () => {
    setLoading(true);
    const res = await getApplicationLeadPromptReasons();
    setLoading(false);

    const reasonList = res?.data?.getApplicationLeadPromptReasons?.applicationLeadPromptReasons;

    setPromptReasonListInSessionData(reasonList);

    return reasonList;
  };

  return {
    applicationLeadPromptReasons,
    setPromptReasonListInSessionData,
    holdInformationRequestedActive,
    checkUpdatePromptReasonStatusNeeded,
    updatePromptReasonStatus,
    getRouteNameFromPromptReasonList,
    createUpdatePromptReasonHandler,
    clearPromptReasonListInSessionData,
    checkPromptReasonListHasReason,
    updateApplicationLeadInfo,
    getReasonByRouteName,
    handleNavigateForward,
    getBankingRelatedReasons,
    handleGetApplicationLeadPromptReasons,
    loading,
  };
};

export default useApplicationLeadPromptReason;
