import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';

import { BusinessPhotoInfoModal } from 'pages/AddBusinessPhoto/BusinessPhotoInfoModal';

const ZOOM_URL = 'https://withcherry.chilipiper.com/book/underwriting-review-queue';
const AddBusinessPhoto = () => {
  const { sessionData } = useStore();
  const navigate = useNavigate();
  const alert = useAlert();

  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();

  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [radioValue, setRadioValue] = useState('');

  const openInfoModal = () => {
    setDisplayInfoModal(true);
  };

  const closeInfoModal = () => {
    setDisplayInfoModal(false);
  };

  const onRadioValueChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleScheduleZoomWalkthrough = () => {
    window.open(ZOOM_URL, '_blank');

    return;
  };

  const continueAction = async () => {
    const requirement: any = sessionData.requirements?.find((req: any) => req.reason === 'R_LocationPictures');

    const res = await submitApplicationLeadRequirement({
      applicationLeadId: sessionData.id,
      requirementId: requirement?.id,
    });

    if (res) {
      navigate('/provide-more-info');
    } else {
      alert.info('Upload failed, please try again later.');
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'photo-black'} />
        <Title m={'10px 0 8px 0'}>Location photo(s)</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          We require a quick self-inspection and photo evidence of your location and treatment areas.
        </Subtitle>
        <Subtitle>
          You will receive an email from our trusted partner <b>Visualogyx</b> with instructions on how to complete your
          self-inspection.
        </Subtitle>
        <Gap size="16px" />
        <Row>
          <Icon src={'info_circle_green'} width={24} height={24} />
          <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={openInfoModal}>
            Having trouble with Visualogyx?
          </UnderlinedSubtitle>
        </Row>
        <Gap size="24px" />
        <RadioButtonGroup
          label="Have you completed your location verification?"
          onChange={onRadioValueChange}
          value={radioValue}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={loading || radioValue !== 'true'} loading={loading} type="submit" onClick={continueAction}>
          Continue
        </Button>
      </ButtonContainer>
      <BusinessPhotoInfoModal
        show={displayInfoModal}
        hideModal={closeInfoModal}
        handleZoomWalkthrough={handleScheduleZoomWalkthrough}
      />
    </Container>
  );
};

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
`;

export default AddBusinessPhoto;
