import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, RemoveModal, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';

import { getBase64 } from 'lib/utils';

import { useApplicationLeadRequirementSubmit, useUploadApplicationLeadRequirementFile } from 'lib/services';

interface FileType {
  name: string;
  mime: string;
  data: string;
}

export interface PhotoUploadFormProps {
  exampleImgSrc?: string;
  icon?: string;
  reason: string;
  subtitle: string;
  title: string;
}

export const PhotoUploadForm = ({
  exampleImgSrc = '/example.jpg',
  icon = 'photo-black',
  reason,
  subtitle,
  title,
}: PhotoUploadFormProps) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { setPageTitle, sessionData } = useStore();

  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();
  const { uploadApplicationLeadRequirement, loading: uploadLoading } = useUploadApplicationLeadRequirementFile();

  const fileInput = useRef<any>(null);
  const [show, setShow] = useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState<null | number>(null);
  const [images, setImages] = useState<FileType[]>([]);

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const addImage = ({ name = Date.now().toString() + '.jpg', mime = 'image/jpeg', data, size }) => {
    const newImages = [...images, { name, data, mime, size }];
    setImages(newImages);
    resetFileInput();
  };

  const onDesktopImageChange = (event) => {
    if (event?.target?.files?.[0]) {
      const file = event.target.files[0];

      // 20MB check
      if (file?.size > 20000000) {
        alert.info('File too large, must be less than 20MB.');
        resetFileInput();
      } else {
        getBase64(file)
          .then((result) => {
            addImage({ name: file.name, mime: file?.type, data: result as string, size: file?.size });
          })
          .catch((err) => {
            console.log(err);
            alert.info('Attachment failed, please try again later.');
          });
      }
    } else {
      alert.info('Attachment failed, please try again later.');
    }
  };

  const removeImage = () => {
    if (Number(selectedImageIndex) >= 0) {
      const newImages = [...images];
      if (typeof selectedImageIndex === 'number') {
        newImages.splice(selectedImageIndex, 1);
      }

      setImages(newImages);
      setSelectedImageIndex(null);
    }
  };

  const hideRemoveModal = () => {
    setShow(false);
    setSelectedImageIndex(null);
  };

  const openRemoveModal = (index: number) => {
    setShow(true);
    setSelectedImageIndex(index);
  };

  const resetFileInput = () => {
    fileInput.current.value = '';
  };

  const addPhoto = () => {
    fileInput?.current?.click();
  };

  const buldImageUploadRequest = async (imageData, requirementId, applicationLeadId) => {
    try {
      const results = await Promise.all(
        imageData?.map((img) => {
          return uploadApplicationLeadRequirement({ requirementId, applicationLeadId, file: img });
        }),
      );
      const checker = (arr) => arr.every((v) => v === true);
      return checker(results);
    } catch (e) {
      console.warn('Upload failed, please try again later');
    }
  };

  const continueAction = async () => {
    const requirement: any = sessionData.requirements?.find((req: any) => req.reason === reason);

    const params: FileType[] = [];
    images.forEach((image: FileType) => {
      params.push({ name: image.name, mime: image.mime, data: image.data?.split('base64,')[1] });
    });

    const imageResult = await buldImageUploadRequest(params, requirement.id, sessionData.id);

    if (!imageResult) {
      alert.info('Upload failed, please try again later');
    } else {
      const res = await submitApplicationLeadRequirement({
        applicationLeadId: sessionData.id,
        requirementId: requirement?.id,
      });

      if (res) {
        navigate('/provide-more-info');
      } else {
        alert.info('Upload failed, please try again later.');
      }
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={icon} />
        <Title m={'12px 0 8px 0'}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <Subtitle m={'16px 0px 8px 0px'}>{!images.length && 'Example:'}</Subtitle>
        {!images.length && (
          <ImageContainer>
            <ExampleImage src={exampleImgSrc} />
          </ImageContainer>
        )}

        {images.map((image, index) => {
          return (
            <ImageContainer key={index}>
              <ExampleImage src={image?.data} />
              <ImageRemove data-testid="removePhoto" onClick={() => openRemoveModal(index)}>
                <Icon src={'close-x'} width={14} height={14} hover={true} />
              </ImageRemove>
              <ImageText>{image?.name}</ImageText>
            </ImageContainer>
          );
        })}
      </ContentContainer>

      <StyledButtonContainer>
        <Button
          disabled={!images.length || loading || uploadLoading}
          loading={loading || uploadLoading}
          onClick={continueAction}
        >
          Submit
        </Button>

        <Button secondary={true} onClick={addPhoto}>
          Add {!!images.length && 'Another'} Photo
        </Button>

        <DesktopInput
          accept="image/*"
          type="file"
          data-testid="upload"
          onChange={onDesktopImageChange}
          ref={fileInput}
        />
      </StyledButtonContainer>

      <RemoveModal show={show} hideModal={hideRemoveModal} removeImage={removeImage} />
    </Container>
  );
};

const ExampleImage = styled.img`
  margin: 0 0 8px 0;
  width: 100%;
  height: auto;
`;

const StyledButtonContainer = styled(ButtonContainer)`
  > button {
    margin-bottom: 8px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const ImageRemove = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  background-color: #00c37c;
  top: 10px;
  right: 10px;
  padding: 13px;
  box-shadow: 0 36px 67px rgba(0, 0, 0, 0.07), 0 13.1406px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 6.37951px 11.873px rgba(0, 0, 0, 0.0389404), 0 3.12736px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 1.23656px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 40px;

  > img {
    z-index: -1;
  }
`;

const ImageText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0e202f;
  margin-bottom: 24px;
`;

const DesktopInput = styled.input`
  display: none;
`;
