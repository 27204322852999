import { gql } from '@apollo/client/core';

export const LOGIN = gql`
  mutation userLogin($input: UserLoginRequest!) {
    userLogin(input: $input) {
      token
      refreshToken
      user {
        id
        email
        firstName
        lastName
      }
      success
      code
      message
    }
  }
`;

export const REGISTER = gql`
  mutation createApplicationLeadUser($input: CreateApplicationLeadUserRequest!) {
    createApplicationLeadUser(input: $input) {
      token
      refreshToken
      user {
        id
        email
        firstName
        lastName
        roles
        prequal
      }
      success
      code
      message
    }
  }
`;

export const VALIDATE_TOKEN = gql`
  mutation validateToken($input: ValidateTokenRequest) {
    validateToken(input: $input) {
      success
      status
      token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      success
      code
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordRequest) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput) {
    refreshToken(input: $input) {
      code
      message
      success
      token
      refreshToken
    }
  }
`;

export const CREATE_USER = gql`
  mutation createOwnerUserRequest($input: UserInput) {
    createOwnerUserRequest(input: $input) {
      status
      message
      error
      user {
        id
        email
        firstName
        lastName
        employeeId
        phone
        roles
      }
    }
  }
`;
