import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { APP_SMARTY_KEY } from 'config';
import { ApplicationLeadReasonPrompt, Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { US_STATES, ApplicationLeadPromptReasons, ApplicationLeadPromptStatus } from 'lib/constants';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { numericPattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';

const SmartySDK = require('smartystreets-javascript-sdk');
const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;

const smartyClientKey: any = APP_SMARTY_KEY;

const credentials = new SmartyCore.SharedCredentials(smartyClientKey);

const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
const client = clientBuilder.buildUsAutocompleteProClient();

interface State {
  address1: string;
  city: string;
  state: string;
  zip: string;
}

interface USStates {
  name: string;
  value: string;
}

const BusinessAddress = () => {
  const navigate = useNavigate();
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { trackSegmentEvent } = useSegment();
  const { handleSubmit, control, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const {
    checkUpdatePromptReasonStatusNeeded,
    createUpdatePromptReasonHandler,
    holdInformationRequestedActive,
    checkPromptReasonListHasReason,
    handleNavigateForward,
    loading: updatePromptReasonLoading,
    updateApplicationLeadInfo,
  } = useApplicationLeadPromptReason();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [autocompleteData, setAutocompleteData] = useState<any[]>([]);
  const [isAddressInfoReasonPromptVisible, setIsAddressInfoReasonPromptVisible] = useState(false);

  const { isValid } = formState;

  useEffect(() => {
    const isAddressReasonInPromptList =
      holdInformationRequestedActive && checkPromptReasonListHasReason(ApplicationLeadPromptReasons.Address);

    if (isAddressReasonInPromptList) {
      setIsAddressInfoReasonPromptVisible(true);
    }
  }, [holdInformationRequestedActive]);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_ADDRESS_PAGE_LOAD);
  }, []);

  useEffect(() => {
    setPageIndex(6);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Business Info');
  }, [setPageTitle]);

  useEffect(() => {
    if (searchTerm?.length > 0) {
      setTimeout(() => {
        const lookup = new Lookup(searchTerm);

        lookup.maxResults = 10;
        lookup.preferRatio = 33;
        lookup.source = 'all';

        handleRequest(lookup);
      }, 250);
    }
  }, [searchTerm]);

  const handleRequest = async (lookup) => {
    try {
      const results = await client.send(lookup);
      logSuggestions(results);
    } catch (err) {
      console.log(err);
    }
  };

  const logSuggestions = async (response) => {
    const _autoCompleteData: any = [];
    response.result.forEach((address, index) => {
      const { city, state, zipcode, streetLine } = address || {};
      const addressLine = `${streetLine}, ${city}, ${state}, ${zipcode}`;
      const obj = {
        id: index,
        label: addressLine,
        street: streetLine,
        city,
        state,
        zip: zipcode,
      };
      _autoCompleteData.push(obj);
    });

    setAutocompleteData(_autoCompleteData);
  };

  const setAddressValues = (selectedAddress: any) => {
    if (selectedAddress) {
      setValue('street', selectedAddress.street, { shouldValidate: true });
      setValue('city', selectedAddress.city, { shouldValidate: true });
      setValue('state', selectedAddress.state, { shouldValidate: true });
      setValue('zip', selectedAddress.zip, { shouldValidate: true });
    }
  };

  const isInformationUpdated = (data) => {
    const { city, unit, street, zip, state } = data;

    return (
      city !== sessionData?.city ||
      unit !== sessionData?.unit ||
      street !== sessionData?.street ||
      zip !== sessionData?.zip ||
      state !== sessionData?.state
    );
  };

  const onSubmit = async (data) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_ADDRESS_CONTINUE_CLICKED);

    if (checkUpdatePromptReasonStatusNeeded()) {
      const { city, unit, street, zip, state } = data;

      await createUpdatePromptReasonHandler(isInformationUpdated, ApplicationLeadPromptReasons.Address)(data);
      await updateApplicationLeadInfo({ address: { city, unit, street, zip, state } });
    }

    setSessionData(data);
    await handleNavigateForward(ApplicationLeadPromptReasons.Address);
  };

  const onEditInfo = () => {
    setIsAddressInfoReasonPromptVisible(false);
  };

  const onSkipReasonPrompt = async () => {
    await createUpdatePromptReasonHandler(isInformationUpdated, ApplicationLeadPromptReasons.Address)(
      {},
      ApplicationLeadPromptStatus.Skipped,
    );
    await handleNavigateForward(ApplicationLeadPromptReasons.Address);
  };

  return (
    <Container backUrl="/business-additional-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'location-pin'} />
          <Title m={'10px 0px 8px 0px'}>Where is your business located?</Title>
          <Subtitle m={'0px 0px 24px'}>
            We’re looking for a physical address. If there are multiple locations, use the primary headquarters.
          </Subtitle>
          <Box>
            <Controller
              name="street"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Autocomplete
                  options={autocompleteData}
                  value={value}
                  onChange={(event: any, newValue: any | null) => {
                    setAddressValues(newValue);
                  }}
                  id="disable-clearable"
                  disableClearable={true}
                  renderInput={(params) => {
                    delete params.inputProps.className;
                    return (
                      <TextField
                        {...params}
                        id="filled-basic"
                        variant="filled"
                        data-testid="street"
                        label="Street Address"
                        name="street"
                        value={value}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    );
                  }}
                />
              )}
            />
            <Controller
              name="unit"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  data-testid="unit"
                  label="Apt / Unit (Optional)"
                  name="address1"
                  autoComplete="new-password"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <RowContainer>
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: true, validate: trapSpacesForRequiredFields }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    data-testid="city"
                    data-name="city"
                    label="City"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="filled-basic"
                    variant="filled"
                    data-testid="state"
                    data-name="state"
                    label="State"
                    select={true}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  >
                    {US_STATES.map((option: USStates) => (
                      <MenuItem data-testid="stateItem" key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </RowContainer>
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              rules={{ required: true, maxLength: 5, minLength: 5 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  data-testid="zip"
                  data-name="zip"
                  type="tel"
                  inputProps={{
                    maxLength: 5,
                  }}
                  label="ZIP Code"
                  value={value}
                  onKeyPress={(event) => {
                    if (!numericPattern.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Box>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>This address should be a physical location where your patients receive services.</InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        {/*// @ts-ignore*/}
        <Button disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)} loading={updatePromptReasonLoading}>
          Continue
        </Button>
        {/* <Button disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button> */}
      </ButtonContainer>
      <ApplicationLeadReasonPrompt
        type={ApplicationLeadPromptReasons.Address}
        show={!!isAddressInfoReasonPromptVisible}
        onConfirmAndContinue={onSkipReasonPrompt}
        onEditInfo={onEditInfo}
        loading={updatePromptReasonLoading}
      />
    </Container>
  );
};

export default BusinessAddress;

const RowContainer = styled.div`
  display: flex;
  alignitems: center;
  width: 551px;

  div[data-name='city'] {
    flex: 4;
    text-align: left;
  }

  div[data-name='state'] {
    flex: 2;
    margin-left: 8px;
  }

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
