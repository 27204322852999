import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import {
  fieldNames,
  infoarrOther,
  infoarrOwner,
  InfoarrType,
  reviewEditRoutesOther,
  reviewEditRoutesOwner,
  titlesOther,
  titlesOwner,
} from 'lib/constants/review';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { FormatPhoneNumber } from 'lib/utils/Formatters';
import { getAccountTypeInitialValue } from 'pages/BankingInfo/BankingInfo';
import { USER_ROLE, USER_ROLE_TITLES } from 'pages/UserRole/UserRole';

const Review = () => {
  const navigate = useNavigate();
  const { setPageIndex, sessionData, setPageTitle } = useStore();
  const { trackSegmentEvent, trackPage } = useSegment();

  const buttonRef: any = useRef(null);
  const containerRef: any = useRef(null);

  const userData = StorageService.getUserData();

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_REVIEW_INFO_PAGE_LOAD);
  }, []);

  useEffect(() => {
    trackPage('review');
    setPageIndex(10);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Review');
  }, [setPageTitle]);

  const onSubmit = () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_REVIEW_INFO_CONTINUE_CLICKED);
    navigate('/pricing-review');
  };

  const onEdit = () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_REVIEW_INFO_START_OVER_CLICKED);
    navigate('/user-role');
  };

  const getTitlesForNotOwner = () => {
    const titleArray = titlesOther;

    titleArray.representative = USER_ROLE_TITLES[sessionData?.role];

    return titleArray;
  };

  const infoarr = sessionData?.role === USER_ROLE.OWNER ? infoarrOwner : infoarrOther;
  const titles = sessionData?.role === USER_ROLE.OWNER ? titlesOwner : getTitlesForNotOwner();
  const reviewEditRoutes = sessionData?.role === USER_ROLE.OWNER ? reviewEditRoutesOwner : reviewEditRoutesOther;

  const fields = Object.keys(infoarr);

  const compile: InfoarrType = {};

  Object.keys(infoarr).forEach((section: string) => {
    const reviewItems: string[] = [];
    infoarr[section].forEach((field: string) => {
      // @ts-ignore // FIX ME
      return reviewItems.push({ key: field, value: sessionData[field] || userData.user[field] || 'Not Available' });
    });
    compile[section] = reviewItems;
  });

  const scrollToBottom = () => {
    buttonRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollElement = () => {
    scrollToBottom();
  };

  const tableValues = (values, key) => {
    if (Array.isArray(values)) {
      return values.join(', ');
    } else if (key === 'pocPhone' || key === 'phone') {
      return FormatPhoneNumber(values);
    } else if (key === 'accountType') {
      return (
        sessionData?.mappedAccountType ||
        getAccountTypeInitialValue(sessionData?.accountType, sessionData?.accountClassification)
      );
    } else {
      return values;
    }
  };

  const handleSectionEdit = (routeName) => {
    navigate(routeName);
  };

  const NON_CAPITALIZED_FIELDS = ['email', 'website', 'ownerEmail'];

  return (
    <Container type="agreement" backUrl="/banking-info">
      <ContentContainer ref={containerRef}>
        <Icon src={'glasses-review'} />
        <Title m={'14px 0px 8px 0px'}>Review your information</Title>
        <Subtitle m={'0px 0px 24px'}>Please review the information below for accuracy.</Subtitle>
        <ReviewContainer>
          {fields.map((field: string, index: number) => (
            <SectionContainer key={index}>
              <SectionHeaderContainer>
                <SectionTitle>{titles[field]}</SectionTitle>
                <Icon
                  width={24}
                  height={24}
                  src="pencil-edit"
                  onClick={() => handleSectionEdit(reviewEditRoutes[field])}
                />
              </SectionHeaderContainer>

              {compile[field].map((item: any, itemIndex: number) => {
                return (
                  <CompileContainer key={itemIndex}>
                    <CompileTitle shouldCapitalize={!NON_CAPITALIZED_FIELDS.includes(item.key)}>
                      {fieldNames[item.key]}: {tableValues(item.value, item.key)}
                    </CompileTitle>
                  </CompileContainer>
                );
              })}
            </SectionContainer>
          ))}
        </ReviewContainer>
        <ScrollToBottom onClick={scrollElement}>
          <Icon src="chevron_duo_down" hover={true} />
        </ScrollToBottom>
      </ContentContainer>
      <ButtonContainer>
        <Button ref={buttonRef} type="submit" onClick={onSubmit}>
          Continue
        </Button>
        <Button secondary={true} data-testid="edit-info-button" onClick={onEdit}>
          Start Over
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Review;

const ReviewContainer = styled.div`
  background: ${(props) => props.theme.main.gray5};
  border-radius: 8px;
  padding: 16px;
`;

const SectionContainer = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cfd2d5;
  &:last-child {
    border: none !important;
  }
`;

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;

const CompileContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

const CompileTitle = styled.div<{ shouldCapitalize: any }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: ${(props) => (props.shouldCapitalize ? 'capitalize' : 'none')};
  text-align: left;
`;

const ScrollToBottom = styled.div`
  background: #00c37d;
  box-shadow: 0px 50.4px 93.8px rgba(0, 0, 0, 0.07), 0px 18.3968px 34.2385px rgba(0, 0, 0, 0.0482987),
    0px 8.93132px 16.6222px rgba(0, 0, 0, 0.0389404), 0px 4.3783px 8.1485px rgba(0, 0, 0, 0.0310596),
    0px 1.73119px 3.22193px rgba(0, 0, 0, 0.0217013);
  border-radius: 33.6px;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 1;
  bottom: 260px;
  right: calc((100% / 2 - 260px));
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    bottom: 210px;
    right: 40px;
  }
`;

const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
