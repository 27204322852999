import { ApplicationLeadPromptReason } from 'lib/constants';
import create, { State } from 'zustand';
import { devtools } from 'zustand/middleware';

interface UserType {
  firstName?: string;
  lastName?: string;
  phone?: string;
  user?: {
    id: string;
  };
}

export interface Requirement {
  id: number;
  reason: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

export interface SessionDataType {
  id?: string;
  pocEmail?: string;
  pocPhone?: string;
  pocFirstName?: string;
  pocLastName?: string;
  password?: string;
  name?: string;
  dba?: string;
  establishedAt?: string;
  industry?: string;
  phone?: string;
  status?: string;
  street?: string;
  unit?: string;
  zip?: string;
  city?: string;
  state?: string;
  firstName?: string;
  lastName?: string;
  accountName?: string;
  accountNumber?: string;
  routingNumber?: string;
  accountType?: string;
  accountClassification?: string;
  mappedAccountType?: string;
  role?: string;
  website?: string;
  sourceId?: string;
  source?: string;
  sourceRef?: string;
  slug?: string;
  offerFinancing?: string;
  financingOptions?: string;
  hasNoRefundPolicy?: boolean | string;
  promoPeriod?: string;
  idApplicationLead?: string;
  repeatPassword?: string;
  idRelation?: number;
  eSign?: boolean;
  disclosures?: boolean;
  retailSales?: boolean;
  requirements?: Requirement[];
  hasDba?: string;
  applicationLeads?: ApplicationLead[];
  ownerFirstname?: string;
  ownerLastname?: string;
  ownerUserId?: string;
  ownerEmail?: string;
  ownerPhone?: string;
  isFirstLook?: boolean;
  firstLookDeclineExplanation?: string;
  einNumber?: string;
  npi?: string;
  applicationLeadPromptReasons?: ApplicationLeadPromptReason[];
  ownerDob?: string;
}

export interface OnboardingFileType {
  createdAt: string;
  externalId: string;
  idFile: number;
  idRelation?: number;
  mime: string;
  name: string;
  relationType: string;
  status: string;
  type: string;
  updatedAt: string;
}

export interface ApplicationLead {
  id: number;
  name: string;
  userId: number;
  status: string;
  dba: string;
  industry: string;
  requirements: any;
  promoPeriod: number;
  bankInfo: any;
  phone: string;
  website: string;
  role: string;
  ownerUserId: string;
  financingOptions: unknown;
  address: {
    city: string;
    unit: string;
    street: string;
    zip: string;
    state: string;
  };
  establishedAt: string;
  hasNoRefundPolicy: boolean;
  reference: {
    ref: string;
    source: string;
  };
  isFirstLook?: boolean;
  firstLookDeclineExplanation?: string;
}

export const initialSessionData = {
  id: '',
  name: '',
  dba: '',
  industry: '',
  phone: '',
  email: '',
  website: '',
  ssn: '',
  establishedAt: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  offerFinancing: '',
  financingOptions: '',
  hasNoRefundPolicy: '',
  firstName: '',
  lastName: '',
  pocPhone: '',
  pocFirstName: '',
  pocLastName: '',
  accountName: '',
  accountNumber: '',
  confirmedAccountNumber: '',
  routingNumber: '',
  accountType: '',
  accountClassification: '',
  mappedAccountType: '',
  role: '',
  password: '',
  promoPeriod: '',
  repeatPassword: '',
  idRelation: 0,
  eSign: false,
  disclosures: false,
  retailSales: false,
  requirements: [],
  hasDba: '',
  applicationLeads: [],
  ownerFirstname: '',
  ownerLastname: '',
  ownerEmail: '',
  ownerPhone: '',
  ownerUserId: '',
  isFirstLook: '' as unknown as boolean, // Convert type not to get Radio Button component error
  firstLookDeclineExplanation: '',
  einNumber: '',
  npi: '',
  applicationLeadPromptReasons: [],
  ownerDob: '',
};

const initialUserData = {
  firstName: '',
  lastName: '',
  phone: '',
  user: {
    id: null,
  },
  ownerDob: '',
};

const initialOnboardingFile = {
  createdAt: '',
  externalId: '',
  idFile: 0,
  idRelation: 0,
  mime: '',
  name: '',
  relationType: '',
  status: '',
  type: '',
  updatedAt: '',
};

interface Store extends State {
  pageIndex: number;
  setPageIndex: (value: number) => void;
  pageTitle: string;
  setPageTitle: (value: string) => void;
  sessionData: SessionDataType;
  setSessionData: (value: SessionDataType) => void;
  userData: UserType;
  setUserData: (value: UserType) => void;
  onboardingFile: OnboardingFileType;
  setOnboardingFile: (value: OnboardingFileType) => void;
  selectedApplicationLeadId: string;
  setSelectedApplicationLeadId: (selectedApplicationLeadId: string) => void;
  onboardingFileData: string;
  setOnboardingFileData: (value: string) => void;
  holdInformationRequestedActive: boolean;
  setHoldInformationRequestedActive: (value: boolean) => void;
}

const useStore = create(
  devtools((set: any) => ({
    pageIndex: 0,
    setPageIndex: (value: number) => set(() => ({ pageIndex: value })),
    pageTitle: '',
    setPageTitle: (value: string) => set(() => ({ pageTitle: value })),
    userData: initialUserData,
    setUserData: (value: UserType) => set((state: Store) => ({ userData: { ...state.userData, ...value } })),
    sessionData: initialSessionData,
    applicationLeads: null,
    setSessionData: (value: SessionDataType) =>
      set((state: Store) => ({ sessionData: { ...state.sessionData, ...value } })),
    selectedApplicationLeadId: '',
    setSelectedApplicationLeadId: (selectedApplicationLeadId: string) => set(() => ({ selectedApplicationLeadId })),
    setSelectedApplicationLead: (selectedApplicationLead: ApplicationLead | undefined) =>
      set(() => ({ selectedApplicationLead })),
    selectedApplicationLead: undefined as ApplicationLead | undefined,
    onboardingFile: initialOnboardingFile,
    setOnboardingFile: (value: OnboardingFileType) => set(() => ({ onboardingFile: value })),
    onboardingFileData: '',
    setOnboardingFileData: (value: string) => set(() => ({ onboardingFileData: value })),
    setApplicationLeads: (data: any) => set((state) => ({ ...state.sessionData, applicationLeads: data })),
    trainingLink: null,
    setTrainingLink: (trainingLink: string) => set(() => ({ trainingLink })),
    holdInformationRequestedActive: false,
    setHoldInformationRequestedActive: (value: boolean) => set(() => ({ holdInformationRequestedActive: value })),
  })),
);

export default useStore;
