import * as Sentry from '@sentry/react';
import { getUserData } from 'lib/services/Storage';
import { PRACTICE_ONBOARDING } from '../constants';
import { useFlags } from './FeatureManagement/FlagsContext';
import useStore from './useStore';

interface IdentifyData {
  firstName?: string;
  lastName?: string;
  dob?: string;
  phone?: string;
  id?: string | number;
  street?: string;
  zip?: string;
  state?: string;
  city?: string;
  email?: string;
}

interface SegmentEventHandlerOptions {
  data?: any;
  withInput?: boolean;
  isFilledEvent?: boolean;
  cb?: (e) => void;
}

export enum SegmentEventNames {
  LOGIN_CLICKED = 'LOGIN_CLICKED',
  FORGOT_PASSWORD_CLICKED = 'FORGOT_PASSWORD_CLICKED',
  LOGIN_CREATE_NEW_ACCOUNT_CLICKED = 'LOGIN_CREATE_NEW_ACCOUNT_CLICKED',
  NEED_HELP_CLICKED = 'NEED_HELP_CLICKED',
  SEND_RESET_LINK_CLICKED = 'SEND_RESET_LINK_CLICKED',
  EMAIL_SUPPORT_CLICKED = 'EMAIL_SUPPORT_CLICKED',
  CALL_SUPPORT_CLICKED = 'CALL_SUPPORT_CLICKED',
  SELECT_ORGANIZATION_ONCHANGE = 'SELECT_ORGANIZATION_ONCHANGE',
  SELECT_ORGANIZATION_CONTINUE = 'SELECT_ORGANIZATION_CONTINUE',
  REGISTER_NEW_BUSINESS = 'REGISTER_NEW_BUSINESS',
  LOGOUT_CLICKED = 'LOGOUT_CLICKED',
  BUSINESS_OWNER_RADIO_CHANGE = 'BUSINESS_OWNER_RADIO_CHANGE',
  BUSINESS_OWNER_CONTINUE = 'BUSINESS_OWNER_CONTINUE',
  BUSINESS_OWNER_SHARE = 'BUSINESS_OWNER_SHARE',
  BUSINESS_INFO_CONTINUE_CLICKED = 'BUSINESS_INFO_CONTINUE_CLICKED',
  BUSINESS_INFO_RADIO_CHANGE = 'BUSINESS_INFO_RADIO_CHANGE',
  BUSINESS_ADDITIONAL_INFO_CONTINUE_CLICKED = 'BUSINESS_ADDITIONAL_INFO_CONTINUE_CLICKED',
  BUSINESS_ADDITIONAL_INFO_INDUSTRY_CHANGE = 'BUSINESS_ADDITIONAL_INFO_INDUSTRY_CHANGE',
  FINANCIAL_SERVICES_RADIO_CHANGE = 'FINANCIAL_SERVICES_RADIO_CHANGE',
  FINANCIAL_SERVICES_CONTINUE_CLICKED = 'FINANCIAL_SERVICES_CONTINUE_CLICKED',
  BANKING_INFO_CONTINUE_CLICKED = 'BANKING_INFO_CONTINUE_CLICKED',
  FIRST_LOOK_CONTINUE_CLICKED = 'FIRST_LOOK_CONTINUE_CLICKED',
  REVIEW_CONTINUE_CLICKED = 'REVIEW_CONTINUE_CLICKED',
  REVIEW_EDIT_INFO_CLICKED = 'REVIEW_EDIT_INFO_CLICKED',
  PLAN_SELECTION_REVIEW_PLAN_CLICKED = 'PLAN_SELECTION_REVIEW_PLAN_CLICKED',
  PLAN_SELECTION_BACK_CLICKED = 'PLAN_SELECTION_BACK_CLICKED',
  PLAN_SELECTION_SELECT_PLAN_CHANGE = 'PLAN_SELECTION_SELECT_PLAN_CHANGE',
  PLAN_REVIEW_CHOOSE_PLAN_CLICKED = 'PLAN_REVIEW_CHOOSE_PLAN_CLICKED',
  PLAN_REVIEW_CHOOSE_DIFFERENT_PLAN_CLICKED = 'PLAN_REVIEW_CHOOSE_DIFFERENT_PLAN_CLICKED',
  AGREEMENT_AGREE_AND_CONFIRM_CLICKED = 'AGREEMENT_AGREE_AND_CONFIRM_CLICKED',
  PREPARATION_LIST_CONTINUE_CLICKED = 'PREPARATION_LIST_CONTINUE_CLICKED',
  BUSINESS_OWNER_PHONE_CONTINUE_CLICKED = 'BUSINESS_OWNER_PHONE_CONTINUE_CLICKED',
  OTP_CONTINUE_CLICKED = 'OTP_CONTINUE_CLICKED',
  OTP_DIDNT_GET_CODE_CLICKED = 'OTP_DIDNT_GET_CODE_CLICKED',
  REGISTER_CREATE_AND_APPLY_CLICKED = 'REGISTER_CREATE_AND_APPLY_CLICKED',
  USER_ROLE_CONTINUE_CLICKED = 'USER_ROLE_CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_YOUR_ROLE_PAGE_LOAD = 'PRACTICE_REGISTRATION.YOUR_ROLE.PAGE_LOAD',
  PRACTICE_REGISTRATION_YOUR_ROLE_ROLE_SELECTOR_CLICKED = 'PRACTICE_REGISTRATION.YOUR_ROLE.ROLE_SELECTOR_CLICKED',
  PRACTICE_REGISTRATION_YOUR_ROLE_ROLE_SELECTED = 'PRACTICE_REGISTRATION.YOUR_ROLE.ROLE_SELECTED',
  PRACTICE_REGISTRATION_YOUR_ROLE_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.YOUR_ROLE.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_OWNER_INFO_PAGE_LOAD = 'PRACTICE_REGISTRATION.OWNER_INFO.PAGE_LOAD',
  PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_CLICKED = 'PRACTICE_REGISTRATION.OWNER_INFO.FIRST_NAME_CLICKED',
  PRACTICE_REGISTRATION_OWNER_INFO_LAST_NAME_CLICKED = 'PRACTICE_REGISTRATION.OWNER_INFO.LAST_NAME_CLICKED',
  PRACTICE_REGISTRATION_OWNER_INFO_FIRST_NAME_FILLED = 'PRACTICE_REGISTRATION.OWNER_INFO.FIRST_NAME_FILLED',
  PRACTICE_REGISTRATION_OWNER_INFO_LAST_NAME_FILLED = 'PRACTICE_REGISTRATION.OWNER_INFO.LAST_NAME_FILLED',
  PRACTICE_REGISTRATION_OWNER_INFO_EMAIL_FILLED = 'PRACTICE_REGISTRATION.OWNER_INFO.EMAIL_FILLED',
  PRACTICE_REGISTRATION_OWNER_INFO_PHONE_CLICKED = 'PRACTICE_REGISTRATION.OWNER_INFO.PHONE_CLICKED',
  PRACTICE_REGISTRATION_OWNER_INFO_PHONE_FILLED = 'PRACTICE_REGISTRATION.OWNER_INFO.PHONE_FILLED',
  PRACTICE_REGISTRATION_OWNER_INFO_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.OWNER_INFO.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_PAGE_LOAD = 'PRACTICE_REGISTRATION.PERSONAL_INFO.PAGE_LOAD',
  PRACTICE_REGISTRATION_PERSONAL_INFO_FIRST_NAME_CLICKED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.FIRST_NAME_CLICKED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_LAST_NAME_CLICKED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.LAST_NAME_CLICKED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_FIRST_NAME_FILLED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.FIRST_NAME_FILLED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_LAST_NAME_FILLED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.LAST_NAME_FILLED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_PHONE_CLICKED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.PHONE_CLICKED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_PHONE_FILLED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.PHONE_FILLED',
  PRACTICE_REGISTRATION_PERSONAL_INFO_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.PERSONAL_INFO.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_OTP_PAGE_LOAD = 'PRACTICE_REGISTRATION.OTP.PAGE_LOAD',
  PRACTICE_REGISTRATION_OTP_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.OTP.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_OTP_SEND_CODE_CLICKED = 'PRACTICE_REGISTRATION.OTP.SEND_CODE_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_PAGE_LOAD = 'PRACTICE_REGISTRATION.BUSINESS_INFO.PAGE_LOAD',
  PRACTICE_REGISTRATION_BUSINESS_INFO_LEGAL_BUSINESS_NAME_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.LEGAL_BUSINESS_NAME_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_LEGAL_BUSINESS_NAME_FILLED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.LEGAL_BUSINESS_NAME_FILLED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_SELECTED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.DBA_SELECTED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_NAME_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.DBA_NAME_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_NAME_ENTERED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.DBA_NAME_FILLED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_PAGE_LOAD = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.PAGE_LOAD',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_INDUSTRY_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.INDUSTRY_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_INDUSTRY_SELECTED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.INDUSTRY_SELECTED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_PHONE_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.BUSINESS_PHONE_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_PHONE_ENTERED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.BUSINESS_PHONE_FILLED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_WEBSITE_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.BUSINESS_WEBSITE_CLICKED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_BUSINESS_WEBSITE_ENTERED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.BUSINESS_WEBSITE_FILLED',
  PRACTICE_REGISTRATION_BUSINESS_INFO_2_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_INFO_2.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_MORE_DETAILS_PAGE_LOAD = 'PRACTICE_REGISTRATION.MORE_DETAILS.PAGE_LOAD',
  PRACTICE_REGISTRATION_MORE_DETAILS_FINANCING_SELECTED = 'PRACTICE_REGISTRATION.MORE_DETAILS.FINANCING_SELECTED',
  PRACTICE_REGISTRATION_MORE_DETAILS_ESTABLISHED_DATE_ENTERED = 'PRACTICE_REGISTRATION.MORE_DETAILS.ESTABLISHED_DATE_FILLED',
  PRACTICE_REGISTRATION_MORE_DETAILS_EIN_CLICKED = 'PRACTICE_REGISTRATION.MORE_DETAILS.EIN_CLICKED',
  PRACTICE_REGISTRATION_MORE_DETAILS_EIN_FILLED = 'PRACTICE_REGISTRATION.MORE_DETAILS.EIN_FILLED',
  PRACTICE_REGISTRATION_MORE_DETAILS_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.MORE_DETAILS.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_BANK_DETAILS_PAGE_LOAD = 'PRACTICE_REGISTRATION.BANK_DETAILS.PAGE_LOAD',
  PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_TYPE_SELECTED = 'PRACTICE_REGISTRATION.BANK_DETAILS.ACCOUNT_TYPE_SELECTED',
  PRACTICE_REGISTRATION_BANK_DETAILS_ROUTING_NUMBER_ENTERED = 'PRACTICE_REGISTRATION.BANK_DETAILS.ROUTING_NUMBER_FILLED',
  PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NUMBER_ENTERED = 'PRACTICE_REGISTRATION.BANK_DETAILS.ACCOUNT_NUMBER_FILLED',
  PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NUMBER_CONFIRMED = 'PRACTICE_REGISTRATION.BANK_DETAILS.ACCOUNT_NUMBER_CONFIRMED',
  PRACTICE_REGISTRATION_BANK_DETAILS_ACCOUNT_NAME_ENTERED = 'PRACTICE_REGISTRATION.BANK_DETAILS.ACCOUNT_NAME_FILLED',
  PRACTICE_REGISTRATION_BANK_DETAILS_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.BANK_DETAILS.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_REVIEW_INFO_PAGE_LOAD = 'PRACTICE_REGISTRATION.REVIEW_INFO.PAGE_LOAD',
  PRACTICE_REGISTRATION_REVIEW_INFO_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.REVIEW_INFO.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_REVIEW_INFO_START_OVER_CLICKED = 'PRACTICE_REGISTRATION.REVIEW_INFO.START_OVER_CLICKED',
  PRACTICE_REGISTRATION_PRICING_REVIEW_PAGE_LOAD = 'PRACTICE_REGISTRATION.PRICING_REVIEW.PAGE_LOAD',
  PRACTICE_REGISTRATION_PRICING_REVIEW_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.PRICING_REVIEW.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_PRICING_REVIEW_INFO_BACK_CLICKED = 'PRACTICE_REGISTRATION.REVIEW_INFO.BACK_CLICKED',
  PRACTICE_REGISTRATION_AGREEMENT_REVIEW_PAGE_LOAD = 'PRACTICE_REGISTRATION.AGREEMENT_REVIEW.PAGE_LOAD',
  PRACTICE_REGISTRATION_AGREEMENT_REVIEW_AGREE_AND_CONFIRM_CLICKED = 'PRACTICE_REGISTRATION.AGREEMENT_REVIEW.AGREE_AND_CONFIRM_CLICKED',
  PRACTICE_REGISTRATION_FIRST_LOOK_PAGE_LOAD = 'PRACTICE_REGISTRATION.FIRST_LOOK.PAGE_LOAD',
  PRACTICE_REGISTRATION_FIRST_LOOK_YES_OR_NO_CLICKED = 'PRACTICE_REGISTRATION.FIRST_LOOK.YES_OR_NO_CLICKED',
  PRACTICE_REGISTRATION_FIRST_LOOK_REASON_NO_ENTERED = 'PRACTICE_REGISTRATION.FIRST_LOOK.REASON_NO_FILLED',
  PRACTICE_REGISTRATION_BUSINESS_ADDRESS_PAGE_LOAD = 'PRACTICE_REGISTRATION.BUSINESS_ADDRESS.PAGE_LOAD',
  PRACTICE_REGISTRATION_BUSINESS_ADDRESS_CONTINUE_CLICKED = 'PRACTICE_REGISTRATION.BUSINESS_ADDRESS.CONTINUE_CLICKED',
  PRACTICE_REGISTRATION_MORE_DETAILS_ALTERNATE_FINANCING_CLICKED = 'PRACTICE_REGISTRATION.MORE_DETAILS.ALTERNATE_FINANCING_CLICKED',
  PRACTICE_REGISTRATION_MORE_DETAILS_ALTERNATE_FINANCING_FILLED = 'PRACTICE_REGISTRATION.MORE_DETAILS.ALTERNATE_FINANCING_FILLED',
  PRACTICE_REGISTRATION_MORE_DETAILS_ESTABLISHED_DATE_CLICKED = 'PRACTICE_REGISTRATION.MORE_DETAILS.ESTABLISHED_DATE_CLICKED',
  PRACTICE_REGISTRATION_MORE_DETAILS_REFUND_POLICY_SELECTED = 'PRACTICE_REGISTRATION.MORE_DETAILS.REFUND_POLICY_SELECTED',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_TYPE_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_TYPE.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_TYPE_EDIT_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_TYPE.EDIT_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_TYPE_CONFIRM_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_TYPE.CONFIRM_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_DETAILS_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_DETAILS.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_DETAILS_EDIT_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_DETAILS.EDIT_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_BANK_ACCOUNT_DETAILS_SKIP_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_BANK_ACCOUNT_DETAILS.SKIP_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_OWNER_INFO_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_OWNER_INFO.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_OWNER_INFO_ADD_DOB_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_OWNER_INFO.ADD_DOB_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_NPI_INFO_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_NPI_INFO.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_NPI_INFO_ADD_NPI_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_NPI_INFO.ADD_NPI_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_NPI_INFO_SKIP_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_NPI_INFO.SKIP_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_NPI_MISMATCH_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_NPI_MISMATCH.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_NPI_MISMATCH_ADD_DOB_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_NPI_MISMATCH.ADD_DOB_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_ADDRESS_INFO_MODAL_LOAD = 'PRACTICE_REGISTRATION.PROMPT_ADDRESS_INFO.MODAL_LOAD',
  PRACTICE_REGISTRATION_PROMPT_ADDRESS_INFO_EDIT_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_ADDRESS_INFO.EDIT_CLICKED',
  PRACTICE_REGISTRATION_PROMPT_ADDRESS_INFO_SKIP_CLICKED = 'PRACTICE_REGISTRATION.PROMPT_ADDRESS_INFO.SKIP_CLICKED',
}

export const useSegment = () => {
  const { userData, sessionData } = useStore();
  const { flags } = useFlags();

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyFields(v) : v]),
    );
  };

  const trackIdentify = (data?: IdentifyData) => {
    try {
      if (data) {
        const userId = data?.id ? `b-${data?.id}` : undefined;
        const traits = removeEmptyFields({
          firstName: data?.firstName,
          lastName: data?.lastName,
          phone: data?.phone,
          id: data?.id,
          email: data?.email,
        });
        // @ts-ignore analytics
        window.analytics.identify(userId, traits);
      } else {
        // @ts-ignore analytics
        window.analytics.identify();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackAlias = (id: string) => {
    try {
      // @ts-ignore analytics
      window.analytics.alias({
        userId: id,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackPage = (pageName: string, data: object = {}) => {
    try {
      // @ts-ignore analytics
      window.analytics.page(pageName, { application: PRACTICE_ONBOARDING, ...data });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackGroup = (groupId: string, traits) => {
    try {
      // @ts-ignore analytics
      window.analytics.group(groupId, traits);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackSegmentEvent = (eventName: string, data: Record<string, any> = {}) => {
    const featureFlags = {
      ...Object.keys(flags.FEATURES).map((key) => ({ [key]: flags.FEATURES[key].isEnabled() })),
    };

    try {
      const payload = {
        application: PRACTICE_ONBOARDING,
        user: {
          id: getUserData()?.user?.id,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          phone: userData?.phone,
        },
        applicationLead: {
          id: sessionData?.id,
          industry: sessionData?.industry,
        },
        flags: featureFlags,
        ...data,
      };

      // @ts-ignore analytics
      window.analytics.track(eventName, payload);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const segmentEventHandler = (
    eventName: string,
    { data = {}, withInput = false, isFilledEvent = false, cb }: SegmentEventHandlerOptions = {},
  ) => {
    return (ev?) => {
      const value = ev?.target?.value as string;
      const fieldName = (ev?.target?.name || ev?.target?.dataset?.inputkey) as string;

      if (isFilledEvent && !value) return;

      if (cb) {
        cb?.(ev);
      }

      const payload = withInput ? { [fieldName]: value, ...data } : { ...data };

      trackSegmentEvent(eventName, payload);
    };
  };

  return {
    trackPage,
    trackSegmentEvent,
    trackIdentify,
    trackAlias,
    trackGroup,
    segmentEventHandler,
  };
};
