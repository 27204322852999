import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, Title } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import CheckboxItem from 'pages/AgreementReview/CheckboxItem';

export const UserRoleConfirmationModal = ({ show, hideModal, handleContinue }) => {
  const [checkboxValue, setCheckboxValue] = useState(false);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContent>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>Owner Information</Title>
            <Icon src={'close'} onClick={hideModal} />
          </ModalHeader>
          <ModalText>
            This application will also require <b>the business owner's information</b>. Before continuing, make sure you
            have their personal information available in addition to your own.
          </ModalText>
          <Gap size="24px" />
          <CheckboxContainer>
            <CheckboxItem
              onChange={setCheckboxValue}
              checked={checkboxValue}
              label="I have my information and the business owner’s information ready"
            />
          </CheckboxContainer>
          <Gap size="24px" />
          <ButtonContainer>
            <Button onClick={handleContinue} disabled={!checkboxValue}>
              Continue
            </Button>
          </ButtonContainer>
        </ModalContent>
      </Slide>
    </Backdrop>
  );
};

const ModalContent = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 30px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 320px;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModalText = styled.span`
  color: #0e202f;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
