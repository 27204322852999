import { useEffect } from 'react';
import styled from 'styled-components';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import useStore from 'lib/hooks/useStore';

const ApplicationStatus = () => {
  const { setPageTitle } = useStore();

  useEffect(() => {
    setPageTitle('Application Status');
  }, [setPageTitle]);

  return (
    <Container>
      <ContentContainer>
        <Icon src="decline-fail" />
        <Title m={'10px 0px'}>Unfortunately, we could not approve your application</Title>
        <Subtitle m={'0px 0px 24px'}>
          Thank you for taking the time to apply. After reviewing your application, we have determined that Cherry is
          not a good business fit at this time.
        </Subtitle>
        <SemiTitle>What happens next?</SemiTitle>
        <Subtitle m={'8px 0px'}>
          We are constantly changing and expanding how we do business, so feel free to apply again at a later date.
        </Subtitle>
        <Subtitle m={'20px 0px 50px'}>
          If you have questions about our decision, please reach out to your sales representative for additional
          information.
        </Subtitle>
      </ContentContainer>
    </Container>
  );
};

export default ApplicationStatus;

const SemiTitle = styled.span`
  color: #56636d;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;
