import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { InitiateApplicationLeadResponse } from 'lib/constants';

import { INITIATE_APPLICATION_LEAD } from 'lib/graphql/mutations';
import { useState } from 'react';

export const useInitiateApplicationLead = (applicationLeadId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<InitiateApplicationLeadResponse | null>(null);

  const [initiateApplicationLead] = useMutation(INITIATE_APPLICATION_LEAD, {
    variables: {
      input: {
        applicationLeadId,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data?.initiateApplicationLead);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException('useInitiateApplicationLead', {
        level: 'error',
        extra: {
          errorMessage: 'INITIATE_APPLICATION_LEAD ERROR',
          page: 'useInitiateApplicationLead',
          error,
        },
      });
    },
  });

  return { initiateApplicationLead, data, loading, setLoading };
};
