import Collapse from '@mui/material/Collapse';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components';

interface Props {
  show: boolean;
  text: string;
  onHide: () => void;
}

export const Snackbar = ({ show, text, onHide }: Props) => {
  const [visible, setVisible] = useState<any>(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(show);
    }, 100);
  }, [show]);

  if (visible) {
    return (
      <Collapse in={show}>
        <Container onClick={onHide}>
          <Icon src="warning" />
          <Text>{text}</Text>
        </Container>
      </Collapse>
    );
  }
  return null;
};

const Container = styled.div`
  background-color: #e6cb72;
  padding: 8px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
