import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, SsnInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';
import { ssnPattern } from 'lib/utils/Validators';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  ssn: string;
}

interface Requirement {
  id: number;
  reason: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

const AddOwnerSSN = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setSessionData, sessionData, setPageTitle } = useStore();
  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;
  const { requirements = [] }: { requirements?: Requirement[] } = sessionData;

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const onSubmit = async (data: State) => {
    try {
      setSessionData(data);
      const { ssn } = data;
      const requirement = requirements.find((req) => req?.reason === 'R_OwnerSSN');

      const submittedInformation = ssn;

      const res = await submitApplicationLeadRequirement({
        submittedInformation,
        requirementId: requirement?.id,
        applicationLeadId: sessionData?.id,
      });

      if (res) {
        navigate('/provide-more-info');
      }
    } catch (e) {
      alert.info('Update failed, please try again later.');
    }
  };

  return (
    <Container backUrl="/provide-more-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src="SSN" />
          <Title m={'10px 0px 8px 0px'}>Owner's Social Security Number</Title>
          <Subtitle m={'10px 0px 24px 0px'}>
            We were unable to verify the owner’s identity with the information provided. Typically this is the result of
            uploading the wrong phone number for the Owner. If you have uploaded the wrong phone number, please reach
            out to your account executive to update the phone number on file. Otherwise, please provide the Owner’s
            Social Security Number.
          </Subtitle>
          <Box>
            <Controller
              name="ssn"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: ssnPattern, minLength: 11, maxLength: 11 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="ssn"
                  placeholder="111-22-3333"
                  inputProps={{ maxLength: 11 }}
                  InputProps={{
                    inputComponent: SsnInput,
                    disableUnderline: true,
                  }}
                  variant="filled"
                  data-testid="ssn"
                  label="Owner's Social Security Number"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.type === 'maxLength' ? 'Max length exceed' : null}
                />
              )}
            />
          </Box>
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddOwnerSSN;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
