import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, DidntGetCodeModal, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';

import { PATCH_APPLICATION_LEAD_USER } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import StorageService from 'lib/services/Storage';
import { USER_ROLE } from 'pages/UserRole/UserRole';

const inputStyle = {
  width: '54px',
  height: '90px',
  fontSize: '20px',
  borderRadius: '4px',
  border: '1px solid #56636D',
};

const containerStyle = {
  width: '100%',
  maxWidth: '400px',
  justifyContent: 'space-between',
};

const focusStyle = {
  outline: 0,
};

enum OtpReceiveChoiceEnum {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

const Otp = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setPageTitle, userData, sessionData } = useStore();
  const { trackPage, trackSegmentEvent, trackIdentify } = useSegment();
  const userDetail = StorageService.getUserData();

  const [patchUser, { loading }] = useMutation(PATCH_APPLICATION_LEAD_USER);

  const [otp, setOtp] = useState<any>('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OTP_PAGE_LOAD);
  }, []);

  useEffect(() => {
    setPageIndex(3);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Personal Info');
    trackPage('otp');
  }, [setPageTitle]);

  const onSubmit = async () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OTP_CONTINUE_CLICKED);
    const {
      data: { patchApplicationLeadUser },
    } = await patchUser({ variables: { input: { otp, phone: userData.phone } } });
    if (patchApplicationLeadUser.id) {
      trackIdentify({
        id: userDetail?.user?.id || '',
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userDetail?.user?.email || '',
        phone: userData.phone,
      });

      if (sessionData?.role === USER_ROLE.OWNER) {
        navigate('/business-info');
      } else {
        navigate('/owner-info');
      }
    } else {
      alert.info('Verification code does not match, try again.');
    }
  };

  const handleChange = (value) => {
    setOtp(value);
  };

  const closeModal = () => setShow(false);
  const openModal = () => setShow(true);

  const resendOtp = async () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_OTP_SEND_CODE_CLICKED);
    const {
      data: { patchApplicationLeadUser },
    } = await patchUser({ variables: { input: { tempPhone: userData.phone } } });
    if (patchApplicationLeadUser.success) {
      alert.success('Verification code has been sent!');
    }
  };

  const callMeWithCode = async () => {
    const {
      data: { patchApplicationLeadUser },
    } = await patchUser({
      variables: { input: { tempPhone: userData.phone, otpReceiveChoice: OtpReceiveChoiceEnum.VOICE } },
    });
    if (patchApplicationLeadUser.success) {
      alert.success('Calling you with code!');
    }
  };

  return (
    <Container backUrl={'/personal-info'}>
      <ContentContainer>
        <Icon src={'message_writing'} />
        <Title m={'10px 0px 8px 0px'}>We just texted you</Title>
        <Subtitle m={'0px 0px 40px 0px'}>
          Enter the verification code we sent to <b>{FormatPhoneNumber(userData.phone)}</b>
        </Subtitle>
        <OtpBox>
          <StyledOtpInput
            isInputNum={true}
            inputStyle={inputStyle}
            containerStyle={containerStyle}
            focusStyle={focusStyle}
            data-testid="otp"
            shouldAutoFocus={true}
            value={otp}
            onChange={handleChange}
            placeholder={'####'}
            numInputs={4}
          />
        </OtpBox>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={otp.length !== 4 || loading} loading={loading} onClick={onSubmit}>
          Continue
        </Button>
        <Button secondary={true} onClick={openModal}>
          Didn’t Get a Code?
        </Button>
      </ButtonContainer>
      <DidntGetCodeModal show={show} hideModal={closeModal} reSendCode={resendOtp} callMeWithCode={callMeWithCode} />
    </Container>
  );
};

export default Otp;

const OtpBox = styled.form`
  display: flex;
  justify-content: center;
`;

const StyledOtpInput = styled(OtpInput)`
  input {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #dadada;
    }
    :-ms-input-placeholder {
      color: #dadada;
    }
  }
`;
