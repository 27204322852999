import styled from 'styled-components';

export const Title = styled.span<{ m?: any; p?: any }>`
  font-family: 'Open Sans';
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
`;
