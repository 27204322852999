import { ReactNode, useEffect, useState } from 'react';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import styled from 'styled-components';

interface StatusContentProps {
  children?: ReactNode;
  icon?: string;
  reference?: string;
  title: string;
}

const StatusContent = ({ children, icon = 'file_find', reference, title }: StatusContentProps) => {
  return (
    <>
      <Icon src={icon} />
      <Title m={'10px 0px'}>{title}</Title>
      <Subtitle m={'10px 0px 20px'}>{children}</Subtitle>
      {reference && (
        <InfoText>
          <h4>Your Account Executive</h4>
          <p>{reference}</p>
        </InfoText>
      )}
    </>
  );
};

const ApplicationStatus = () => {
  const { setPageIndex, setPageTitle, selectedApplicationLead, sessionData } = useStore();
  const [reference, setReference] = useState<string>();
  const { trackPage } = useSegment();

  const isArchived = selectedApplicationLead?.status === 'ARCHIVED';
  const isOnHold =
    selectedApplicationLead?.status?.startsWith('HOLD') && selectedApplicationLead?.status !== 'HOLD_FIRST_LOOK';
  const isInReview = selectedApplicationLead?.status === 'SUBMITTED' || (!isArchived && !isOnHold);

  useEffect(() => {
    setPageIndex(12);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Application Status');
    trackPage('applicationStatus');
  }, [setPageTitle]);

  useEffect(() => {
    if (selectedApplicationLead?.reference?.source === 'in_sales') {
      setReference(selectedApplicationLead?.reference?.ref);
    }
  }, [selectedApplicationLead]);

  return (
    <Container>
      <ContentContainer>
        {isInReview && (
          <StatusContent icon="file_find" title="Your application is in review.">
            <p>
              We’re still reviewing and processing your application. Expect an update in <b>1 to 3 business days</b>{' '}
              from your original submission date.
            </p>
            <p>
              You can return to check the status at any time. We’ll notify you when your status changes, or if we need
              any additional information.
            </p>
          </StatusContent>
        )}

        {isArchived && (
          <StatusContent icon="decline-fail" title="Your application has expired." reference={reference}>
            <p>
              {reference
                ? 'Please reach out to your Cherry Account Executive to renew your application. Alternatively, you can start a new application.'
                : 'Your application has expired. Please reach out to our support team at support@withcherry.com to renew your application.'}
            </p>
          </StatusContent>
        )}

        {isOnHold && (
          <StatusContent icon="decline-fail" title="Cherry Registration on Hold" reference={reference}>
            <p>Dear {sessionData?.pocFirstName || 'applicant'},</p>
            <p>
              Thank you for taking the time to submit a registration with us here at Cherry. We unfortunately cannot
              move forward with your registration at this time due to one of the following reasons:
              <ul>
                <li>A majority of the services you offer are outside of our covered services.</li>
                <li>Your business is located outside of the US or within a US territory.</li>
              </ul>
              We are constantly covering new services and geographies. We will reach out to you directly when our
              covered services or geographies change.
              {reference
                ? 'Please contact your Account Executive listed below with any questions or concerns. '
                : 'Please reach out to our support team at support@withcherry.com with any questions or concerns.'}
            </p>
          </StatusContent>
        )}
      </ContentContainer>
    </Container>
  );
};

export default ApplicationStatus;

const InfoText = styled.div`
  padding: 1rem;
  background-color: #f2f4f5;
  border-radius: 0.5rem;

  h4 {
    margin: 0;
    margin-top: 0.5rem;
  }

  p {
    color: #0e202f;
    font-size: 14px;
  }
`;
