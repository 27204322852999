import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';
import { websitePattern } from 'lib/utils/Validators';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  website: string;
}

interface Requirement {
  id: number;
  reason: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

const AddUpdatedWebsite = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setSessionData, sessionData, setPageTitle } = useStore();
  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;
  const { requirements = [] }: { requirements?: Requirement[] } = sessionData;

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const onSubmit = async (data: State) => {
    try {
      setSessionData(data);
      const { website } = data;
      const requirement = requirements.find((req) => req?.reason === 'R_UpdatedWebsite');

      let submittedInformation = website;

      if (!website.includes('https://') && !website.includes('http://')) {
        submittedInformation = 'https://' + website;
      }

      const res = await submitApplicationLeadRequirement({
        submittedInformation,
        requirementId: requirement?.id,
        applicationLeadId: sessionData?.id,
      });

      if (res) {
        navigate('/provide-more-info');
      }
    } catch (e) {
      alert.info('Update failed, please try again later.');
    }
  };

  return (
    <Container backUrl="/provide-more-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'location-pin'} />
          <Title m={'10px 0px 8px 0px'}>Updated website</Title>
          <Subtitle m={'10px 0px 24px 0px'}>
            Please link your completed website showing the services you offer.
          </Subtitle>
          <Box>
            <Controller
              name="website"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: websitePattern, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="website"
                  variant="filled"
                  data-testid="website"
                  label="Business Website"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.type === 'maxLength' ? 'Max length exceed' : null}
                />
              )}
            />
          </Box>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>Social media business pages are acceptable.</InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddUpdatedWebsite;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
