import { gql } from '@apollo/client/core';

export const SUBMIT_APPLICATION_LEAD_REQUIREMENT = gql`
  mutation applicationLeadRequirementsSubmit($input: ApplicationLeadRequirementSubmit) {
    applicationLeadRequirementsSubmit(input: $input) {
      success
      code
      message
    }
  }
`;

export const UPLOAD_APPLICATION_LEAD_REQUIREMENT_FILE = gql`
  mutation uploadApplicationLeadRequirementFile($input: UploadApplicationLeadRequirementFileRequest) {
    uploadApplicationLeadRequirementFile(input: $input) {
      success
      code
      message
    }
  }
`;

export const INITIATE_APPLICATION_LEAD = gql`
  mutation initiateApplicationLead($input: ApplicationLeadInitiate) {
    initiateApplicationLead(input: $input) {
      applicationLeadPromptReasons {
        id
        applicationLeadId
        reason
        status
      }
      status
    }
  }
`;

export const COMPLETE_APPLICATION_LEAD = gql`
  mutation completeApplicationLead($input: ApplicationLeadComplete) {
    completeApplicationLead(input: $input) {
      success
      code
      message
    }
  }
`;

export const UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS = gql`
  mutation updateApplicationLeadPromptReasonStatus($input: ApplicationLeadPromptReasonStatusUpdate) {
    updateApplicationLeadPromptReasonStatus(input: $input) {
      success
      code
      message
    }
  }
`;
