import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { API_URL } from 'config';
import { Button, Container, Icon, LoadingWithSteps, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { CONFIRM_APPLICATION_LEADS, UPDATE_APPLICATION_LEADS } from 'lib/graphql/mutations';
import { useApplicationLeadPolling } from 'lib/hooks/useApplicationLeadPolling';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

import { ApplicationLeadPromptStatus, DENTAL_INDUSTRIES, MONTH_60_TERM_INDUSTRIES, RETAIL_STATES } from 'lib/constants';
import { USER_ROLE } from 'pages/UserRole/UserRole';
import CheckboxItem from './CheckboxItem';
import { useInitiateApplicationLead } from 'lib/hooks/useInitiateApplicationLead';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';
import { useCompleteApplicationLead } from 'lib/hooks/useCompleteApplicationLead';
import { InitiateApplicationLeadLoading } from './InitiateApplicationLeadLoading';

const AGREEMENT_FETCH_ERROR = 'Agreement fetch error';

const AgreementReview = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { token } = StorageService.getAuthData();
  const { trackSegmentEvent, trackPage } = useSegment();
  const { pollApplicationLeadStatus, pollingLoading, setPollingLoading } = useApplicationLeadPolling();
  const {
    initiateApplicationLead,
    loading: initiateApplicationLeadLoading,
    setLoading: setInitiateApplicationLeadLoading,
  } = useInitiateApplicationLead(sessionData?.id);
  const {
    completeApplicationLead,
    loading: completeApplicationLeadLoading,
    setLoading: setCompleteApplicationLeadLoading,
  } = useCompleteApplicationLead(sessionData?.id);
  const {
    setPromptReasonListInSessionData,
    getRouteNameFromPromptReasonList,
    holdInformationRequestedActive,
    clearPromptReasonListInSessionData,
  } = useApplicationLeadPromptReason();

  const buttonRef: any = useRef(null);
  const agreementRef: any = useRef(null);
  const retailAgreementRef: any = useRef(null);

  const [submitApplicationLead, { loading: submitApplicationLeadLoading }] = useMutation(CONFIRM_APPLICATION_LEADS);
  const [updateApplication] = useMutation(UPDATE_APPLICATION_LEADS);

  const [agreementData, setAgreementData] = useState('');
  const [retailAgreementData, setRetailAgreementData] = useState('');
  const [agreementHeight, setAgreementHeight] = useState(100);
  const [checkboxValues, setCheckboxValues] = useState({
    eSign: false,
    sellerSales: false,
    retailSales: false,
    disclosures: false,
  });
  const [isProactiveFlow, setIsProactiveFlow] = useState(false);

  const isDental = DENTAL_INDUSTRIES.includes(sessionData.industry);
  const loading = initiateApplicationLeadLoading || completeApplicationLeadLoading;

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_AGREEMENT_REVIEW_PAGE_LOAD);
    getAgreementData();
    if (RETAIL_STATES.includes(sessionData.state)) {
      getRetailAgreementData();
    }
  }, []);

  useEffect(() => {
    trackPage('agreementReview');
    setPageIndex(13);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Agreement');
  }, [setPageTitle]);

  useEffect(() => {
    if (agreementData) {
      setAgreementHeight(agreementRef?.current?.clientHeight);
    }
  }, [agreementData]);

  useEffect(() => {
    if (retailAgreementData) {
      setAgreementHeight(retailAgreementRef?.current?.clientHeight);
    }
  }, [retailAgreementData]);

  const getAgreementData = async () => {
    try {
      const response = await axios.get(`${API_URL}/v1/application-leads/${sessionData.id}/agreement`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setAgreementData(response.data);
    } catch (err) {
      setAgreementData(AGREEMENT_FETCH_ERROR);
    }
  };

  const getRetailAgreementData = async () => {
    try {
      const response = await axios.get(`${API_URL}/v1/application-leads/${sessionData.id}/agreements/ca`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setRetailAgreementData(response.data);
    } catch (err) {
      setRetailAgreementData(AGREEMENT_FETCH_ERROR);
    }
  };

  const setRetailAgreementSign = async () => {
    const {
      id,
      name,
      dba,
      industry,
      phone,
      website,
      street,
      unit,
      city,
      state,
      zip,
      role,
      ownerUserId,
      establishedAt,
      financingOptions,
    } = sessionData;
    const { salesforceAccountId, salesforceOwnerId } = StorageService.getSfInfo();
    const params = {
      name,
      dba,
      industry,
      phone,
      website,
      financingOptions,
      address: {
        street,
        unit,
        city,
        state,
        zip,
      },
      establishedAt,
      role,
      ownerUserId,
      marketingChannels: ['Other'],
      salesforceAccountId,
      salesforceOwnerId,
      signedRetailInstallmentAgreement: true,
    };

    try {
      const { data } = await updateApplication({
        variables: { input: { ...params, id } },
      });
      if (!data.updateApplicationLead?.id) {
        Sentry.captureException('Set Retail Agreement Sign Error', {
          level: 'error',
          extra: {
            error: data.updateApplicationLead,
          },
        });
      }
    } catch (err) {
      Sentry.captureException('Set Retail Agreement Sign Error', {
        level: 'error',
        extra: {
          error: err,
        },
      });
    }
  };

  const startPollingApplicationLeadStatus = async () => {
    const {
      sessionData: { id },
    } = useStore.getState();

    setPollingLoading(true);
    await pollApplicationLeadStatus(id);
  };

  const handleCompleteApplicationLead = async () => {
    setIsProactiveFlow(true);
    setCompleteApplicationLeadLoading(true);
    const completeApplicationLeadResponse = await completeApplicationLead();
    setCompleteApplicationLeadLoading(false);

    if (completeApplicationLeadResponse?.data?.completeApplicationLead?.success) {
      startPollingApplicationLeadStatus();
      clearPromptReasonListInSessionData();
    }
  };

  const handleNavigateBackForReasons = (reasonList) => {
    const routeName = getRouteNameFromPromptReasonList(reasonList);
    navigate(routeName);
  };

  const handleApplicationLeadPromptReasonList = (applicationLeadPromptReasons) => {
    const reasonList = applicationLeadPromptReasons?.filter(
      ({ status }) => status === ApplicationLeadPromptStatus.Prompted,
    );
    setPromptReasonListInSessionData(reasonList);

    if (reasonList?.length) {
      handleNavigateBackForReasons(reasonList);
    }
  };

  const processInitiateApplicationLeadFlow = async () => {
    setInitiateApplicationLeadLoading(true);

    const initiateApplicationLeadResponse = await initiateApplicationLead();
    const { applicationLeadPromptReasons, status } = initiateApplicationLeadResponse?.data?.initiateApplicationLead;

    if (status === 'DENIED') {
      navigate('/application-declined');
    } else if (applicationLeadPromptReasons?.length === 0) {
      await handleCompleteApplicationLead();
    } else {
      handleApplicationLeadPromptReasonList(applicationLeadPromptReasons);
    }
  };

  const processExistingSubmitApplicationLeadFlow = async () => {
    const { sessionData: { industry = '', id } = {} } = useStore.getState();

    const {
      data: { confirmApplicationLead },
    } = await submitApplicationLead({ variables: { input: { applicationLeadId: id } } });
    if (confirmApplicationLead.id) {
      if (MONTH_60_TERM_INDUSTRIES.includes(industry)) {
        setPollingLoading(true);
        await pollApplicationLeadStatus(id);
      } else {
        navigate('/result');
      }
    } else {
      setPollingLoading(false);
      alert.info('An error occurred. Please try again later.');
    }
  };

  const onSubmit = async () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_AGREEMENT_REVIEW_AGREE_AND_CONFIRM_CLICKED);
    try {
      if (RETAIL_STATES.includes(sessionData.state)) {
        await setRetailAgreementSign();
      }

      if (!holdInformationRequestedActive) {
        await processInitiateApplicationLeadFlow();
      } else {
        await handleCompleteApplicationLead();
      }
    } catch (err: any) {
      alert.info('An error occurred. Please try again later.');
    }
  };

  const esignText = () => {
    return (
      <span>
        I am authorized to electronically sign this document on behalf of <b>{sessionData.name}</b>
      </span>
    );
  };

  const sellerText = () => {
    return (
      <span>
        I certify that I have read and accepted the&nbsp;
        <Link href={'https://withcherry.com/merchant-purchase-agreement/'} target={'_blank'}>
          Seller Purchase Financing Loan Program Seller Participation Agreement.
        </Link>
      </span>
    );
  };

  const retailText = () => {
    return (
      <span>
        I certify that I have read and accepted the&nbsp;
        <Link href={'https://withcherry.com/merchant-retail-installment-agreement/'} target={'_blank'}>
          Retail Installment Sales Contract Purchase Agreement.
        </Link>
      </span>
    );
  };

  const disclosureText = () => {
    return (
      <span>
        I am providing ‘written instructions’ under the Fair Credit Reporting Act authorizing Cherry Inc. to obtain
        information from my personal credit profile from Experian solely for background check purposes for a merchant
        account. I further agree to the Cherry Inc.'s&nbsp;
        <Link href={'https://withcherry.com/e-sign-disclosure/'} target={'_blank'}>
          ESign Disclosures
        </Link>{' '}
        and&nbsp;
        <Link href={'https://withcherry.com/terms/'} target={'_blank'}>
          Terms & Conditions
        </Link>
        .
      </span>
    );
  };

  const isButtonDisable = () => {
    const { eSign, sellerSales, retailSales, disclosures } = checkboxValues;
    const checkboxControls = retailAgreementData
      ? [eSign, sellerSales, retailSales, disclosures]
      : [eSign, sellerSales, disclosures];
    return (
      checkboxControls.includes(false) ||
      loading ||
      submitApplicationLeadLoading ||
      agreementData === AGREEMENT_FETCH_ERROR
    );
  };

  const checkboxHandler = (value: boolean, type: string) => {
    const body = {
      ...checkboxValues,
      [type]: value,
    };
    setCheckboxValues(body);
    setSessionData(body);
  };

  const scrollToBottom = () => {
    buttonRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const getOwnerName = () => {
    let ownerName = `${sessionData.pocFirstName} ${sessionData.pocLastName}`;

    if (sessionData.role !== USER_ROLE.OWNER) {
      ownerName = `${sessionData.ownerFirstname} ${sessionData.ownerLastname}`;
    }

    return ownerName;
  };

  return (
    <>
      <Container type="agreement" backUrl={isDental ? '/first-look' : '/pricing-review'}>
        <ContentContainer>
          <Icon src="glasses-review" />
          <Title m={'16px 0px 24px'}>Please review your agreement</Title>
          <Subtitle
            m={'0px 0px 24px 0px'}
          >{`${getOwnerName()} must review and sign the agreements on this page.`}</Subtitle>
          <IframeContainer height={agreementHeight}>
            <StyledIframe
              ref={agreementRef}
              height={agreementHeight}
              dangerouslySetInnerHTML={{ __html: agreementData }}
            />
          </IframeContainer>

          {retailAgreementData ? (
            <IframeContainer height={agreementHeight}>
              <StyledIframe
                ref={retailAgreementRef}
                height={agreementHeight}
                dangerouslySetInnerHTML={{ __html: retailAgreementData }}
              />
            </IframeContainer>
          ) : null}

          <CheckboxContainer>
            <CheckboxItem
              onChange={(e) => checkboxHandler(e, 'eSign')}
              checked={checkboxValues.eSign}
              label={esignText()}
            />
            <CheckboxItem
              onChange={(e) => checkboxHandler(e, 'sellerSales')}
              checked={checkboxValues.sellerSales}
              label={sellerText()}
            />
            {retailAgreementData ? (
              <CheckboxItem
                onChange={(e) => checkboxHandler(e, 'retailSales')}
                checked={checkboxValues.retailSales}
                label={retailText()}
              />
            ) : null}
            <CheckboxItem
              onChange={(e) => checkboxHandler(e, 'disclosures')}
              checked={checkboxValues.disclosures}
              label={disclosureText()}
            />
          </CheckboxContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button
            ref={buttonRef}
            disabled={isButtonDisable()}
            loading={
              loading ||
              initiateApplicationLeadLoading ||
              completeApplicationLeadLoading ||
              submitApplicationLeadLoading
            }
            type="submit"
            onClick={onSubmit}
          >
            Agree & Confirm
          </Button>
        </ButtonContainer>
        <ScrollToBottom onClick={scrollToBottom}>
          <Icon src="chevron_duo_down" hover={true} />
        </ScrollToBottom>
      </Container>
      {pollingLoading && <LoadingWithSteps isProactiveFlow={isProactiveFlow} />}
      {(initiateApplicationLeadLoading || (completeApplicationLeadLoading && !holdInformationRequestedActive)) &&
        !pollingLoading && <InitiateApplicationLeadLoading />}
    </>
  );
};

export default AgreementReview;

const IframeContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height};
  background-color: #f2f4f5;
  padding: 16px;
  margin-bottom: 30px;
`;

const StyledIframe = styled.div<{ height: number }>`
  height: ${(props) => props.height};
  background-color: white;
  td {
    padding: 5px 0px;
    font-size: 12px;
  }
  th {
    font-size: 14px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    td {
      word-break: break-word;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Link = styled.a`
  text-decoration: underline;
  font-size: 12px;
  color: ${(props) => props.theme.main.green};
`;

const ScrollToBottom = styled.div`
  background: #00c37d;
  box-shadow: 0px 50.4px 93.8px rgba(0, 0, 0, 0.07), 0px 18.3968px 34.2385px rgba(0, 0, 0, 0.0482987),
    0px 8.93132px 16.6222px rgba(0, 0, 0, 0.0389404), 0px 4.3783px 8.1485px rgba(0, 0, 0, 0.0310596),
    0px 1.73119px 3.22193px rgba(0, 0, 0, 0.0217013);
  border-radius: 33.6px;
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 1;
  bottom: 170px;
  right: calc((100% / 2 - 270px));
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    bottom: 90px;
    right: 30px;
  }
`;
