import React from 'react';
import styled from 'styled-components';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number;
  height?: number;
  src: string;
  m?: any;
  p?: any;
  hover?: boolean;
}

export const Icon = ({ width = 30, height = 30, src, m = '', p = '', hover = false, ...rest }: Props) => {
  return <StyledIcon src={`/icon/${src}.svg`} width={width} height={height} m={m} p={p} hover={hover} {...rest} />;
};

const StyledIcon = styled.img<Props>`
  cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
`;
