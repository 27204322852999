export interface InfoarrType {
  [key: string]: string[];
}

export interface TitleType {
  [key: string]: string;
}

interface FieldNamesType {
  [key: string]: string;
}

export const infoarrOwner: InfoarrType = {
  basic: ['name', 'industry', 'phone', 'email', 'website', 'financingOptions'],
  address: ['street', 'unit', 'city', 'state', 'zip'],
  owner: ['pocFirstName', 'pocLastName', 'pocPhone'],
  banking: ['accountName', 'accountNumber', 'routingNumber', 'accountType'],
};

export const titlesOwner: TitleType = {
  basic: 'Business Information',
  address: 'Business Address',
  owner: 'Owner',
  banking: 'Banking Information',
};

export const reviewEditRoutesOther: TitleType = {
  basic: '/business-info',
  address: '/business-address',
  owner: '/owner-info',
  representative: '/personal-info',
  banking: '/banking-info',
};

export const infoarrOther: InfoarrType = {
  basic: ['name', 'industry', 'phone', 'email', 'website', 'financingOptions'],
  address: ['street', 'unit', 'city', 'state', 'zip'],
  owner: ['ownerFirstname', 'ownerLastname', 'ownerEmail', 'ownerPhone'],
  representative: ['pocFirstName', 'pocLastName', 'pocPhone'],
  banking: ['accountName', 'accountNumber', 'routingNumber', 'accountType'],
};

export const titlesOther: TitleType = {
  basic: 'Business Information',
  address: 'Business Address',
  owner: 'Owner',
  representative: 'Location Manager',
  banking: 'Banking Information',
};

export const reviewEditRoutesOwner: TitleType = {
  basic: '/business-info',
  address: '/business-address',
  owner: '/personal-info',
  banking: '/banking-info',
};

export const fieldNames: FieldNamesType = {
  name: 'Name',
  industry: 'Category',
  pocPhone: 'Phone',
  phone: 'Phone',
  email: 'Email',
  website: 'Website',
  financingOptions: 'Financing',
  street: 'Address',
  unit: 'Apt / Unit',
  city: 'City',
  state: 'State',
  zip: 'Zip Code',
  pocFirstName: 'First Name',
  pocLastName: 'Last Name',
  ownerFirstname: 'First Name',
  ownerLastname: 'Last Name',
  ownerPhone: 'Phone',
  ownerEmail: 'Email',
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  routingNumber: 'Routing Number',
  accountType: 'Account Type',
};
