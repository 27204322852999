import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { DateInput, TextField } from 'lib/components';

type OwnerDobInputProps = {
  control: Control<any>;
};

export const OwnerDobInput = ({ control }: OwnerDobInputProps) => {
  return (
    <>
      <Controller
        name="ownerDob"
        control={control}
        defaultValue=""
        rules={{ required: true, minLength: 10, maxLength: 10 }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            id="filled-basic"
            variant="filled"
            name="ownerDob"
            data-testid="ownerDob"
            type="tel"
            label="Owner's Date of Birth (MM/DD/YYYY)"
            InputProps={{
              inputComponent: DateInput as any,
              disableUnderline: true,
            }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </>
  );
};
