import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, Loading, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';

import { RESET_PASSWORD, VALIDATE_TOKEN } from 'lib/graphql/mutations';
import { GoToLoginModal } from './components/goToLoginModal';

interface State {
  password: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const alert = useAlert();
  const { setPageTitle } = useStore();
  const passwordControl = useRef({});

  const [validateToken] = useMutation(VALIDATE_TOKEN);
  const [resetPassword, { loading: resetLoading }] = useMutation(RESET_PASSWORD);

  const [showPassword, setShowPassword] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [ready, setReady] = useState(false);

  const { handleSubmit, control, watch, formState } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;
  passwordControl.current = watch('password', '');

  useEffect(() => {
    validateTokenRequest();
  }, []);

  useEffect(() => {
    setPageTitle('Reset Password');
  }, [setPageTitle]);

  const validateTokenRequest = async () => {
    if (id) {
      const res: any = await validateToken({ variables: { input: { token: id } } });
      if (res.data?.validateToken.success && res.data?.validateToken?.status !== 'EXPIRED') {
        setReady(true);
      } else {
        navigate(`/login`);
      }
    } else {
      navigate(`/login`);
    }
  };

  const onSubmit = async (data: State) => {
    const { password } = data;
    const res: any = await resetPassword({ variables: { input: { password, exchangeTokenId: id } } });
    if (res?.data?.resetPassword?.success) {
      setShowLoginModal(true);
    } else {
      alert.info(res?.data?.resetPassword?.message);
    }
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onLoginPressed = () => {
    navigate('/login');
  };

  return (
    <Container>
      <ContentContainer>
        <Icon width={30} height={30} src={'fingerprint'} />
        <Title m={'10px 0px 8px 0px'}>Please enter your new password</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          Enter your a new password. You will be using this new password to log into your account.
        </Subtitle>
        <Box>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },

              validate: (value) =>
                [/[a-z]/, /[A-Z]/, /[0-9]/].every((pattern) => pattern.test(value)) ||
                'Password does not meet minimum requirements',
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                label="Password"
                autoComplete="new-password"
                value={value}
                type={showPassword ? 'email' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={showPasswordHandler}>
                      {showPassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
          <Controller
            name="repeatPassword"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => value === passwordControl.current || 'The passwords do not match',
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                autoComplete="off"
                label="Re-enter Password"
                value={value}
                type={showPassword ? 'email' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error.message : null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={showPasswordHandler}>
                      {showPassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Box>
        <ValidateContainer>
          <ValidateTitle>Passwords must contain:</ValidateTitle>
          <ValidateTextContainer>
            <ValidateText>8 characters minimum</ValidateText>
            <ValidateText>at least 1 number</ValidateText>
            <ValidateText>at least 1 capital letter</ValidateText>
          </ValidateTextContainer>
        </ValidateContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || resetLoading}
          loading={resetLoading}
          type="submit"
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}
        >
          Reset Password
        </Button>
      </ButtonContainer>
      {!ready && (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      )}
      <GoToLoginModal show={showLoginModal} loginPressed={onLoginPressed} />
    </Container>
  );
};

export default ResetPassword;

const ValidateContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const ValidateTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
`;

const ValidateTextContainer = styled.ul`
  margin: 0px;
  padding: 0px 30px;
`;

const ValidateText = styled.li`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
`;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;
