import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Rox from 'rox-browser';
import styled from 'styled-components';

import { Loading } from 'lib/components';
import { FETCH_AUTH_INFO, GET_APPLICATION_LEADS } from 'lib/graphql/queries';
import { GET_USER_DETAIL } from 'lib/graphql/queries/User';
import { useIdleTimeout } from 'lib/hooks/useIdleTimeout';
import useStore, { Requirement } from 'lib/hooks/useStore';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { GenericSearchOperators } from 'lib/types/GenericSearch';
import { FormatDate } from 'lib/utils';

export const AuthChecker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();

  const { setSessionData, setUserData } = useStore();

  const [loading, setLoading] = useState(true);

  const { token: hasToken } = StorageService.getAuthData();
  const userData = StorageService.getUserData();
  NavigateService.initNavigate(navigate);
  useIdleTimeout({ idleTime: 45 });

  if (userData?.user?.id) {
    Rox.setCustomStringProperty('rox.distinct_id', userData.user.id);
    Rox.setCustomStringProperty('idUser', userData.user.id);
  }

  const getInitialRequests = async () => {
    if (hasToken) {
      const { token, refreshToken } = StorageService.getAuthData();
      if (!token && !refreshToken) {
        StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
      }

      const {
        data: { getApplicationLeads },
      } = await client.query({
        query: GET_APPLICATION_LEADS,
        variables: {
          input: {
            pagination: {
              limit: 20,
              offset: 0,
            },
            search: [
              {
                key: 'userId',
                value: userData?.user?.id,
                operator: GenericSearchOperators.EQUAL,
              },
            ],
          },
        },
      });

      const {
        data: { getUserAuthInfo },
      } = await client.query({ query: FETCH_AUTH_INFO });
      if (getUserAuthInfo?.user) {
        const { firstName, lastName, phone } = getUserAuthInfo?.user;
        setUserData({ firstName, lastName, phone });
        setSessionData({ pocFirstName: firstName, pocLastName: lastName, pocPhone: phone });

        (window as any).analytics.identify(getUserAuthInfo.user);
      }

      const activeApplication = getApplicationLeads?.contents?.find(
        (application) => application.userId === userData?.user?.id,
      );

      if (activeApplication) {
        const filterApplicationLeads = getApplicationLeads.contents?.filter(
          (application) => application.userId === userData?.user?.id,
        );

        setSessionData({ applicationLeads: filterApplicationLeads });
        setLoading(false);
        navigate('/application-selection');
        return;
      } else {
        setLoading(false);
        navigate('/preparation-list');
        return;
      }
    } else {
      StorageService.clearUserData();
      navigate('/login');
      setLoading(false);
      return;
    }
  };

  const getUpdatedApplicationLead = async () => {
    const { selectedApplicationLeadId } = useStore.getState();

    const {
      data: { getApplicationLeads },
    } = await client.query({
      query: GET_APPLICATION_LEADS,
      variables: {
        input: {
          pagination: {
            limit: 20,
            offset: 0,
          },
          search: [
            {
              key: 'userId',
              value: userData?.user?.id,
              operator: GenericSearchOperators.EQUAL,
            },
          ],
        },
      },
    });

    const activeApplication = getApplicationLeads?.contents?.find(
      (application) => application.id === selectedApplicationLeadId,
    );

    if (activeApplication) {
      const {
        id,
        name,
        dba,
        industry,
        phone,
        website,
        financingOptions,
        address = {},
        establishedAt,
        bankInfo = {},
        promoPeriod,
        role,
        ownerUserId,
        requirements,
        hasNoRefundPolicy,
        isFirstLook,
        firstLookDeclineExplanation,
      } = activeApplication;

      let ownerUserDetail;

      if (ownerUserId) {
        const {
          data: { getUserDetail },
        } = await client.query({
          query: GET_USER_DETAIL,
          variables: {
            input: {
              userId: ownerUserId,
            },
          },
        });

        if (!getUserDetail.error) {
          ownerUserDetail = getUserDetail.user;
        }
      }

      const notDeletedRequirements = requirements?.filter((req: Requirement) => req.status !== 'DELETED');

      const params = {
        id,
        name,
        dba,
        industry,
        phone,
        website,
        financingOptions,
        establishedAt: FormatDate(establishedAt),
        promoPeriod,
        role,
        ownerUserId,
        ownerFirstname: ownerUserDetail?.firstName,
        ownerLastname: ownerUserDetail?.lastName,
        ownerEmail: ownerUserDetail?.email,
        ownerPhone: ownerUserDetail?.phone,
        requirements: notDeletedRequirements,
        hasNoRefundPolicy,
        isFirstLook,
        firstLookDeclineExplanation,
      };

      setSessionData({ ...params, ...address, ...bankInfo });
    }
  };

  useEffect(() => {
    getInitialRequests();
  }, []);

  useEffect(() => {
    if (location.pathname === '/provide-more-info') {
      getUpdatedApplicationLead();
    }
  }, [location.pathname]);

  return (
    <>
      <Container>
        <Outlet />
      </Container>
      {loading && (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      )}
    </>
  );
};

const Container = styled.div`
  height: 100vh;
`;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;
