import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { HelpMeModal } from 'lib/components';
import { PAGE_COUNT } from 'lib/constants';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore, { initialSessionData } from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

const progressTheme = createTheme({
  palette: {
    primary: {
      main: '#1ec37c',
    },
    secondary: {
      main: '#c5cae9',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

interface Props {
  pathname: string;
  pageIndex: number;
  pageTitle: string;
  backUrl: string;
}

export const Header = ({ pathname, pageIndex, pageTitle, backUrl }: Props) => {
  const navigate = useNavigate();
  const { setSessionData } = useStore();
  const { trackSegmentEvent } = useSegment();

  const [helpMe, setHelpMe] = useState(false);

  const goBackHandler = () => {
    if (backUrl) {
      return navigate(backUrl);
    }
    navigate(-1);
  };

  const getProgressValue = () => {
    if (pageIndex > 0) {
      return (100 / PAGE_COUNT) * pageIndex;
    }
    return 0;
  };

  const isBackButtonVisible =
    pathname !== '/' &&
    pathname !== '/result' &&
    pathname !== '/user-role' &&
    pathname !== '/application-status' &&
    pathname !== '/login' &&
    pathname !== '/provide-more-info' &&
    pathname !== '/submitted' &&
    pathname !== '/application-approved' &&
    pathname !== '/application-declined' &&
    !pathname.includes('/reset-password');

  const isProgressVisible =
    pathname !== '/' &&
    pathname !== '/provide-more-info' &&
    pathname !== '/add-business-photo' &&
    pathname !== '/add-void-check' &&
    pathname !== '/submitted' &&
    pathname !== '/result' &&
    pathname !== '/application-status' &&
    pathname !== '/application-approved' &&
    pathname !== '/application-declined' &&
    pathname !== '/submitted' &&
    pathname !== '/login' &&
    pathname !== '/add-other-info' &&
    pathname !== '/add-updated-website' &&
    pathname !== '/add-business-owner-information' &&
    pathname !== '/add-bank-statements' &&
    pathname !== '/add-business-registration' &&
    pathname !== '/add-credit-unlock' &&
    pathname !== '/add-address-verification' &&
    pathname !== '/zoom-walkthrough' &&
    pathname !== '/register';

  const helpMeToggle = () => {
    trackSegmentEvent(SegmentEventNames.NEED_HELP_CLICKED, { pathname });
    setHelpMe(!helpMe);
  };

  const closeHelpMe = () => {
    setHelpMe(false);
  };

  const onEmailPressed = (e) => {
    trackSegmentEvent(SegmentEventNames.EMAIL_SUPPORT_CLICKED, { pathname });
    window.location.href = 'mailto:support@withcherry.com';
    e.preventDefault();
  };

  const onCallPressed = (e) => {
    trackSegmentEvent(SegmentEventNames.CALL_SUPPORT_CLICKED, { pathname });
    window.open('tel:8888397171', '_self');
    e.preventDefault();
  };

  const logoutPressed = () => {
    trackSegmentEvent(SegmentEventNames.LOGOUT_CLICKED, { pathname });
    StorageService.clearUserData();
    setSessionData(initialSessionData);
    navigate('/login');
  };

  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <IconContainer>
            {isBackButtonVisible && (
              <IconButton aria-label="back" color="inherit" onClick={goBackHandler}>
                <BackIcon src={'/icon/short_left.svg'} />
              </IconButton>
            )}
          </IconContainer>
          <HeaderTitle>{pageTitle}</HeaderTitle>
          <IconContainer onClick={helpMeToggle}>
            <HelpIcon src={'/icon/help_circle.svg'} />
          </IconContainer>
        </HeaderContainer>
        <HelpMeModal
          show={helpMe}
          hideModal={closeHelpMe}
          emailPressed={onEmailPressed}
          callPressed={onCallPressed}
          logoutPressed={logoutPressed}
        />
      </InnerContainer>
      <ProgressContainer>
        {isProgressVisible && (
          <ThemeProvider theme={progressTheme}>
            <ProgressInnerContainer>
              <LinearProgress variant="determinate" value={getProgressValue()} />
            </ProgressInnerContainer>
          </ThemeProvider>
        )}
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  z-index: 100;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.main.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: inset 0px -1px 0px #dadada;
  width: 100%;
  height: 80px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: 50px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 48px));
`;

const ProgressContainer = styled.div``;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0e202f;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  width: 25px;
  justify-content: center;
  align-items: center;
`;

const ProgressInnerContainer = styled.div`
  width: 100%;
`;

const HelpIcon = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 25px;
    height: 25px;
  }
`;

const BackIcon = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    width: 23px;
    height: 23px;
  }
`;
