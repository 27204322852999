import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const EINNumberInput = React.forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const onAcceptHandler = (value: string) => {
    onChange({ target: { name: props.name, value } });
  };

  const { onChange, ...other } = props;
  return <IMaskInput {...other} mask="00-0000000" inputRef={ref} onAccept={onAcceptHandler} unmask={true} />;
});
