import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import {
  Account,
  AddAddressVerification,
  AddApplicationReview,
  AddBankStatements,
  AddBusinessOwnerInfo,
  AddBusinessPhoto,
  AddBusinessRegistration,
  AddCreditUnlock,
  AddDentalLicense,
  AddListOfServices,
  AddMedicalLicense,
  AddOtherInfo,
  AddOwnerEmail,
  AddOwnerSSN,
  AddPriceList,
  AddUpdatedWebsite,
  AddVoidCheck,
  AgreementReview,
  ApplicationApproved,
  ApplicationDenied,
  ApplicationStatus,
  AuthChecker,
  BankingInfo,
  BusinessAdditionalInfo,
  BusinessAddress,
  BusinessInfo,
  BusinessOwner,
  FinancialServices,
  FirstLook,
  ForgotPassword,
  Login,
  MultipleApplicationSelection,
  Otp,
  OwnerInfo,
  PersonalInfo,
  PreparationList,
  PricingReview,
  ProvideMoreInfo,
  Register,
  ResetPassword,
  Result,
  Review,
  Submitted,
  UserRole,
  ZoomWalkthrough,
} from './pages';

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { token } = StorageService.getAuthData();

  useEffect(() => {
    if (token) {
      if (
        location.pathname === '/login' ||
        location.pathname === '/register' ||
        location.pathname === '/reset-password' ||
        location.pathname === '/forgot-password'
      ) {
        navigate('/user-role');
      }
    }
    NavigateService.initNavigate(navigate);
  }, []);

  return (
    <Container>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route path="/" element={<AuthChecker />}>
          <Route path="/application-selection" element={<MultipleApplicationSelection />} />
          <Route path="/preparation-list" element={<PreparationList />} />
          <Route path="/user-role" element={<UserRole />} />
          <Route path="/owner-info" element={<OwnerInfo />} />
          <Route path="/personal-info" element={<PersonalInfo />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/business-info" element={<BusinessInfo />} />
          <Route path="/business-additional-info" element={<BusinessAdditionalInfo />} />
          <Route path="/business-address" element={<BusinessAddress />} />
          <Route path="/financial-services" element={<FinancialServices />} />
          <Route path="/business-owner" element={<BusinessOwner />} />
          <Route path="/banking-info" element={<BankingInfo />} />
          <Route path="/account" element={<Account />} />
          <Route path="/review" element={<Review />} />
          <Route path="/pricing-review" element={<PricingReview />} />
          <Route path="/first-look" element={<FirstLook />} />
          <Route path="/agreement-review" element={<AgreementReview />} />
          <Route path="/application-status" element={<ApplicationStatus />} />
          <Route path="/result" element={<Result />} />
          <Route path="/application-declined" element={<ApplicationDenied />} />
          <Route path="/application-approved" element={<ApplicationApproved />} />

          {/* Post Register */}
          <Route path="/provide-more-info" element={<ProvideMoreInfo />} />
          <Route path="/zoom-walkthrough" element={<ZoomWalkthrough />} />
          <Route path="/add-address-verification" element={<AddAddressVerification />} />
          <Route path="/add-updated-website" element={<AddUpdatedWebsite />} />
          <Route path="/add-other-info" element={<AddOtherInfo />} />
          <Route path="/add-credit-unlock" element={<AddCreditUnlock />} />
          <Route path="/add-business-owner-info" element={<AddBusinessOwnerInfo />} />
          <Route path="/add-business-registration" element={<AddBusinessRegistration />} />
          <Route path="/add-bank-statements" element={<AddBankStatements />} />
          <Route path="/add-business-photo" element={<AddBusinessPhoto />} />
          <Route path="/add-void-check" element={<AddVoidCheck />} />
          <Route path="/add-medical-license" element={<AddMedicalLicense />} />
          <Route path="/add-dental-license" element={<AddDentalLicense />} />
          <Route path="/add-owner-ssn" element={<AddOwnerSSN />} />
          <Route path="/add-owner-email" element={<AddOwnerEmail />} />
          <Route path="/add-application-review" element={<AddApplicationReview />} />
          <Route path="/add-list-of-services" element={<AddListOfServices />} />
          <Route path="/add-price-list" element={<AddPriceList />} />
          <Route path="/submitted" element={<Submitted />} />
          {/* Post Register */}
        </Route>
      </Routes>
    </Container>
  );
};

export default Router;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
