import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, TextField } from 'lib/components';
import useStore from 'lib/hooks/useStore';

interface State {
  pocEmail: string;
  password: string;
}

const Account = () => {
  const password = useRef({});
  const navigate = useNavigate();
  const { setPageIndex, setSessionData, sessionData } = useStore();
  const { handleSubmit, control, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;
  password.current = watch('password', '');

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  const onSubmit = (data: State) => {
    setSessionData(data);
    navigate('/review');
  };

  return (
    <Container>
      <Typography variant="h5" mb={6}>
        Let’s set up you with an account
      </Typography>
      <Box>
        {/* <Controller
          name="pocEmail"
          control={control}
          defaultValue=""
          rules={{ required: true, pattern: emailPattern }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="filled-basic"
              variant="filled"
              data-testid="pocEmail"
              label="Email Address"
              type="email"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        /> */}
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'You must specify a password',
            minLength: {
              value: 6,
              message: 'Password must have at least 6 characters',
            },
          }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="filled-basic"
              variant="filled"
              data-testid="password"
              label="Password"
              type="password"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Controller
          name="repeatPassword"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) => value === password.current || 'The passwords do not match',
          }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="filled-basic"
              variant="filled"
              data-testid="repeatPassword"
              label="Re-enter Password"
              type="password"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
      </Box>
      <ButtonContainer>
        {/*// @ts-ignore*/}
        <Button disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)}>
          Review
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Account;

const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
