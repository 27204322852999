import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';
import { emailPattern } from 'lib/utils/Validators';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  email: string;
}

interface Requirement {
  id: number;
  reason: string;
  createdAt: string;
  updatedAt: string;
  status: string;
}

const AddOwnerEmail = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setSessionData, sessionData, setPageTitle } = useStore();
  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;
  const { requirements = [] }: { requirements?: Requirement[] } = sessionData;

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const onSubmit = async (data: State) => {
    try {
      setSessionData(data);
      const { email } = data;
      const requirement = requirements.find((req) => req?.reason === 'R_OwnerEmail');

      const submittedInformation = email;

      const res = await submitApplicationLeadRequirement({
        submittedInformation,
        requirementId: requirement?.id,
        applicationLeadId: sessionData?.id,
      });

      if (res) {
        navigate('/provide-more-info');
      }
    } catch (e) {
      alert.info('Update failed, please try again later.');
    }
  };

  return (
    <Container backUrl="/provide-more-info">
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src="email" />
          <Title m={'10px 0px 8px 0px'}>Owner's Email</Title>
          <Subtitle m={'10px 0px 24px 0px'}>
            The email listed on the registration seems to belong to someone other than the Owner or seems to be a
            general staff email. For security purposes, please provide the Owner’s direct email. Once approved, you may
            create additional logins for the rest of your staff as needed.
          </Subtitle>
          <Box>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: emailPattern, minLength: 2, maxLength: 255 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="email"
                  inputProps={{ maxLength: 255 }}
                  variant="filled"
                  data-testid="email"
                  label="Owner's Email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.type === 'maxLength' ? 'Max length exceed' : null}
                />
              )}
            />
          </Box>
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddOwnerEmail;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
