import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import { confettiColors } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import Confetti from 'react-confetti';

const ApplicationStatus = () => {
  const { setPageTitle, trainingLink } = useStore();

  const [confettiActive, setConfettiActive] = useState(false);
  const containerRef: any = useRef(null);

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 5000);
  }, []);

  useEffect(() => {
    setPageTitle('Application Status');
  }, [setPageTitle]);

  const onScheduleTrainingClicked = () => {
    trainingLink && window.open(trainingLink, '_blank');
  };

  return (
    <Container>
      <ContentContainer ref={containerRef} noMargin={true} justify="space-between">
        <InnerContainer>
          <Icon src="party" />
          <Title m={'10px 0px'}>Your application is approved!</Title>
          <Subtitle m={'0px 0px 20px'}>
            Our mission is to help you treat more patients. We can’t wait to help you get started with Cherry!
          </Subtitle>
          <InnerTitle m={'10px 0px'}>What happens next?</InnerTitle>
          {trainingLink ? (
            <>
              <Subtitle m={'0px 0px 50px'}>Register for training so patients can start paying with Cherry:</Subtitle>
              <Button onClick={onScheduleTrainingClicked}>Register for Training</Button>
            </>
          ) : (
            <>
              <Subtitle m={'0px 0px 50px'}>
                Your Cherry representative will contact you to schedule your onboarding! Your account will remain in{' '}
                <b>demo mode</b> until you complete onboarding.
              </Subtitle>
            </>
          )}
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>You cannot accept applications while in demo mode.</InfoText>
        </InfoContainer>
        {confettiActive && (
          <ConfettiContainer>
            <Confetti
              width={containerRef.current?.clientWidth}
              height={containerRef.current?.clientHeight}
              numberOfPieces={300}
              colors={confettiColors}
              recycle={false}
            />
          </ConfettiContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default ApplicationStatus;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: #0e202f;
`;

const InnerTitle = styled(Title)`
  //styleName: Heading/h5;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #56636d;
`;
