import { PhotoUploadForm } from 'lib/components';

const AddListOfServices = () => {
  return (
    <PhotoUploadForm
      title="List of Services"
      subtitle="Cherry must verify the services our practices offer. We either could not verify your services on your website or you do not have a website. For our records, please provide a full, comprehensive list of services your business offers."
      reason="R_ListOfServices"
      exampleImgSrc="examples/service-list.png"
      icon="service-list"
    />
  );
};

export default AddListOfServices;
