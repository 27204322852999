import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Icon, RadioButtonGroup, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { trapSpacesForRequiredFields } from 'lib/utils/Validators';
import { USER_ROLE } from 'pages/UserRole/UserRole';
import styled from 'styled-components';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  website: string;
}

const BusinessInfo = () => {
  const navigate = useNavigate();
  const dbaRef = useRef({});
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { trackSegmentEvent, trackPage } = useSegment();
  const { handleSubmit, control, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;
  dbaRef.current = watch('dba', '');

  const [radioValue, setRadioValue] = useState('');

  useEffect(() => {
    if (radioValue === 'true') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_SELECTED, { value: 'Yes' });
    } else if (radioValue === 'false') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_SELECTED, { value: 'No' });
    }
  }, [radioValue]);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_PAGE_LOAD);
    setRadioValue(sessionData.hasDba);
  }, []);

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Business Info');
    trackPage('businessInfo');
  }, [setPageTitle]);

  const onSubmit = (data: State) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_CONTINUE_CLICKED);
    if (radioValue === 'false') {
      setSessionData({ ...data, dba: '', hasDba: radioValue });
    } else {
      setSessionData({ ...data, hasDba: radioValue });
    }

    navigate('/business-additional-info');
  };

  const onDBARadioChange = (e) => {
    trackSegmentEvent(SegmentEventNames.BUSINESS_INFO_RADIO_CHANGE, { value: setRadioValue(e.target.value) });
    setRadioValue(e.target.value);
  };

  const buttonDisabled = !isValid || radioValue === '' || (radioValue === 'true' && dbaRef.current === '');

  const onInputClick = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event?.target || {};

    if (name === 'name') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_LEGAL_BUSINESS_NAME_CLICKED);
    } else if (name === 'dbaName') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_NAME_CLICKED);
    }
  };

  const getBackUrl = () => (sessionData?.role === USER_ROLE.OWNER ? '/personal-info' : '/owner-info');

  return (
    <Container backUrl={getBackUrl()}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'business-store'} />
          <Title m={'10px 0px 24px 0px'}>Tell us about your business</Title>
          <Box>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  name="name"
                  data-testid="name"
                  label="Legal Business Name"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(
                      SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_LEGAL_BUSINESS_NAME_FILLED,
                      { value },
                    );
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputClick}
                />
              )}
            />
            <RadioButtonGroup
              label="Is your business operating under a different name (DBA)?"
              onChange={onDBARadioChange}
              value={radioValue}
            />
            {radioValue === 'true' && (
              <Controller
                name="dba"
                control={control}
                defaultValue=""
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    label="DBA Name"
                    id="filled-basic"
                    variant="filled"
                    name="dbaName"
                    data-testid="dba"
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_BUSINESS_INFO_DBA_NAME_ENTERED, {
                        value,
                      });
                      onBlur();
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          </Box>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>Your “Legal Business Name” should match what is written in your business registration.</InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={buttonDisabled} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default BusinessInfo;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
