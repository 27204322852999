import { useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { confettiColors } from 'lib/constants';
import useStore from 'lib/hooks/useStore';

const Submitted = () => {
  const { setPageTitle } = useStore();
  const [confettiActive, setConfettiActive] = useState(false);

  const containerRef: any = useRef(null);

  useEffect(() => {
    setConfettiActive(true);
    setTimeout(() => {
      setConfettiActive(false);
    }, 5000);
  }, []);

  useEffect(() => {
    setPageTitle('Submitted');
  }, [setPageTitle]);

  return (
    <Container>
      <ContentContainer ref={containerRef} noMargin={true}>
        <Gap />
        <Icon src={'party'} />
        <Title m={'8px 0 0 0'}>Thanks! We’re re-reviewing</Title>
        <Subtitle m={'8px 0px 6px 0px'}>
          We received your additional information and are reviewing it. We’ll be in touch as soon as possible!
        </Subtitle>
        {confettiActive && (
          <ConfettiContainer>
            <Confetti
              width={containerRef.current?.clientWidth}
              height={containerRef.current?.clientHeight}
              numberOfPieces={300}
              colors={confettiColors}
              recycle={false}
            />
          </ConfettiContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default Submitted;

const Gap = styled.img`
  margin-top: 30px;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;
