import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS } from 'lib/graphql/mutations';
import { useState } from 'react';

type updateApplicationLeadPromptReasonStatusProps = {
  applicationLeadId: string;
  promptReasonId: number;
  status: string;
};

type updateApplicationLeadPromptReasonStatusResponse = {
  success: boolean;
};

export const useUpdateApplicationLeadPromptReasonStatus = ({
  applicationLeadId,
  promptReasonId,
  status,
}: updateApplicationLeadPromptReasonStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<updateApplicationLeadPromptReasonStatusResponse | null>(null);

  const [updateApplicationLeadPromptReasonStatus] = useMutation(UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS, {
    variables: {
      input: {
        applicationLeadId,
        promptReasonId,
        status,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data?.updateApplicationLeadPromptReasonStatus);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException('useUpdateApplicationLeadPromptReasonStatus', {
        level: 'error',
        extra: {
          errorMessage: 'UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS ERROR',
          page: 'useUpdateApplicationLeadPromptReasonStatus',
          error,
        },
      });
    },
  });

  return { updateApplicationLeadPromptReasonStatus, data, loading };
};

export const useUpdateApplicationLeadPromptReasonStatusCall = () => {
  return useMutation(UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS, {
    fetchPolicy: 'network-only',

    onError: (error) => {
      Sentry.captureException('useUpdateApplicationLeadPromptReasonStatusCall', {
        level: 'error',
        extra: {
          errorMessage: 'UPDATE_APPLICATION_LEAD_PROMPT_REASON_STATUS ERROR',
          page: 'useUpdateApplicationLeadPromptReasonStatusCall',
          error,
        },
      });
    },
  });
};
