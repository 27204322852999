import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import {
  ApplicationLeadReasonPrompt,
  Button,
  Container,
  DateInput,
  EINNumberInput,
  Icon,
  InfoText,
  RadioButtonGroup,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { DENTAL_INDUSTRIES, ApplicationLeadPromptReasons, ApplicationLeadPromptStatus } from 'lib/constants';
import { CREATE_APPLICATION_LEADS, UPDATE_APPLICATION_LEADS } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

import { NoRefundModal } from 'pages/FinancialServices/NoRefundModal';
import useApplicationLeadPromptReason from 'lib/hooks/useApplicationLeadPromptReason';

interface State {
  offerFinancing: string;
  financingOptions: string;
  establishedAt: string;
  hasNoRefundPolicy: boolean;
  einNumber?: string;
  npi?: string;
}

const FinancialServices = () => {
  const navigate = useNavigate();
  const { setPageIndex, setPageTitle, setSessionData, sessionData } = useStore();
  const { trackPage, trackSegmentEvent } = useSegment();
  const alert = useAlert();
  const {
    checkUpdatePromptReasonStatusNeeded,
    createUpdatePromptReasonHandler,
    holdInformationRequestedActive,
    checkPromptReasonListHasReason,
    handleNavigateForward,
    loading: updatePromptReasonLoading,
  } = useApplicationLeadPromptReason();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: sessionData,
    mode: 'onBlur',
  });

  const isDentalIndustry = DENTAL_INDUSTRIES.includes(sessionData.industry);

  const watchOfferFinancing = watch('offerFinancing', '');
  const watchFinancingOptions = watch('financingOptions', '');
  const establishedAt = watch('establishedAt', '');
  const watchNoRefund = watch('hasNoRefundPolicy', '');
  const watchEinNumber = watch('einNumber', '');

  const [createApplication, { loading: createApplicationLoading }] = useMutation(CREATE_APPLICATION_LEADS);
  const [updateApplication, { loading: updateApplicationLoading }] = useMutation(UPDATE_APPLICATION_LEADS);
  const [displayNoRefundModal, setDisplayNoRefundModal] = useState(false);
  const [isNpiInfoReasonPromptVisible, setIsNpiInfoReasonPromptVisible] = useState(false);

  useEffect(() => {
    const isNpiReasonInPromptList =
      holdInformationRequestedActive && checkPromptReasonListHasReason(ApplicationLeadPromptReasons.Npi);

    if (isNpiReasonInPromptList) {
      setIsNpiInfoReasonPromptVisible(true);
    }
  }, [holdInformationRequestedActive]);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_PAGE_LOAD);
  }, []);

  useEffect(() => {
    trackPage('financialServices');
    if (sessionData.financingOptions) {
      return setValue('offerFinancing', 'true');
    } else if (sessionData.id) {
      setValue('offerFinancing', 'false');
    } else {
      setValue('offerFinancing', sessionData.offerFinancing || '');
    }
  }, []);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    setPageTitle('Business Info');
  }, [setPageTitle]);

  const toggleNoRefundModal = () => {
    setDisplayNoRefundModal((prevState: any) => {
      return !prevState;
    });
  };

  const isNpiInformationUpdated = (data) => {
    return data?.npi !== sessionData?.npi;
  };

  const getParamsForUpdate = (data) => {
    const { salesforceAccountId, salesforceOwnerId } = StorageService.getSfInfo();
    const { id, name, dba, industry, phone, website, street, unit, city, state, zip, role, ownerUserId } = sessionData;
    const params = {
      name,
      dba,
      industry,
      phone,
      website,
      financingOptions: data.financingOptions,
      address: {
        street,
        unit,
        city,
        state,
        zip,
      },
      establishedAt: data.establishedAt,
      role,
      ownerUserId,
      marketingChannels: ['Other'],
      salesforceAccountId,
      salesforceOwnerId,
      hasNoRefundPolicy: isDentalIndustry
        ? undefined
        : typeof data.hasNoRefundPolicy === 'boolean'
        ? data.hasNoRefundPolicy
        : data.hasNoRefundPolicy === 'true',
      ein: data.einNumber || null,
      npi: data.npi || null,
    };

    return { id, params };
  };

  const handleUpdateApplicationLead = async (id, params) => {
    const {
      data: { updateApplicationLead },
    } = await updateApplication({ variables: { input: { ...params, id } } });
    if (updateApplicationLead?.id) {
      await handleNavigateForward(ApplicationLeadPromptReasons.Npi);
    } else {
      alert.info('An error occurred. Please try again later.');
    }
  };

  const handleCreateApplicationLead = async (params) => {
    const {
      data: { createApplicationLead },
    } = await createApplication({ variables: { input: params } });
    if (createApplicationLead?.id) {
      setSessionData({ id: createApplicationLead?.id });
      await handleNavigateForward(ApplicationLeadPromptReasons.Npi);
    } else {
      alert.info('An error occurred. Please try again later.');
    }
  };

  const processUpdateFinancialServiceInformations = async (data) => {
    const { id, params } = getParamsForUpdate(data);

    if (sessionData.id) {
      await handleUpdateApplicationLead(id, params);
    } else {
      await handleCreateApplicationLead(params);
    }
  };

  const onSubmit = async (data: State) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_CONTINUE_CLICKED);

    if (checkUpdatePromptReasonStatusNeeded()) {
      await createUpdatePromptReasonHandler(isNpiInformationUpdated, ApplicationLeadPromptReasons.Npi)(data);
    }

    try {
      const { offerFinancing } = data;
      if (offerFinancing === 'false') {
        setSessionData({
          financingOptions: '',
          establishedAt: data.establishedAt,
          offerFinancing,
          hasNoRefundPolicy: Boolean(data.hasNoRefundPolicy),
          einNumber: data.einNumber,
          npi: data.npi,
        });
      } else {
        setSessionData(data);
      }
      await processUpdateFinancialServiceInformations(data);
    } catch (err) {
      alert.info('An error occurred please try again');
      trackSegmentEvent(SegmentEventNames.FINANCIAL_SERVICES_CONTINUE_CLICKED);
    }
  };

  const onRadioChange = (e, onChange) => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_FINANCING_SELECTED, {
      value: e?.target?.value,
    });
    onChange(e);
  };

  const onEinInputFocus = () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_EIN_CLICKED);
  };

  const onInputFocus = (e) => {
    const { name } = e?.target || {};

    if (name === 'alternateFinancing') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_ALTERNATE_FINANCING_CLICKED);
    } else if (name === 'establishedAt') {
      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_ESTABLISHED_DATE_CLICKED);
    }
  };

  const onEditInfo = () => {
    setIsNpiInfoReasonPromptVisible(false);
  };

  const onSkipReasonPrompt = async () => {
    await createUpdatePromptReasonHandler(isNpiInformationUpdated, ApplicationLeadPromptReasons.Npi)(
      {},
      ApplicationLeadPromptStatus.Skipped,
    );
    await handleNavigateForward(ApplicationLeadPromptReasons.Npi);
  };

  const isFormDisabled =
    establishedAt.length !== 10 ||
    watchOfferFinancing === '' ||
    createApplicationLoading ||
    updateApplicationLoading ||
    (watchOfferFinancing === 'true' && watchFinancingOptions === '') ||
    (!isDentalIndustry && watchNoRefund === '') ||
    (watchEinNumber?.length > 0 && watchEinNumber.length !== 9) ||
    updatePromptReasonLoading;

  return (
    <Container backUrl="/business-address">
      <ContentContainer>
        <Icon src={'business-store'} />
        <Title m={'10px 0px 24px 0px'}>Help us better understand your business</Title>
        <Controller
          name="offerFinancing"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <RadioButtonGroup
              label="Do you currently offer financing?"
              onChange={(e) => onRadioChange(e, onChange)}
              value={value}
            />
          )}
        />
        {watchOfferFinancing === 'true' && (
          <>
            <Label>What financing service(s) do you currently offer?</Label>
            <Controller
              name="financingOptions"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  style={{ width: '100%', marginBottom: 10 }}
                  label="Financing Service(s)"
                  id="filled-basic"
                  name="alternateFinancing"
                  variant="filled"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onFocus={onInputFocus}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_ALTERNATE_FINANCING_FILLED, {
                      value,
                    });
                    onBlur();
                  }}
                />
              )}
            />
          </>
        )}
        <Label>When was your business established?</Label>
        <Controller
          name="establishedAt"
          control={control}
          defaultValue=""
          rules={{ required: true, maxLength: 10, minLength: 10 }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="establishedAt"
              variant="filled"
              name="establishedAt"
              data-testid="establishedAt"
              type="tel"
              label="Established Date (mm/dd/yyyy)"
              InputProps={{
                inputComponent: DateInput as any,
                disableUnderline: true,
              }}
              value={value}
              onChange={onChange}
              onBlur={() => {
                trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_ESTABLISHED_DATE_ENTERED, {
                  value,
                });
                onBlur();
              }}
              error={!!error}
              helperText={error ? error.message : null}
              onFocus={onInputFocus}
            />
          )}
        />
        <Gap />
        {!isDentalIndustry && (
          <>
            <Controller
              name="hasNoRefundPolicy"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  label="Do you have a no-refund policy for undelivered treatments or services?"
                  onChange={(e) => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_REFUND_POLICY_SELECTED, {
                      value: e?.target?.value,
                    });
                    onChange(e);
                  }}
                  value={value}
                  rightTextValue={true}
                  leftTextValue={false}
                />
              )}
            />
            <Row>
              <Icon src={'info_circle_green'} width={24} height={24} />
              <UnderlinedSubtitle m={'0px 0px 0px 8px'} onClick={toggleNoRefundModal}>
                What does this mean?
              </UnderlinedSubtitle>
            </Row>
          </>
        )}
        {isDentalIndustry ? (
          <>
            <Label>
              <span>
                <b>Optional:&nbsp;</b>Do you know your NPI or EIN? One or both of these will help us process your
                application faster.
              </span>
            </Label>
            <NpiAndEinContainer>
              <Controller
                name="npi"
                control={control}
                defaultValue=""
                rules={{ required: false, maxLength: 10, minLength: 10 }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="npi"
                    variant="filled"
                    data-testid="npi"
                    type="tel"
                    label="National Provider Identifier (NPI)"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!errors?.npi}
                    helperText={errors?.npi ? 'NPI must be a 10-digit number' : null}
                  />
                )}
              />
              <Controller
                name="einNumber"
                control={control}
                defaultValue=""
                rules={{ required: false, maxLength: 9, minLength: 9 }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="einNumber"
                    variant="filled"
                    data-testid="einNumber"
                    type="tel"
                    label="Employer Identification Number (EIN)"
                    InputProps={{
                      inputComponent: EINNumberInput as any,
                      disableUnderline: true,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={() => {
                      trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_EIN_FILLED, {
                        value,
                      });
                      onBlur();
                    }}
                    error={!!errors?.einNumber}
                    helperText={errors?.einNumber ? 'EIN must be a 9-digit number' : null}
                    onFocus={onEinInputFocus}
                  />
                )}
              />
            </NpiAndEinContainer>
            <Gap />
            <InfoText text="Please provide the NPI of the registered dentist." />
          </>
        ) : (
          <>
            <Label>Do you know your EIN? This can help us process your application faster.</Label>
            <Controller
              name="einNumber"
              control={control}
              defaultValue=""
              rules={{ required: false, maxLength: 9, minLength: 9 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="einNumber"
                  variant="filled"
                  data-testid="einNumber"
                  type="tel"
                  label="Optional: Employer Identification Number (EIN)"
                  InputProps={{
                    inputComponent: EINNumberInput as any,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_MORE_DETAILS_EIN_FILLED, {
                      value,
                    });
                    onBlur();
                  }}
                  error={!!errors?.einNumber}
                  helperText={errors?.einNumber ? 'EIN must be a 9-digit number' : null}
                  onFocus={onEinInputFocus}
                />
              )}
            />
          </>
        )}
      </ContentContainer>
      <ButtonContainer>
        {/*// @ts-ignore*/}
        <Button data-testid="submit" disabled={isFormDisabled} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
      <NoRefundModal hideModal={toggleNoRefundModal} show={displayNoRefundModal} />
      <ApplicationLeadReasonPrompt
        type={ApplicationLeadPromptReasons.Npi}
        show={!!isNpiInfoReasonPromptVisible}
        onConfirmAndContinue={onSkipReasonPrompt}
        onEditInfo={onEditInfo}
        loading={updatePromptReasonLoading}
      />
    </Container>
  );
};

export default FinancialServices;

const Label = styled.span`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const Gap = styled.span`
  margin-top: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
`;

const NpiAndEinContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
