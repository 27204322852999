import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const SsnInput = forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const onAcceptHandler = (value: string, mask: any) => {
    props.onChange({ target: { name: props.name, value: mask.value } });
  };

  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-00-0000"
      definitions={{ '#': /[1-9]/ }}
      inputRef={ref}
      onAccept={onAcceptHandler}
      overwrite={true}
    />
  );
});
