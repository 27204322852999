import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Icon, RadioButtonGroup, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore, { Requirement } from 'lib/hooks/useStore';
import { useApplicationLeadRequirementSubmit } from 'lib/services';

interface State {
  name: string;
  dba: string;
  industry: string;
  phone: string;
  website: string;
}

const AddCreditUnlock = () => {
  const navigate = useNavigate();
  const dbaRef = useRef<any>({ current: '' });
  const { sessionData, setPageTitle } = useStore();
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { submitApplicationLeadRequirement, loading } = useApplicationLeadRequirementSubmit();

  dbaRef.current = watch('dba', '');
  const { requirements = [] }: { requirements: Requirement[] } = sessionData;

  const [radioValue, setRadioValue] = useState('');

  useEffect(() => {
    setRadioValue(sessionData.hasDba);
  }, []);

  useEffect(() => {
    setPageTitle('Provide More Info');
  }, [setPageTitle]);

  const onSubmit = async (data: State) => {
    const requirement = requirements.find((req: Requirement) => req.reason === 'R_CreditUnlock');
    const res = await submitApplicationLeadRequirement({
      submittedInformation: data?.dba,
      applicationLeadId: sessionData?.id,
      requirementId: requirement?.id,
    });

    if (res) {
      navigate('/provide-more-info');
    }
  };

  const buttonDisabled =
    radioValue === '' ||
    (radioValue === 'false' && dbaRef.current === '') ||
    (radioValue === 'false' && dbaRef?.current?.length < 5) ||
    loading;

  return (
    <Container backUrl="/provide-more-info">
      <ContentContainer>
        <Icon src={'key'} />
        <Title m={'10px 0px 24px 0px'}>Credit unlock</Title>
        <Subtitle m={'10px 0px 24px 0px'}>
          As part of our underwriting process we perform a soft credit check on our merchants via Experian.
        </Subtitle>
        <Box>
          <RadioButtonGroup
            label="Have you unlocked your credit report?"
            onChange={(e) => setRadioValue(e.target.value)}
            value={radioValue}
          />
          {radioValue === 'false' && (
            <>
              <Subtitle m={'0px 0px 8px 0px'}>
                No worries, please request a one time use <b>Experian Freeze Key PIN</b> by contacting Experian’s
                customer service department at 1-888-EXPERIAN (888-397-3742).
              </Subtitle>
              <Controller
                name="dba"
                control={control}
                defaultValue=""
                rules={{ required: true, minLength: 5, maxLength: 10 }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    label="Experian Freeze Key PIN (5-10 digits)"
                    id="filled-basic"
                    variant="filled"
                    data-testid="dba"
                    type="tel"
                    InputProps={{
                      maxLength: 10,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </>
          )}
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={buttonDisabled} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddCreditUnlock;
