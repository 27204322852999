import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React from 'react';

interface Props {
  show: boolean;
  handleClose: () => void;
  message: string;
  position?: SnackbarOrigin;
}

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Notification = ({
  show,
  handleClose,
  message,
  position = { vertical: 'top', horizontal: 'center' },
}: Props) => {
  return (
    <Snackbar anchorOrigin={position} open={show} onClose={handleClose}>
      <CustomAlert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {message}
      </CustomAlert>
    </Snackbar>
  );
};
