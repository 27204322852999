import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type BlinkingTextProps = {
  text: string | string[];
};

const DURATION = 7000;

export const BlinkingText = ({ text }: BlinkingTextProps): JSX.Element => {
  const [textOrder, _] = useState<Array<string>>(Array.isArray(text) ? text : [text]);
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);

  const isLastText = currentTextIndex === textOrder.length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTextIndex === textOrder?.length - 1) {
        clearInterval(interval);
      }

      const index = currentTextIndex === textOrder?.length - 1 ? currentTextIndex : currentTextIndex + 1;
      setCurrentTextIndex(index);
    }, DURATION);

    return () => clearInterval(interval);
  }, [currentTextIndex, textOrder]);

  return textOrder.length > 0 ? <Text stopBlinking={isLastText}>{textOrder[currentTextIndex]}</Text> : <></>;
};

const Text = styled.span<{ stopBlinking?: boolean }>`
  color: ${(props) => props.theme.main.black};
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.09px;
  animation: ${(props) => (props?.stopBlinking ? 'fadeIn 7s' : 'fade 7s infinite')};

  @keyframes fade {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 100%;
    }
    75% {
      opacity: 100%;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 100%;
    }
  }
`;
