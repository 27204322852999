import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Icon, Title } from 'lib/components';

export const DidntGetCodeModal = ({ show, hideModal, reSendCode, callMeWithCode }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <HelpMeContainer>
          <HelpMeHeader>
            <IconGap />
            <Title m={'24px 0px'}>Didn’t Get a Code?</Title>
            <Icon src={'close'} onClick={hideModal} />
          </HelpMeHeader>
          <HelpMeButton onClick={reSendCode}>
            <Icon src={'message_writing'} m={'0px 10px'} />
            Re-send Code
          </HelpMeButton>
          <HelpMeButton onClick={callMeWithCode}>
            <Icon src={'phone'} m={'0px 10px'} />
            Call me with Code
          </HelpMeButton>
        </HelpMeContainer>
      </Slide>
    </Backdrop>
  );
};

const HelpMeContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: #ffffff;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0px 0px 32px 32px;
  padding: 0px 24px 30px;
  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 250px;
    border-radius: 6px;
    padding: 0px 24px 0px;
  }
`;

const HelpMeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HelpMeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #56636d;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: #0e202f;
  cursor: pointer;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;
