import { useMutation } from '@apollo/client';
import { SUBMIT_APPLICATION_LEAD_REQUIREMENT } from 'lib/graphql/mutations';

export const useApplicationLeadRequirementSubmit = () => {
  const [applicationLeadRequirementsSubmit, { loading }] = useMutation(SUBMIT_APPLICATION_LEAD_REQUIREMENT);

  const submitApplicationLeadRequirement = async (data) => {
    const response = await applicationLeadRequirementsSubmit({ variables: { input: data } });

    return !!response?.data?.applicationLeadRequirementsSubmit?.success;
  };

  return { submitApplicationLeadRequirement, loading };
};
