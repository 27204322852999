import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from 'styled-components';

import { Icon } from 'lib/components';

interface Props {
  value: string;
  label?: string;
  leftText?: string;
  leftTextValue?: string | boolean;
  rightText?: string;
  rightTextValue?: string | boolean;
  onChange: any;
  alignment?: 'horizontal' | 'vertical';
}

export const RadioButtonGroup = ({
  value,
  onChange,
  label,
  leftText = 'No',
  leftTextValue = 'false',
  rightText = 'Yes',
  rightTextValue = 'true',
  alignment = 'horizontal',
}: Props) => {
  const radioSxObject = {
    '&.Mui-checked': {
      color: '#1ec37c',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  };

  const radioContainerClicked = (text: string | boolean) => {
    onChange({ target: { value: text } });
  };

  const leftOption = () => (
    <RadioContainer
      data-value={leftTextValue}
      selected={value === leftTextValue}
      data-testid="radio-false"
      onClick={() => radioContainerClicked(leftTextValue)}
    >
      <Radio
        name="radio-button-false"
        inputProps={{ 'aria-label': 'B' }}
        value={leftTextValue}
        sx={radioSxObject}
        checkedIcon={<Icon width={16} height={16} src={'checkedRadio'} hover={true} />}
        icon={<Icon width={16} height={16} src={'radio'} hover={true} />}
      />
      <RadioLabel selected={value === leftTextValue}>{leftText}</RadioLabel>
    </RadioContainer>
  );

  const rightOption = () => (
    <RadioContainer
      data-value={rightTextValue}
      selected={value === rightTextValue}
      data-testid="radio-true"
      onClick={() => radioContainerClicked(rightTextValue)}
    >
      <Radio
        name="radio-button-true"
        inputProps={{ 'aria-label': 'A' }}
        value={rightTextValue}
        sx={radioSxObject}
        checkedIcon={<Icon width={16} height={16} src={'checkedRadio'} hover={true} />}
        icon={<Icon width={16} height={16} src={'radio'} hover={true} />}
      />
      <RadioLabel selected={value === rightTextValue}>{rightText}</RadioLabel>
    </RadioContainer>
  );

  return (
    <CustomRadioGroup value={value} onChange={onChange} label={label}>
      {label && <Label>{label}</Label>}
      {alignment === 'horizontal' && (
        <InnerContainer>
          {leftOption()}
          <Gap />
          {rightOption()}
        </InnerContainer>
      )}

      {alignment === 'vertical' && (
        <InnerContainer vertical={true}>
          {rightOption()}
          <Gap />
          {leftOption()}
        </InnerContainer>
      )}
    </CustomRadioGroup>
  );
};

const CustomRadioGroup = styled(RadioGroup)<{ label?: string }>`
  width: 100% !important;
  margin-bottom: 15px !important;
  margin-top: ${(props) => (props.label ? '8px' : '0')};
`;

const InnerContainer = styled.div<{ vertical?: boolean }>`
  ${(props) =>
    !props.vertical &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
    `}
`;

const Label = styled.span`
  display: flex;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
`;

const RadioLabel = styled.span<{ selected?: boolean }>`
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  color: ${(props) => (props.selected ? '#0E202F' : '#56636D')};
  font-size: 14px;
  line-height: 19px;
  margin-left: 7px;
`;

const RadioContainer = styled.div<{ selected?: boolean }>`
  height: 56px;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 7px;
  border: ${(props) => (props.selected ? '2px solid #00c37c' : '1px solid #56636D')};
  // outline-offset: ${(props) => (props.selected ? '-2px' : '-1px')};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px !important;
  color: #979797;
  cursor: pointer;
`;

const Gap = styled.div`
  width: 2%;
  height: 12px;
`;
