import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { GetApplicationLeadPromptReasonsResponse } from 'lib/constants';
import { GET_APPLICATION_LEAD_PROMPT_REASONS } from 'lib/graphql/queries/ApplicationLeadRequirement';

export const useGetApplicationLeadPromptReasons = (applicationLeadId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetApplicationLeadPromptReasonsResponse | null>(null);

  const [getApplicationLeadPromptReasons] = useLazyQuery(GET_APPLICATION_LEAD_PROMPT_REASONS, {
    variables: {
      input: {
        applicationLeadId,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data?.getApplicationLeadPromptReasons);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException('useGetApplicationLeadPromptReasons', {
        level: 'error',
        extra: {
          errorMessage: 'GET_APPLICATION_LEAD_PROMPT_REASONS ERROR',
          page: 'useGetApplicationLeadPromptReasons',
          error,
        },
      });
    },
  });

  return { getApplicationLeadPromptReasons, data, loading, setLoading };
};
