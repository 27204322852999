import { gql } from '@apollo/client/core';

export const GET_APPLICATION_LEAD_PROMPT_REASONS = gql`
  query getApplicationLeadPromptReasons($input: GetApplicationLeadPromptReasons) {
    getApplicationLeadPromptReasons(input: $input) {
      applicationLeadPromptReasons {
        id
        applicationLeadId
        reason
        status
      }
    }
  }
`;
