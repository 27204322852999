import React from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components';

type InfoTextProps = {
  text: string;
  margin?: string | number;
};

export const InfoText = ({ text, margin }: InfoTextProps) => {
  return (
    <InfoContainer margin={margin}>
      <Icon width={20} height={20} src="info_circle_outline" />
      <Text>{text}</Text>
    </InfoContainer>
  );
};

const InfoContainer = styled.div<{ margin?: string | number }>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
