import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FORGOT_PASSWORD } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { emailPattern } from 'lib/utils/Validators';

interface State {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { setPageTitle } = useStore();
  const alert = useAlert();
  const { trackSegmentEvent, trackPage } = useSegment();

  const [tempEmail, setTempEmail] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [mailSent, setMailSent] = useState(false);

  const [forgotPasswordMutation, { loading }] = useMutation(FORGOT_PASSWORD);

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;

  useEffect(() => {
    trackPage('forgotPassword');
    setPageTitle('Forgot Password');
  }, [setPageTitle]);

  const onSubmit = async (params: State) => {
    trackSegmentEvent(SegmentEventNames.SEND_RESET_LINK_CLICKED);
    try {
      const { email } = params;
      setTempEmail(params.email);
      const {
        data: { forgotPassword },
      } = await forgotPasswordMutation({ variables: { input: { email, source: 'ONBOARDING' } } });
      if (forgotPassword.success) {
        setMailSent(true);
      } else {
        alert.info('No matching email found. Double-check spelling or create a new account.');
        setNotFound(true);
      }
    } catch (err) {
      alert.info('An error occurred. Please try again later.');
    }
  };

  const registerHandler = () => {
    navigate('/register');
  };

  return (
    <Container>
      <ContentContainer>
        <Icon width={35} height={35} src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>
          {mailSent ? `A password reset link was sent to ${tempEmail}` : 'Please enter your email'}
        </Title>
        <Subtitle m={'0px 0px 20px 0px'}>
          {mailSent
            ? 'Open your email and click the link to reset your account password.'
            : 'You will be sent a link to reset your password.'}
        </Subtitle>
        {!mailSent && (
          <Box>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true, pattern: emailPattern }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="filled-basic"
                  variant="filled"
                  label="Email"
                  data-testid="email"
                  type="email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Box>
        )}
      </ContentContainer>
      {!mailSent && (
        <ButtonContainer>
          {/*// @ts-ignore*/}
          <Button disabled={!isValid || loading} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
            Send Reset Link
          </Button>
          {notFound && (
            <Button secondary={true} onClick={registerHandler}>
              Create New Account
            </Button>
          )}
        </ButtonContainer>
      )}
    </Container>
  );
};

export default ForgotPassword;
