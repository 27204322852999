import { PhotoUploadForm } from 'lib/components';

const AddPriceList = () => {
  return (
    <PhotoUploadForm
      title="Price List"
      subtitle="Cherry must verify the services (and their respective prices) our practices offer. We either could not verify your services on your website or you do not have a website. Please provide a full, comprehensive list of services your business offers and their respective costs."
      reason="R_PriceList"
      exampleImgSrc="examples/price-list.png"
      icon="price-list"
    />
  );
};

export default AddPriceList;
