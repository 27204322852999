import StorageService from 'lib/services/Storage';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

export const useIdleTimeout = ({ idleTime = 1 }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const idleTimeout = 60000 * idleTime; // Minutes
  const [isIdle, setIdle] = useState(false);

  const ignorePaths = ['/login', '/register', '/forgot-password', '/reset-password'];

  const handleIdle = () => {
    try {
      if (!ignorePaths.includes(location.pathname)) {
        setIdle(true);
        navigate('/login');
        StorageService.clearUserData();
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onIdle: handleIdle,
    debounce: 500,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
