import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import useStore from 'lib/hooks/useStore';

const PreparationList = () => {
  const navigate = useNavigate();
  const { setPageTitle } = useStore();
  const { flags } = useFlags();
  const { trackPage, trackSegmentEvent } = useSegment();

  useEffect(() => {
    setPageTitle('Preparation List');
  }, [setPageTitle]);

  useEffect(() => {
    trackPage('preparationList');
    navigate('/user-role');
  }, []);

  const navigateToPersonalInfo = () => {
    trackSegmentEvent(SegmentEventNames.PREPARATION_LIST_CONTINUE_CLICKED);
    navigate('/user-role');
  };

  return (
    <Container>
      <ContentContainer justify="space-between">
        <Icon src={'preparation'} />
        <Title m={'10px 0px 24px 0px'}>Before You Start</Title>
        <Subtitle>We’ll need the following information to complete your application:</Subtitle>
        <InfoItem>
          <Icon src={'user_circle'} width={24} height={24} />
          <InfoItemText>Owner’s information</InfoItemText>
        </InfoItem>
        <InfoItem>
          <Icon src={'cursor'} width={24} height={24} />
          <InfoItemText>Live website or social page</InfoItemText>
        </InfoItem>
        <InfoItem>
          <Icon src={'bank'} width={24} height={24} />
          <InfoItemText>Banking information</InfoItemText>
        </InfoItem>
        <AdditionalInformation>Additional Information</AdditionalInformation>
        <Subtitle>
          Some applications may require additional information. To expedite this process, please have the following:
        </Subtitle>
        <InfoItem>
          <Icon src={'remote-check-midnight'} width={24} height={24} />
          <InfoItemText>Picture of a voided check</InfoItemText>
        </InfoItem>
        <InfoItem>
          <Icon src={'business-store'} width={24} height={24} />
          <InfoItemText>Business registration</InfoItemText>
        </InfoItem>
        <InfoItem>
          <Icon src={'photo-black'} width={24} height={24} />
          <InfoItemText>Location photos</InfoItemText>
        </InfoItem>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={navigateToPersonalInfo}>Continue</Button>
      </ButtonContainer>
    </Container>
  );
};

export default PreparationList;

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const InfoItemText = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0e202f;
  margin-left: 18px;
`;

const AdditionalInformation = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #56636d;
  margin-bottom: 8px;
  margin-top: 26px;
`;
