const deleteAllCookies = () => {
  try {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  } catch (err) {
    console.warn(err);
  }
};

export const getUserToken = () => {
  const userInfo = localStorage.getItem('@userInfo');
  const parsedInfo = Boolean(userInfo) ? JSON.parse(userInfo!) : {};
  return parsedInfo && parsedInfo.token;
};

export const clearUserData = () => {
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
};

export const setAuthData = ({ authToken, refreshToken }) => {
  localStorage.setItem('@authToken', authToken);
  localStorage.setItem('@refreshToken', refreshToken);
};

export const getAuthData = () => {
  const token = localStorage.getItem('@authToken') || undefined;
  const refreshToken = localStorage.getItem('@refreshToken') || undefined;

  return { token, refreshToken };
};

export const setUserData = (data: any) => {
  localStorage.setItem('@userInfo', JSON.stringify(data));
};

export const getUserData = () => {
  const userInfo = localStorage.getItem('@userInfo');
  const parsedInfo = userInfo ? JSON.parse(userInfo) : {};

  return parsedInfo;
};

export const setSfInfo = (data: any) => {
  localStorage.setItem('@sfInfo', JSON.stringify(data));
};

export const getSfInfo = () => {
  const sfInfo = localStorage.getItem('@sfInfo');
  const parsedInfo = sfInfo ? JSON.parse(sfInfo) : {};

  return parsedInfo;
};

export default {
  getUserToken,
  clearUserData,
  setUserData,
  getUserData,
  setAuthData,
  getAuthData,
  setSfInfo,
  getSfInfo,
};
