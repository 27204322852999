import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container, Icon, Notification, PricingInformationTable, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';

import { DENTAL_INDUSTRIES } from 'lib/constants';
import { UPDATE_PLAN } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';
import { PlanSelector } from './PlanSelector';
import {
  BadgeBorder,
  BadgeTextAmount,
  BadgeTextFee,
  BadgeTextInfo,
  BadgeTier,
  PricingTierContainer,
  PricingTiers,
  PricingTierTextContainer,
} from './PricingTiers';

const PricingReview = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { trackPage, trackSegmentEvent } = useSegment();

  const { setPageIndex, sessionData, setSessionData, setPageTitle } = useStore();

  const [updatePlan, { loading }] = useMutation(UPDATE_PLAN);

  const [error, setError] = useState(false);

  const defaultInstallmentMethod = DENTAL_INDUSTRIES.includes(sessionData.industry) ? 24 : 12;
  const initialInstallmentMethod = sessionData.promoPeriod
    ? parseInt(sessionData.promoPeriod, 10)
    : defaultInstallmentMethod;

  const [showPricingTierModal, setShowPricingTierModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedInstallment, setSelectedInstallment] = useState(initialInstallmentMethod);

  const isDental = DENTAL_INDUSTRIES.includes(sessionData.industry);

  useEffect(() => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_PRICING_REVIEW_PAGE_LOAD);
  }, []);

  const handleClose = () => {
    setError(false);
    setErrorMessage('');
  };

  const onBack = () => {
    navigate(`/review`);
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_PRICING_REVIEW_INFO_BACK_CLICKED);
  };

  const aprFinancingMonths = () => {
    switch (selectedInstallment) {
      case 3:
        return '3 months.';
      case 6:
        return '6 months.';
      case 12:
        return '12 months.';
      case 18:
        return ' 18 months.';
      case 24:
        return '24 months.';
      default:
        return '3 months.';
    }
  };

  const onSubmit = async () => {
    trackSegmentEvent(SegmentEventNames.PRACTICE_REGISTRATION_PRICING_REVIEW_CONTINUE_CLICKED, {
      value: selectedInstallment.toString(),
    });

    setSessionData({ promoPeriod: selectedInstallment.toString() });

    try {
      const { id } = sessionData;
      const {
        data: { updateApplicationLeadPlan },
      } = await updatePlan({ variables: { input: { promoPeriod: selectedInstallment, applicationLeadId: id } } });
      if (updateApplicationLeadPlan?.id) {
        if (isDental) {
          navigate('/first-look');
        } else {
          navigate('/agreement-review');
        }
      } else {
        alert.info('An error occurred. Please try again later.');
      }
    } catch (err) {
      alert.info('An error occurred. Please try again later.');
    }
  };

  const onInstallmentSelectedHandler = (value: number) => {
    setSelectedInstallment(value);
  };

  const openPricingTierModal = () => {
    setShowPricingTierModal(true);
  };

  const closePricingTierModal = () => {
    setShowPricingTierModal(false);
  };

  useEffect(() => {
    setPageTitle(isDental ? 'Pricing' : 'Plan Selection');
  }, [setPageTitle, isDental]);

  useEffect(() => {
    setPageIndex(11);
    trackPage('pricingReview');
  }, [setPageIndex]);

  return (
    <Container backUrl="/review">
      <ContentContainer>
        <Icon width={30} height={30} src="payment" />
        <Title m="10px 0px">{isDental ? 'Review Pricing' : 'Select a plan'}</Title>
        {!isDental && (
          <>
            <Subtitle m="0px 0px 24px 0px">
              How long would you like to <b>offer your patients promotional rates</b>? You can change this later.
            </Subtitle>
            <PlanSelector onSelect={onInstallmentSelectedHandler} initialValue={initialInstallmentMethod} />
            <Subtitle m="0px 0px 24px 0px">
              Qualified patients are eligible for <b>0% or Reduced APR plans</b> for up to <b>{aprFinancingMonths()}</b>{' '}
              and standard APR plans up to 24 months.
            </Subtitle>
          </>
        )}
        {isDental && (
          <>
            <Subtitle m="0px 0px 24px 0px">
              Qualified patients are eligible for <b>promotional APR plans</b> for up to <b>24 months</b> and standard
              APR plans for up to 60 months.
            </Subtitle>
          </>
        )}
        <PricingInformationTable selectedInstallment={selectedInstallment} showExtraInformation={true} />

        <DiscountsTitle>Pricing Tiers</DiscountsTitle>
        <Subtitle m="0px 0px 24px 0px">
          You’ll start out on <b>Bronze Tier</b> with Standard Fees. Unlock Silver and Gold Tiers to earn discounts on
          practice fees.
        </Subtitle>

        <PricingTierContainer onClick={openPricingTierModal}>
          <BadgeTier>BRONZE TIER</BadgeTier>
          <PricingTierTextContainer>
            <BadgeTextAmount>$0 - $15k</BadgeTextAmount>
            <BadgeTextInfo>Cherry sales per month</BadgeTextInfo>
            <BadgeBorder />
            <BadgeTextFee>standard fees</BadgeTextFee>
          </PricingTierTextContainer>
          <Icon width={30} height={30} src="info_circle_dark" />
        </PricingTierContainer>
        <PricingTiers show={showPricingTierModal} hideModal={closePricingTierModal} />
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={loading} loading={loading} type="submit" onClick={onSubmit}>
          {isDental ? 'Continue' : `Choose ${selectedInstallment}-Month Plan`}
        </Button>
        <Button data-testid="another-option-button" secondary={true} onClick={onBack}>
          Back
        </Button>
        <Notification show={error} handleClose={handleClose} message={errorMessage} />
      </ButtonContainer>
    </Container>
  );
};

const DiscountsTitle = styled.span`
  color: ${(props) => props.theme.main.textColor};

  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-top: 36px;
  margin-bottom: 8px;
`;

export default PricingReview;
