import { useMutation } from '@apollo/client';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { emailPattern } from 'lib/utils/Validators';

import { LOGIN } from 'lib/graphql/mutations';
import { SegmentEventNames, useSegment } from 'lib/hooks/useSegment';

interface State {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const { setPageTitle } = useStore();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const { trackSegmentEvent, trackIdentify } = useSegment();

  const [salesforceAccountId, setSalesforceAccountId] = useState<string>('');
  const [salesforceOwnerId, setSalesforceOwnerId] = useState<string>('');

  const [login, { loading }] = useMutation(LOGIN);

  NavigateService.initNavigate(navigate);

  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
  });
  const { isValid } = formState;

  useEffect(() => {
    setPageTitle('Login or Register');
  }, [setPageTitle]);

  useEffect(() => {
    setSalesforceAccountId(searchParams.get('sf_account_id') || '');
    setSalesforceOwnerId(searchParams.get('sf_owner_id')?.toString() || '');
    trackIdentify();
  }, []);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: State) => {
    trackSegmentEvent(SegmentEventNames.LOGIN_CLICKED);
    const { email, password } = data;
    const res = await login({ variables: { input: { email, password, scope: 'ONBOARDING' } } });
    if (res?.data?.userLogin?.success) {
      const userData = res.data?.userLogin;
      const { user } = userData;
      trackIdentify({ id: user?.id, firstName: user?.firstName, lastName: user?.lastName, email: user?.email });

      StorageService.setUserData(userData);
      StorageService.setAuthData({ authToken: userData.token, refreshToken: userData.refreshToken });
      navigate('/');
    } else {
      alert.info(res?.data?.userLogin?.message);
    }
  };

  const onRegisterHandler = () => {
    trackSegmentEvent(SegmentEventNames.LOGIN_CREATE_NEW_ACCOUNT_CLICKED);
    StorageService.setSfInfo({ salesforceAccountId, salesforceOwnerId });
    navigate('/register');
  };

  const onForgotPasswordHandler = () => {
    trackSegmentEvent(SegmentEventNames.FORGOT_PASSWORD_CLICKED);
    navigate('/forgot-password');
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter' && isValid) {
      event.preventDefault();
      event.stopPropagation();
      // @ts-ignore
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'cherry-logo'} />
        <Title m={'10px 0px 8px 0px'}>Welcome to Cherry!</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          Enter your email and password to pick up where you left off, or create a new account.
        </Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: emailPattern }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="email-input"
                variant="filled"
                data-testid="email"
                label="Email"
                type="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="filled-basic"
                variant="filled"
                data-testid="password"
                label="Password"
                value={value}
                type={showPassword ? 'email' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={!!error}
                helperText={error ? error.message : null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={showPasswordHandler}>
                      {showPassword ? <Icon src={'show_eye'} hover={true} /> : <Icon src={'hide_eye'} hover={true} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        {/*// @ts-ignore*/}
        <Button disabled={!isValid || loading} loading={loading} type="submit" onClick={handleSubmit(onSubmit)}>
          Login
        </Button>
        <Button onClick={onRegisterHandler} secondary={true}>
          Create New Account
        </Button>
        <ButtonText data-testid="forgot-password-button" onClick={onForgotPasswordHandler}>
          Forgot Password?
        </ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default Login;

const ButtonText = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-top: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
`;
