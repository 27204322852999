import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { API_URL } from 'config';
import {
  Button,
  Container,
  Icon,
  LoadingWithSteps,
  RadioButtonGroup,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useApplicationLeadPolling } from 'lib/hooks/useApplicationLeadPolling';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';

import { useMutation } from '@apollo/client';
import { UPDATE_APPLICATION_LEADS } from 'lib/graphql/mutations';
import { Controller, useForm } from 'react-hook-form';
import { InfoModal } from './InfoModal';

export const FirstLook = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const { setPageIndex, setSessionData, sessionData, setPageTitle } = useStore();
  const { control, watch, setValue } = useForm({
    defaultValues: sessionData,
  });
  const { token } = StorageService.getAuthData();
  const { trackSegmentEvent, segmentEventHandler } = useSegment();
  const { pollingLoading } = useApplicationLeadPolling();
  const [updateApplication, { loading: updateApplicationLoading }] = useMutation(UPDATE_APPLICATION_LEADS);
  const [loading, setLoading] = useState(true);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const openInfoModal = () => {
    setShowInfoModal(true);
    trackSegmentEvent('PRACTICE_REGISTRATION.PREFERRED_PARTNER.PREFERRED_PARTNER_TOOLTIP_CLICKED');
  };
  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  const watchIsFirstLook = watch('isFirstLook')?.toString();
  const values = watch();

  useEffect(() => {
    getFirstLookData();
  }, []);

  useEffect(() => {
    trackSegmentEvent('PRACTICE_REGISTRATION.PREFERRED_PARTNER.PAGE_VIEWED');
    setPageIndex(12);
    setPageTitle('Preferred Partner');
  }, [setPageTitle]);

  const getFirstLookData = async () => {
    try {
      const {
        data: { isFirstLook, firstLookDeclineExplanation },
      } = await axios.get(`${API_URL}/v1/application-leads/${sessionData.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      setValue('isFirstLook', isFirstLook?.toString());
      setValue('firstLookDeclineExplanation', firstLookDeclineExplanation?.toString());
      setSessionData({ ...sessionData, isFirstLook, firstLookDeclineExplanation });
    } catch (err) {
      alert.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    trackSegmentEvent('PRACTICE_REGISTRATION.PREFERRED_PARTNER.SUBMITTED', {
      firstLook: values?.isFirstLook,
      whyNot: values?.firstLookDeclineExplanation,
    });

    const approved = values?.isFirstLook.toString() === 'true';

    const newSessionData = {
      ...sessionData,
      isFirstLook: approved,
      firstLookDeclineExplanation: !approved ? values?.firstLookDeclineExplanation : undefined,
    };

    setSessionData(newSessionData);

    try {
      const { salesforceAccountId, salesforceOwnerId } = StorageService.getSfInfo();

      const {
        id,
        name,
        dba,
        industry,
        phone,
        website,
        street,
        unit,
        city,
        state,
        zip,
        role,
        ownerUserId,
        isFirstLook,
        firstLookDeclineExplanation,
        financingOptions,
        establishedAt,
        hasNoRefundPolicy,
      } = newSessionData;

      const params = {
        name,
        dba,
        industry,
        phone,
        website,
        financingOptions,
        address: {
          street,
          unit,
          city,
          state,
          zip,
        },
        establishedAt,
        role,
        ownerUserId,
        // TODO remove this after be non-nullable fix
        marketingChannels: ['Other'],
        salesforceAccountId,
        salesforceOwnerId,
        hasNoRefundPolicy:
          hasNoRefundPolicy === ''
            ? null
            : typeof hasNoRefundPolicy === 'boolean'
            ? hasNoRefundPolicy
            : hasNoRefundPolicy === 'true',
        isFirstLook,
        firstLookDeclineExplanation,
      };

      const {
        data: { updateApplicationLead },
      } = await updateApplication({
        variables: { input: { ...params, id } },
      });
      if (updateApplicationLead?.id) {
        navigate('/agreement-review');
      } else {
        alert.info('An error occurred. Please try again later.');
      }
    } catch (err) {
      alert.info('An error occurred please try again');
    }
  };

  const isButtonDisable = () => {
    return loading || updateApplicationLoading || watchIsFirstLook === '' || watchIsFirstLook === undefined;
  };

  const onRadioChangeHandler = (onChangeEv) => {
    return (ev) => {
      const value = ev?.target?.value;

      if (value === 'true') {
        trackSegmentEvent('PRACTICE_REGISTRATION.PREFERRED_PARTNER.RADIO_YES_CLICKED');
      }

      if (value === 'false') {
        trackSegmentEvent('PRACTICE_REGISTRATION.PREFERRED_PARTNER.RADIO_NO_CLICKED');
      }

      onChangeEv(ev);
    };
  };

  return (
    <>
      <Container type="agreement" backUrl="/pricing-review">
        <InfoModal
          show={showInfoModal}
          hideModal={closeInfoModal}
          onExit={segmentEventHandler('PRACTICE_REGISTRATION.PREFERRED_PARTNER.TOOLTIP_SHELF_DISMISS_CLICKED')}
        />
        <ContentContainer>
          <Icon src="payment" />
          <Title m="16px 0px 24px">Can we be your number one?</Title>
          <Subtitle m="0px 0px 24px 0px">
            You’ll get the best merchant fees, approval rates, and approval amounts if you present Cherry first.
            <br />
            <br />
            All this means is using Cherry as your preferred payment plan. It’s not exclusive. You can still try other
            options after applying with Cherry.
            <br />
            <br />
            Applying to Cherry second or only for denials leads to fewer, smaller, and costlier approvals.
          </Subtitle>

          <Row>
            <Icon src="info_circle_green" width={24} height={24} />
            <UnderlinedSubtitle onClick={openInfoModal} m="0px 0px 0px 8px">
              What does preferred partner mean?
            </UnderlinedSubtitle>
          </Row>

          <Gap size="12px" />

          <Controller
            name="isFirstLook"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RadioButtonGroup
                label=""
                onChange={onRadioChangeHandler(onChange)}
                value={value?.toString()}
                alignment="vertical"
                rightText="Yes, let’s make Cherry our first choice!"
                leftText="No thanks"
              />
            )}
          />
          {watchIsFirstLook === 'false' && (
            <>
              <Controller
                name="firstLookDeclineExplanation"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    style={{ width: '100%', marginBottom: 10 }}
                    label="We’d love to understand why (optional)"
                    id="filled-basic"
                    variant="filled"
                    name="firstLookDeclineExplanation"
                    value={value}
                    onChange={onChange}
                    onFocus={segmentEventHandler('PRACTICE_REGISTRATION.PREFERRED_PARTNER.NO_EXPLANATION_SELECTED')}
                    onBlur={segmentEventHandler('PRACTICE_REGISTRATION.PREFERRED_PARTNER.NO_EXPLANATION_FILLED', {
                      withInput: true,
                    })}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </>
          )}
        </ContentContainer>
        <ButtonContainer>
          <Button
            disabled={isButtonDisable()}
            type="submit"
            onClick={onSubmit}
            loading={updateApplicationLoading || loading}
          >
            Continue
          </Button>
        </ButtonContainer>
      </Container>
      {pollingLoading && <LoadingWithSteps />}
    </>
  );
};

const Gap = styled.div<{ size: string }>`
  margin-bottom: ${(props) => props.size};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const UnderlinedSubtitle = styled(Subtitle)`
  text-decoration-line: underline;
  cursor: pointer;
`;
