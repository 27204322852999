import { gql } from '@apollo/client/core';

export const FETCH_AUTH_INFO = gql`
  query getUserAuthInfo {
    getUserAuthInfo {
      code
      message
      user {
        id
        email
        firstName
        lastName
        phone
      }
    }
  }
`;
