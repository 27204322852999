import { Installments } from 'lib/types';
import styled from 'styled-components';

interface Props {
  installments: Installments[];
  selectedInstallment: number;
  onSelected: (value: number) => void;
}

export const InstallmentButtonGroup = ({ installments, onSelected, selectedInstallment }: Props) => {
  const onChangeHandler = (e: React.MouseEvent<HTMLElement>) => {
    const input = e.target as HTMLElement;
    const value = parseInt(input.innerText, 10);
    onSelected(value);
  };

  return (
    <Container>
      {installments.map((instll: Installments, index: number) => (
        <InstallmentContainer key={index}>
          <CircleButton
            data-testid={`installment_${instll.installmentDuration}`}
            onClick={onChangeHandler}
            selected={selectedInstallment === instll.installmentDuration}
          >
            {instll.installmentDuration}
          </CircleButton>
          <CircleLabel selected={selectedInstallment === instll.installmentDuration}>months</CircleLabel>
        </InstallmentContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 10px;
`;

const InstallmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const CircleButton = styled.span<{ selected?: boolean }>`
  font: 900 20px Roboto, 'Helvetica Neue', sans-serif;
  color: ${(props) => (props.selected ? props.theme.main.green : props.theme.main.borderColor)};
  padding: 15px;
  min-width: 25px;
  border-radius: 50%;
  border: ${(props) =>
    props.selected ? `3px solid ${props.theme.main.green}` : `3px solid ${props.theme.main.borderColor}`};
`;

const CircleLabel = styled.span<{ selected?: boolean }>`
  font: 900 14px/20px Roboto, 'Helvetica Neue', sans-serif;
  color: ${(props) => (props.selected ? props.theme.main.green : props.theme.main.borderColor)};
  margin-top: 5px;
`;
