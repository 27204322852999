import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Container } from 'lib/components';
import useStore from 'lib/hooks/useStore';

interface State {
  pocFirstName: string;
  pocLastName: string;
  pocPhone: string;
}

const BusinessOwner = () => {
  const navigate = useNavigate();
  const { setPageIndex, sessionData } = useStore();
  const { handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: sessionData,
  });
  const { isValid } = formState;

  useEffect(() => {
    setPageIndex(5);
  }, [setPageIndex]);

  const onSubmit = (data: State) => {
    navigate('/banking-info');
  };

  return (
    <Container>
      <Typography variant="h5" mb={6}>
        Tell us about yourself
      </Typography>
      <ButtonContainer>
        <Button disabled={!isValid} type="submit" onClick={handleSubmit(onSubmit)}>
          Continue
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default BusinessOwner;

const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
