import { PhotoUploadForm } from 'lib/components';

const AddDentalLicense = () => {
  return (
    <PhotoUploadForm
      title="Dental License"
      subtitle="Due to the nature of services your business provides, Cherry requires there to be a fully licensed dentist on staff or overseeing operations. Please upload proof of the doctor’s DDS or DMD license. Note: While we understand some states do not require a dental license to practice, Cherry has chosen to set this requirement as part of internal protections."
      reason="R_DentalLicense"
      exampleImgSrc="examples/dental-license.png"
      icon="medical-license"
    />
  );
};

export default AddDentalLicense;
