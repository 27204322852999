import { ClickLinkForm } from 'lib/components';

const ZoomWalkthrough = () => {
  return (
    <ClickLinkForm
      reason="R_ZoomWalkThrough"
      title="Zoom walkthrough"
      buttonText="Schedule Walkthrough"
      buttonUrl="https://withcherry.chilipiper.com/book/underwriting-review-queue"
      icon="zoom"
    >
      <p>
        As part of your application review, we require a brief Zoom tour of your location and treatment areas.
        <ul>
          <li>This tour should take just 5-10 minutes.</li>
          <li>
            You will need to be present and at your place of business to complete this tour. If you join from home, you
            will be asked to reschedule.
          </li>
        </ul>
      </p>

      <p>
        Please use the link below to select a time convenient for you. After you schedule your call please note the date
        and time of your tour and use the link in the confirmation email to join.
      </p>
    </ClickLinkForm>
  );
};

export default ZoomWalkthrough;
