import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { GQL_URL } from 'config';
import jwtDecode from 'jwt-decode';
import StorageService from 'lib/services/Storage';
import NavigateService from '../services/Navigate';

const isExpiredToken = (token = '') => {
  try {
    const decodedToken = jwtDecode(token) as any;
    const tokenExpiration = decodedToken.exp * 1000 - 10000;
    const currentTime = Date.now().valueOf();

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};

const httpLink = new HttpLink({
  uri: GQL_URL,
});

const authMiddleware = setContext(async (_, { headers, ...rest }) => {
  try {
    const { token } = StorageService.getAuthData();
    if ((token !== 'undefined' && Boolean(token) && !isExpiredToken(token)) || _.operationName === 'refreshToken') {
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
        ...rest,
      };
    } else {
      if (token) {
        StorageService.clearUserData();
        NavigateService.navigate('/login');
      }
      const { token: refreshedToken } = StorageService.getAuthData();

      return {
        headers: {
          ...headers,
          Authorization: Boolean(refreshedToken) ? `Bearer ${refreshedToken}` : '',
        },
        ...rest,
      };
    }
  } catch (error: any) {
    Sentry.captureException('Auth Middleware Error', {
      level: 'error',
      extra: {
        errorMessage: error?.message,
        error,
      },
    });
  }
});

const checkTokenValidLink = new ApolloLink((operation: any, forward: any) => {
  return forward(operation).map((response: any) => {
    try {
      if (response?.data?.[operation.operationName]?.code === 'auth.token.invalid') {
        NavigateService.navigate('/login');
        StorageService.clearUserData();
        return response;
      } else {
        return response;
      }
    } catch (error: any) {
      Sentry.captureException('Token Validation Error', {
        level: 'error',
        extra: {
          errorMessage: error?.error?.message,
          error,
        },
      });
    }
  });
});

const link = checkTokenValidLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, link]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
  name: 'merchant-onboarding',
});

export default client;
