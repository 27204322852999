import { gql } from '@apollo/client/core';

export const PATCH_APPLICATION_LEAD_USER = gql`
  mutation patchApplicationLeadUser($input: PatchApplicationUserRequest) {
    patchApplicationLeadUser(input: $input) {
      firstName
      lastName
      phone
      id
      code
      message
    }
  }
`;

export const CREATE_APPLICATION_LEADS = gql`
  mutation createApplicationLead($input: ApplicationLeadRequest) {
    createApplicationLead(input: $input) {
      id
      userId
      name
      status
    }
  }
`;

export const UPDATE_APPLICATION_LEADS = gql`
  mutation updateApplicationLead($input: UpdateApplicationLeadRequest) {
    updateApplicationLead(input: $input) {
      id
      userId
      name
      status
    }
  }
`;

export const CONFIRM_APPLICATION_LEADS = gql`
  mutation confirmApplicationLead($input: ConfirmApplicationLeadRequest) {
    confirmApplicationLead(input: $input) {
      id
      name
      status
    }
  }
`;

export const UPDATE_BANKING_INFO = gql`
  mutation updateBankInfo($input: UpdateBankInfoRequest) {
    updateBankInfo(input: $input) {
      id
      userId
      name
      status
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updateApplicationLeadPlan($input: UpdateApplicationLeadPlanRequest) {
    updateApplicationLeadPlan(input: $input) {
      id
      userId
      name
      status
    }
  }
`;

export const GET_APPLICATION_LEAD_AGREEMENT = gql`
  mutation getApplicationLeadAgreement($input: GetApplicationLeadAgreementRequest) {
    getApplicationLeadAgreement(input: $input) {
      id
    }
  }
`;

export const APPLICATION_LEADS_SUBMIT = gql`
  mutation submitApplicationLead($input: ApplicationLeadSubmitRequest) {
    submitApplicationLead(input: $input) {
      success
    }
  }
`;

export const APPLICATION_LEADS_REQUIREMENTS = gql`
  mutation updateApplicationLeadRequirements($input: ApplicationLeadRequirementsRequest) {
    updateApplicationLeadRequirements(input: $input) {
      reason
      id
      status
    }
  }
`;
